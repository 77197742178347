import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// import { HOME, POLICIES } from 'src/Constants/Common';
import Routes from 'src/Utils/Routes';
import { scrollSmoothTo } from 'src/Utils/utils';

const POLICIES = [
  { nameKey: 'footer.privacyPolicy', path: Routes.PrivacyPolicy },
  { nameKey: 'footer.termsAndConditions', path: Routes.TermsAndCondition },
  { nameKey: 'footer.cookiePolicy', path: Routes.CookiesPolicy },
];

const HOME = [
  { nameKey: 'footer.benefits', id: 'benefits', type: 'scroll' },
  { nameKey: 'footer.howToUse', id: 'how-to-use', type: 'scroll' },
  { nameKey: 'footer.pricing', id: 'pricing-plan', type: 'link' },
];

const FooterRight = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className='grid text-lg grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
      <div className='md:px-8 mb-8 sm:mb-0'>
        <h2 className='mb-5 font-[Karla-Regular] text-white uppercase dark:text-white'>
          {t('footer.home')}
        </h2>
        <ul className='text-gray-500 dark:text-gray-400 font-medium'>
          {HOME.map(({ nameKey, id, type }, ind) => (
            <p
              key={ind}
              className='!font-[Karla-Regular] text-lg text-gray-500 cursor-pointer hover:underline'
              onClick={() =>
                type === 'scroll'
                  ? scrollSmoothTo(id, '/', history)
                  : history.push(id)
              }
            >
              {t(nameKey)} {/* Using the translation key for dynamic text */}
            </p>
          ))}
        </ul>
      </div>
      <div className='md:px-8 mb-8 sm:mb-0'>
        <h2 className='mb-5 font-[Karla-Regular] text-white uppercase dark:text-white'>
          {t('footer.information')}
        </h2>
        <ul className='text-gray-500 dark:text-gray-400 font-medium'>
          {POLICIES.map(({ nameKey, path }, ind) => (
            <p
              key={ind}
              className='!font-[Karla-Regular] text-lg text-gray-500 cursor-pointer hover:underline md:whitespace-nowrap'
              onClick={() => history.push(path)}
            >
              {t(nameKey)} {/* Using the translation key for dynamic text */}
            </p>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FooterRight;
