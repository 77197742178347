import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from 'src/Contexts';

const FeaturesBottomRight = () => {
  const { t } = useTranslation();
  const { language } = useContext(AppContext);

  const array = t('features_section.analyze_career_path.list', {
    returnObjects: true,
  });

  return array.map((data, index) => (
    <div key={index}>
      <ul>
        <li
          className={`list-disc mt-8 text-base ${
            language === 'en' ? 'lg:ml-24' : 'lg:mr-24'
          } `}
        >
          {data}
        </li>
      </ul>
    </div>
  ));
};

export default FeaturesBottomRight;
