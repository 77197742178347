const PaymentIconNavbar = ({ isEnable }) => {
  return (
    <svg
      stroke='currentColor'
      fill='currentColor'
      strokeWidth='0'
      viewBox='0 0 24 24'
      height='22'
      width='22'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path fill='none' d='M0 0h24v24H0z'></path>
      <path
        d='M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z'
        stroke={`${isEnable ? '#ffffff' : '#afafaf'}`}
        fill={`${isEnable ? '#ffffff' : '#afafaf'}`}
      ></path>
    </svg>
  );
};

export default PaymentIconNavbar;
