import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';

import { Degree } from '../../Api';
import { Auth as AuthConstants } from '../../Constants';
import SearchDropdown from '../SearchDropdown';

const DegreeDropdown = (props) => {
  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${cookies[AuthConstants.JWT_TOKEN]}`,
    },
  };

  const [degrees, setDegrees] = useState([]);
  const [loading, setLoading] = useState(true);

  const list = (clear = false) => {
    Degree.list(requestConfig)
      .then((res) => {
        setDegrees([...(clear ? [] : degrees), ...res.data.data]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    list(true);
  }, []);

  return (
    <SearchDropdown
      value={props.value}
      onChange={(item) => {
        props.onChange(item);
      }}
      labelAttribute='title'
      matchAttribute='id'
      width='100%'
      data={degrees}
      loading={loading}
      inputPlaceholder='Degree*'
    />
  );
};

DegreeDropdown.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default DegreeDropdown;
