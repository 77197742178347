import Api from './index';

const getConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const Threads = {
  getThread: (token) => {
    return Api.get('/threads', getConfig(token));
  },
};

export default Threads;
