import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDebounce } from 'use-debounce';

import { customSort } from 'src/Utils/utils';

import { Profession } from '../../Api';
import { Auth as AuthConstants } from '../../Constants';
import SearchDropdown from '../SearchDropdown';

const ProfessionDropdown = (props) => {
  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const { t } = useTranslation();

  const SIZE = 50;

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${cookies[AuthConstants.JWT_TOKEN]}`,
    },
  };

  const [professions, setProfessions] = useState([]);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [debouncedQuery] = useDebounce(query, 1000);

  const list = ({ clear = false, page = 0, size = SIZE } = {}) => {
    Profession.list(requestConfig, {
      beg: page,
      size: size,
      query: debouncedQuery,
    })
      .then((res) => {
        const sortedArray = customSort(res?.data?.data, debouncedQuery);
        if (res?.data?.data)
          setProfessions([...(clear ? [] : professions), ...sortedArray]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (debouncedQuery) {
      list({ clear: true });
    }
  }, [debouncedQuery]);

  useEffect(() => {
    list({
      page: page,
    });
  }, [page]);

  return (
    <SearchDropdown
      value={props.value}
      onChange={(item) => {
        setPage(0);
        setQuery('');
        props.onChange(item);
      }}
      labelAttribute='name'
      matchAttribute='id'
      width='100%'
      data={professions}
      loading={loading}
      inputPlaceholder={t('profile.profileSummary.profession')}
      requestMoreData={() => {
        setPage(page + 1);
      }}
      onSearch={(input) => {
        if (input !== query) {
          setPage(0);
          setQuery(input);
        }
      }}
    />
  );
};

ProfessionDropdown.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default ProfessionDropdown;
