export const PointsSection = ({ data, index }) => {
  return (
    <li className='my-10'>
      <h2 className='font-[Karla-Bold] mx-1 inline-block text-3xl font-bold m-0 p-0 text-DarkBluePurple lg:mx-0 px-2 lg:px-0 w-full my-2'>
        {index}. {data.section}
      </h2>
      <p className='font-[Karla-Regular] m-0 p-0 text-DarkBluePurple lg:mx-0 px-2 lg:px-0 w-full my-2 text-regular'>
        {data.title?.includes('info@skilledscore.com')
          ? data.title.split('info@skilledscore.com')?.map((part, index) =>
              index === 0 ? (
                <span key={index}>{part}</span>
              ) : (
                <a
                  key={index}
                  className='underline'
                  href='mailto:info@skilledscore.com'
                >
                  info@skilledscore.com
                </a>
              ),
            )
          : data.title}
      </p>
      {data.subPoints && (
        <ol className='px-10'>
          {data.subPoints.map((subPoint, subIndex) => (
            <li
              className='list-disc font-[Karla-Regular] m-0 text-secondary w-full my-2 text-regular'
              key={subIndex}
            >
              {subPoint}
            </li>
          ))}
        </ol>
      )}
    </li>
  );
};
