import * as React from 'react';
// import { useCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Grid, Tooltip } from '@material-ui/core';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import { User } from '../../Api';
import Loader from '../../Components/Loader';
import Header from '../../Components/UploadCV/Header';
// import { Auth as AuthConstants } from '../../Constants';
import { parseLocalStorage } from '../../Utils';

const Path = (props) => {
  const [loader, setLoader] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [, setUploading] = React.useState(false);
  const [file, setFile] = React.useState('');
  let upload;

  // const history = useHistory();

  React.useEffect(() => {
    const loginData = parseLocalStorage(localStorage.getItem('loginData'));
    if (loginData) {
      setUser(loginData.user);
    }
  }, [props.uuid]);

  // const [, setCookie] = useCookies([AuthConstants.JWT_TOKEN]);

  const uploadFile = (file) => {
    setLoader(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('user_id', user.id);
    User.updateProfileUsingResumes(formData)
      .then(() => {
        // setCookie(
        //   AuthConstants.PROFILE_STATUS,
        //   response.data.data.profileStatus,
        //   {
        //     path: '/',
        //   },
        // );
        // localStorage.setItem(
        //   'loginData',
        //   JSON.stringify({
        //     user: { ...user, thread_id: response.data.data.thread_id },
        //   }),
        // );
        // const
        // history.push('/home/profile/resume');
      })
      .catch((err) => {
        console.error({ err });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  React.useEffect(() => {
    if (file) {
      uploadFile(file);
    }
  }, [file]);

  return (
    <>
      <Grid
        component={() => {
          return (
            <Grid>
              <Header />
              <div className='w-3/5 mx-auto mt-4 '>
                <input
                  id='fileInput'
                  type='file'
                  accept='application/pdf'
                  onChange={(e) => {
                    if (!e.target.files || e.target.files.length === 0) return;
                    if (e.target.files[0].name.split('.').pop() !== 'pdf') {
                      return;
                    }
                    setUploading(true);
                    setFile(e.target.files[0]);
                  }}
                  ref={(ref) => (upload = ref)}
                  style={{ display: 'none' }}
                />

                <button
                  disabled={loader}
                  className='border-primaryBlue border rounded-md px-4 py-2 w-full'
                  onClick={() => props.submitted()}
                >
                  <p className='text-primaryBlue'>Manual Signup</p>
                </button>
                <p className='my-2 text-sm text-center text-primaryBlue'>OR</p>
                <button
                  disabled={loader}
                  className='border-primaryBlue border rounded-md px-4 py-2 w-full flex justify-center items-center'
                  onClick={() => upload.click()}
                >
                  {loader ? (
                    <Loader />
                  ) : (
                    <p className='text-primaryBlue'>Upload your Resume</p>
                  )}
                </button>

                <p className='my-2 text-sm text-center text-primaryBlue'>OR</p>
                <Tooltip title='Coming Soon'>
                  <button className='mx-auto w-full bg-primaryBlue cursor-not-allowed flex justify-center items-center px-4 py-2 rounded-md '>
                    <LinkedInIcon className='mr-4 text-white' />
                    <p className='text-white'>Upload your LinkedIn Profile</p>
                  </button>
                </Tooltip>
              </div>
            </Grid>
          );
        }}
      />
    </>
  );
};

Path.prototype = {
  submitted: PropTypes.func.isRequired,
  skipped: PropTypes.func.isRequired,
  backed: PropTypes.func.isRequired,
};

export default withRouter(Path);
