import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Fab, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const FormHeader = (props) => {
  return (
    <>
      <Grid
        direction='row'
        justify='space-between'
        alignItems='center'
        container
      >
        <Typography className={props.className} variant='h3'>
          {props.title}
        </Typography>
        {props.onActionPerformed ? (
          <Fab
            size={props.iconSize || 'small'}
            color='primary'
            aria-label='add'
            onClick={props.onActionPerformed}
          >
            <AddIcon />
          </Fab>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};

FormHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onActionPerformed: PropTypes.func,
};

export default withRouter(FormHeader);
