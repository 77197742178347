import format from 'date-fns/format';

import Api from './index';
import Response from './Response';

const { next } = Response;

const User = {
  getUserProfile: async (token) => {
    const endpoint = '/user';

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const resp = await Api.get(endpoint, config);
      if (!resp?.data?.data) return resp;
      const { profession_id, profession_name, ...rest } = resp.data.data;
      return {
        data: {
          data: {
            profession: { id: profession_id, name: profession_name },
            ...rest,
          },
        },
      };
    } catch (e) {
      return e.response;
    }
  },
  getHappinessIndex: async (token, code) => {
    const endpoint = `/scraper/happiness-index?code=${code}`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const resp = await Api.get(endpoint, config);
      if (!resp?.data) return resp;
      const { happiness_rank } = resp.data.data;
      return {
        data: {
          happinesIndex: happiness_rank,
        },
      };
    } catch (e) {
      return e.response;
    }
  },
  getTopCountriesByProfession: async (token, data) => {
    const endpoint = 'profession/get-top-country';
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const resp = await Api.post(endpoint, data, config);
      if (resp?.data?.data) return resp.data.data;
      return resp;
    } catch (e) {
      next(e.response);
    }
  },
  generatePdf: async (token, payload) => {
    const endpoint = 'user/generate-pdf';

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    };

    try {
      const response = await Api.post(endpoint, payload, config);
      return response;
    } catch (e) {
      next(e.response);
    }
  },

  updateUserProfile: async (data) => {
    const endpoint = '/auth/resume-profile-creation';

    try {
      const resp = await Api.post(endpoint, data);
      if (resp?.data?.data) return resp.data.data;
      return resp;
    } catch (e) {
      next(e.response);
    }
  },

  getUserByEmail: async (email) => {
    const endpoint = '/auth/is-user';

    try {
      const resp = await Api.post(endpoint, { email });
      if (resp?.data?.data) return resp.data.data;
      return resp;
    } catch (e) {
      next(e.response);
    }
  },

  getProfileStatus: async (token) => {
    const endpoint = '/user/status';

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        // crossDomain: true,
        // withCredentials: true,
      },
    };

    try {
      const resp = await Api.get(endpoint, config);
      if (resp?.data?.data) {
        const {
          percentage,
          name,
          email,
          image_url: image,
          cv: resume,
          email_verified,
        } = resp.data.data;
        return { percentage, name, email, image, resume, email_verified };
      }
      throw resp;
    } catch (e) {
      next(e.response);
    }
  },

  updateGeneralProfile: async (data, token) => {
    const endpoint = '/user';

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const payload = {
      general: JSON.stringify({
        name: data.name,
        profession_id: data.profession.id,
        dob: data.dob ? format(data.dob, 'yyyy-MM-dd') : null,
        country_id: data.country.id,
        city_id: data.city.id,
        description: data.summary,
      }),
      image: data.image ? data.image.file : null,
      remove_image: data.isDeleted,
    };

    const formData = new FormData();
    Object.keys(payload).map((key) => formData.append(key, payload[key]));

    try {
      const resp = await Api.put(endpoint, formData, config);
      return resp;
    } catch (e) {
      next(e.response);
    }
  },
  updateProfileImage: async (data, token) => {
    const endpoint = '/user/upload-image';

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const payload = {
      image: data.image ? data.image.file : null,
      remove_image: data.isDeleted,
    };

    const formData = new FormData();
    Object.keys(payload).map((key) => formData.append(key, payload[key]));

    try {
      const resp = await Api.put(endpoint, formData, config);
      return resp;
    } catch (e) {
      next(e.response);
    }
  },

  updateSkills: async (skills, token) => {
    const endpoint = '/user/skills';
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const payload = {
      skill: JSON.stringify(skills),
    };

    try {
      const resp = await Api.put(endpoint, payload, config);
      return resp;
    } catch (e) {
      next(e.response);
    }
  },

  updateInterests: async (interests, token) => {
    const mappedData = interests?.map((interest) => ({
      id: interest.id,
      name: interest.name,
    }));
    const endpoint = '/user/area-of-interest';
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const payload = {
      interest: JSON.stringify(mappedData),
    };

    try {
      const resp = await Api.put(endpoint, payload, config);
      return resp;
    } catch (e) {
      next(e.response);
    }
  },

  updateProfileUsingResumes: async (payload, token) => {
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    try {
      return await Api.post('/auth/resume-profile-creation', payload, config);
    } catch (e) {
      next(e.response);
    }
  },

  updateExperience: async (experience, token, { id = -1 } = {}) => {
    const endpoint = `/user/experience${id !== -1 ? `/${id}` : ''}`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const payload = {
      experience: JSON.stringify({
        designation: experience.designation,
        company: experience.company,
        location: experience.location,
        start_year: experience.startYear,
        start_year_utc: experience.startUTC,
        end_year: experience.endYear,
        end_year_utc: experience.endUTC,
        start_month: experience.start_month,
        end_month: experience.end_month,
      }),
    };

    const request = id !== -1 ? Api.put : Api.post;
    try {
      const resp = await request(endpoint, payload, config);
      return resp;
    } catch (e) {
      next(e.response);
    }
  },

  deleteExperience: async (id, token) => {
    const endpoint = `/user/experience/${id}`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const resp = await Api.delete(endpoint, config);
      return resp;
    } catch (e) {
      next(e.response);
    }
  },

  deleteAccount: async (token, feedback) => {
    const endpoint = '/user';
    const queryParams = feedback
      ? `?feedback=${encodeURIComponent(feedback)}`
      : '';
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const resp = await Api.delete(`${endpoint}${queryParams}`, config);
      return resp;
    } catch (e) {
      next(e.response);
    }
  },

  updateEducation: async (education, token, { id = -1 } = {}) => {
    const endpoint = `/user/education${id !== -1 ? `/${id}` : ''}`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const payload = {
      education: JSON.stringify({
        degree_id: education.degree ? education.degree.id : null,
        study_field_id: education.fieldOfStudy
          ? education.fieldOfStudy.id
          : null,
        location: education.location,
        start_year: education.startYear,
        end_year: education.endYear,
        start_year_utc: education.startUTC,
        end_year_utc: education.endUTC,
        grade: education.grade,
        university_name: education.institute,
      }),
    };

    const request = id !== -1 ? Api.put : Api.post;
    try {
      const resp = await request(endpoint, payload, config);
      return resp;
    } catch (e) {
      next(e.response);
    }
  },

  deleteEducation: async (id, token) => {
    const endpoint = `/user/education/${id}`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const resp = await Api.delete(endpoint, config);
      return resp;
    } catch (e) {
      next(e.response);
    }
  },

  updateCertificate: async (certificate, token, { id = -1 } = {}) => {
    const endpoint = `/user/certificate${id !== -1 ? `/${id}` : ''}`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const payload = {
      certificate: JSON.stringify({
        title: certificate.title,
        // "study_field_id": certificate.fieldOfStudy ? certificate.fieldOfStudy.id : null,
        // "location": certificate.location,
        // "start_year": certificate.startYear,
        // "end_year": certificate.endYear,
        // "grade": certificate.grade,
      }),
    };

    const request = id !== -1 ? Api.put : Api.post;
    try {
      const resp = await request(endpoint, payload, config);
      return resp;
    } catch (e) {
      next(e.response);
    }
  },

  deleteCertificate: async (id, token) => {
    const endpoint = `/user/certificate/${id}`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const resp = await Api.delete(endpoint, config);
      return resp;
    } catch (e) {
      next(e.response);
    }
  },

  updateProject: async (project, token, { id = -1 } = {}) => {
    const endpoint = `/user/project${id !== -1 ? `/${id}` : ''}`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const payload = {
      project: JSON.stringify({
        title: project.title,
        description: project.description,
        company_name: project.company,
        location: project.location,
        link: project.link,
        start_year: project.startYear,
        end_year: project.endYear,
        start_year_utc: project.startUTC,
        end_year_utc: project.endUTC,
      }),
      image: project.image ? project.image.file : null,
    };

    const formData = new FormData();
    Object.keys(payload).map((key) => formData.append(key, payload[key]));

    const request = id !== -1 ? Api.put : Api.post;
    try {
      const resp = await request(endpoint, formData, config);
      return resp;
    } catch (e) {
      next(e.response);
    }
  },

  deleteProject: async (id, token) => {
    const endpoint = `/user/project/${id}`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const resp = await Api.delete(endpoint, config);
      return resp;
    } catch (e) {
      next(e.response);
    }
  },

  deleteSkills: async (id, token) => {
    const endpoint = `/user/skill/${id}`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const resp = await Api.delete(endpoint, config);
      return resp;
    } catch (e) {
      next(e.response);
    }
  },

  deleteInterests: async (id, token) => {
    const endpoint = `/user/interest/${id}`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const resp = await Api.delete(endpoint, config);
      return resp;
    } catch (e) {
      next(e.response);
    }
  },

  getScores: async (token) => {
    const endpoint = '/user/score';

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const resp = await Api.get(endpoint, config);
      if (resp?.data?.data) {
        const scores = {
          ilo: resp.data.data.ilo,
          iloLevel: resp?.data?.data?.iloLevel,
        };
        resp.data.data.scores.forEach(
          (singleScore) => (scores[singleScore.type] = singleScore.score),
        );
        return {
          ...scores,
          employability: scores.emp,
        };
      }
      return resp;
    } catch (e) {
      console.warn('e', e?.response);
      next(e?.response);
    }
  },

  getCVs: async (token) => {
    const endpoint = '/user/attachment?type=cv';

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        // crossDomain: true,
        // withCredentials: true,
      },
    };

    try {
      const resp = await Api.get(endpoint, config);
      return resp;
    } catch (e) {
      next(e.response);
    }
  },

  deleteCV: async (id, token) => {
    const endpoint = `/user/resume/${id}`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        // crossDomain: true,
        // withCredentials: true,
      },
    };

    try {
      return await Api.delete(endpoint, config);
    } catch (e) {
      next(e.response);
    }
  },

  uploadCV: async (token, file, type) => {
    const endpoint = `/user/resume?is_linkedin=${
      type === true ? 'True' : 'False'
    }`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        // crossDomain: true,
        // withCredentials: true,
      },
    };

    const formData = new FormData();
    formData.append('resume', file);

    try {
      return await Api.post(endpoint, formData, config);
    } catch (e) {
      next(e.response);
    }
  },

  submitCVData: async (data, token) => {
    const endpoint = '/user/resume';

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const payload = {
      skills: JSON.stringify(
        data.skills.map((skill) => ({ id: skill.id, rate: skill.rate })),
      ),
      education: JSON.stringify(
        data.education.map((education) => ({
          degree_id: education.degree.id,
          study_field_id: education.fieldOfStudy.id,
          location: education.location,
          start_year: education.startYear,
          end_year: education.endYear,
          grade: education.grade,
          university_name: education.institute,
        })),
      ),
      experience: JSON.stringify(
        data.experience.map((experience) => ({
          designation: experience.designation,
          company: experience.company,
          location: experience.location,
          start_year: experience.startYear,
          end_year: experience.endYear,
        })),
      ),
      certificate: JSON.stringify(data.certificate),
    };

    const formData = new FormData();
    Object.keys(payload).map((key) => formData.append(key, payload[key]));

    try {
      return await Api.put(endpoint, formData, config);
    } catch (e) {
      next(e.response);
    }
  },
};

export default User;
