import Api from './index';
const getConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const Skills = {
  list: async (config, { query = '', beg = 0, size = 10 }) => {
    const endpoint = `/interest?q=${query}&page=${beg}&size=${size}&orderBy=desc`;
    return await Api.get(endpoint, config);
  },
  getAreaOfInterest: async (token) => {
    const endpoint = '/skill/area-of-interest';
    return await Api.get(endpoint, getConfig(token));
  },
};

export default Skills;
