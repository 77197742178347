import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

import { Button } from '@material-ui/core';
import { useGoogleLogin } from '@react-oauth/google';

import { Auth } from '../Api';
import Threads from '../Api/Threads';
import google from '../Assets/images/google-96.svg';
import { Auth as AuthConstants } from '../Constants';
import { AppContext } from '../Contexts';

const GoogleSignOn = ({ label = '' }) => {
  const history = useHistory();

  const { fetchUserPayment } = useContext(AppContext);

  const [, setCookie] = useCookies([AuthConstants.JWT_TOKEN]);

  const login = useGoogleLogin({
    onSuccess: (user) => triggerSSO(user.access_token),
    onError: (error) => console.error('Login Failed:', error),
  });

  const triggerSSO = async (access_token) => {
    try {
      const res = await Auth.socialSignOn(access_token);

      const threadData = await Threads.getThread(res?.data?.data?.token);

      localStorage.setItem(
        'loginData',
        JSON.stringify({
          user: {
            ...res.data.data,
            thread_id: threadData?.data?.data?.id ?? null,
          },
        }),
      );
      setCookie(AuthConstants.JWT_TOKEN, res.data.data.token, {
        path: '/',
      });

      setCookie(AuthConstants.PROFILE_STATUS, res.data.data.profile_status, {
        path: '/',
      });

      fetchUserPayment(res?.data?.data?.token);

      history.push('/home');
    } catch (e) {
      console.error(e, 'error');
    }
  };

  return (
    <Button
      onClick={login}
      variant='contained'
      style={{
        backgroundColor: '#fff',
        fontWeight: '600',
        width: '100%',
        lineHeight: '1',
      }}
    >
      <img
        src={google}
        alt='Sign in with Google'
        style={{ maxWidth: '25px' }}
      />
      &nbsp;&nbsp;
      {label}
    </Button>
  );
};

export default GoogleSignOn;
