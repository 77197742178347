const Response = {
  next: (response) => {
    if (!response?.data || response?.data?.error === false) return response;

    const ERROR_MESSAGES = {
      'email does not exist!': 'Email does not exist!',
      'account does not exist!': "Account does not exist or it's disabled.",
      'incorrect password!': 'Incorrect Password!',
      'invalid_api': 'Invalid Request',
      'field_missing': 'Required Fields are missing',
      'internal_server_error': 'Internal Server Error',
      'invalid_type': 'Invalid type selected!',
      'General information is required!': 'General information is required!',
      'Duplicate entry!': 'Already exists!',
      'Validation error': 'Email already exists!',
      'File required!': 'File required!',
      'auth_password_invalid_format': 'Invalid password format!',
      'password_too_long': 'Password is too long!',
      'wrong_email_or_password': 'Wrong email or password!',
      'invalid_format': 'Invalid input format!',
      'auth_invalid_email': 'Invalid email',
      'account_exists': 'Account Already Exists',
      'token_expired': 'Your token has expired!',
      'unauthorized_request': 'You are not authorized to perform this action!',
      'auth_token_invalid': 'Your Authentication Token is not valid!',
      'same password': 'Same as previous Password',
      'gender required': 'Gender Required!',
      'Email sent!': 'Email Sent!',
      'invalid_user_type': 'Not a valid user account!',
    };
    throw Error(
      ERROR_MESSAGES[response.data.error_code] === null
        ? response.data.error_code
        : ERROR_MESSAGES[response.data.error_code],
    );
  },
};

export default Response;
