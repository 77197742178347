import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { COMMON } from 'src/Constants';
import { AppContext } from 'src/Contexts';

import InterestsDropdown from './OnBoarding/InterestsDropdown';
import SkillsDropdown from './OnBoarding/SkillsDropdown';
import Chip from './Chip';

const useStyles = makeStyles(() => ({
  container: {
    // padding: '0 5% 5% 0',
    padding: '0 1% 0 1%',
  },
  chipsContainer: {
    backgroundColor: '#f7f8f9',
    margin: '2% 0',
    padding: '5px',
    height: '200px',
    overflowY: 'scroll',
  },
  promptText: {
    marginBottom: '8px',
  },
}));

const ChipsInputForm = (props) => {
  const classes = useStyles();
  const context = useContext(AppContext);
  const addNewSkill = (addedItem) => {
    if (addedItem) {
      props.onNewItem(addedItem);
      return;
    }
    context.showError(COMMON.REQUIRED_ERROR_MSG);
  };

  return (
    <Grid className={classes.container} justify='center' container>
      <Grid xs={12} item>
        {props.variant === 'skills' ? (
          <SkillsDropdown
            type={props.type}
            onChange={(item) => addNewSkill(item)}
            profession_id={props.profession_id}
          />
        ) : (
          <InterestsDropdown onChange={(item) => addNewSkill(item)} />
        )}
      </Grid>
      <Grid xs={12} item>
        <Grid
          alignItems='center'
          direction='row'
          container
          justify={'flex-end'}
        >
          {/* {props.variant === 'skills' ? (
            <Grid
              className={classes.inputStyle}
              component={Slider}
              aria-labelledby='discrete-slider-always'
              step={1}
              value={rate}
              onChange={(event, value) => setRate(value)}
              marks={true}
              min={1}
              max={5}
              valueLabelDisplay='auto'
              item
            ></Grid>
          ) : (
            <></>
          )}
          {props.variant === 'skills' ? (
            <Grid xs={12} className={classes.promptText} item>
              <Typography variant='subtitle1'>Rate your skill</Typography>
            </Grid>
          ) : null} */}
          {/* commented due to implementing single click add */}
          {/* <Grid item>
            <button
              onClick={() => {
                if (item) {
                  props.onNewItem(item);
                  return;
                }
                context.showError(COMMON.REQUIRED_ERROR_MSG);
                setItem(null);
              }}
              className='bg-primaryBg shadow-md text-white px-4 my-2 p-1 rounded-lg'
            >
              ADD
            </button>
          </Grid> */}
        </Grid>
      </Grid>
      <Grid xs={12} item>
        <Grid className={classes.chipsContainer} container>
          <Grid item>
            <Grid container>
              {props.data.map((item, index) => (
                <Grid item key={index}>
                  <Chip
                    key={item.id}
                    label={item.name}
                    onDelete={() => props.onDeleteItem(item.id)}
                    className={classes.chipStyle}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ChipsInputForm.propTypes = {
  type: PropTypes.oneOf(['h', 's']),
  variant: PropTypes.oneOf(['skills', 'interests']),
  profession_id: PropTypes.string,
  data: PropTypes.array.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  onNewItem: PropTypes.func.isRequired,
};

ChipsInputForm.defaultProps = {
  type: 'h',
  data: [],
  variant: 'skills',
};

export default ChipsInputForm;
