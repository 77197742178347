import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  spacing: 8,
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Avenir',
      'Ubuntu-Medium',
      'Ubuntu',
      'Ubuntu-Light',
      'Avenir-Heavy',
      'Avenir-Roman',
      'Avenir-Medium',
      'Avenir-Book',
      'Avenir-Light',
      'Roboto',
      'sans-serif',
    ].join(','),
    h2: {
      fontFamily: 'Avenir-Light',
      fontSize: '48px',
      fontWeight: '300',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    h3: {
      fontFamily: 'Ubuntu',
      fontSize: '33px',
      fontWeight: '500',
      fontStretch: 'normal',
      fontStyle: 'normal',
      color: '#505050',
    },
    h4: {
      fontFamily: 'Avenir-Book',
      fontSize: '20px',
      fontWeight: '600',
    },
    h5: {
      fontFamily: 'Avenir-Medium',
      fontSize: '16px',
      fontWeight: '500',
      fontStretch: 'normal',
      fontStyle: 'normal',
      color: '#505050',
    },
    body1: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: 1.5,
    },
    subtitle1: {
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#8b8b8b',
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#4f4f4f',
    },
  },
  palette: {
    primary: {
      main: '#3277e9',
      light: '#ffffff',
      Dark: '#cccccc',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#f5f6fa',
      light: '#484848',
      Dark: '#000000',
      contrastText: '#000000',
    },
    shape: {
      borderRadius: 0,
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        'height': '43px',
        '&$focused': {
          borderColor: 'blue',
        },
      },
    },
    MuiButton: {
      textPrimary: {
        color: '#3479ea',
        fontWeight: '600',
        textTransform: 'none',
        fontFamily: 'Avenir',
      },
      textSecondary: {
        color: '#505050',
        fontWeight: '400',
        textTransform: 'none',
        fontFamily: 'Avenir-Roman',
      },
      contained: {
        height: '43px',
        paddingRight: '31px',
        paddingLeft: '31px',
        textTransform: 'none',
        fontSize: '14px',
      },
      containedPrimary: {
        'background':
          'linear-gradient(90deg, rgba(72,144,240,1) 0%, rgba(50,119,233,1) 100%)',
        '&:disabled': {
          background: 'inherit',
        },
      },
      containedSecondary: {
        background: 'none',
        backgroundColor: '#ffffff',
        color: '#3479ea',
        height: '43px',
        paddingRight: '31px',
        paddingLeft: '31px',
        fontWeight: '900',
        fontFamily: 'Avenir-Heavy',
        fontSize: '16px',
        boxShadow: '0 9px 15px 0 rgba(0, 0, 0, 0.24)',
      },
    },
    MuiFab: {
      root: {
        background:
          'linear-gradient(90deg, rgba(72,144,240,1) 0%, rgba(50,119,233,1) 100%)',
      },
    },
    MuiChip: {
      deleteIconOutlinedColorPrimary: {
        color: '#000000',
      },
      outlinedPrimary: {
        color: '#000000',
        borderRadius: '8px',
        borderColor: '#000000',
      },
      outlinedSecondary: {
        color: '#FFFFFF',
        borderRadius: '8px',
        borderColor: '#FFFFFF',
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: '15px',
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
      colorPrimary: {
        backgroundColor: '#f8f8fc',
      },
    },
    MuiTab: {
      root: {
        'textTransform': 'none',
        'fontFamily': 'Avenir',
        '&$selected': {
          color: '#3479ea',
          fontSize: '16px',
        },
      },
      textColorPrimary: {
        color: '#848484',
        fontSize: '16px',
      },
    },
    MuiCard: {
      root: {
        boxShadow: '0 4px 6px 0 rgba(192, 192, 192, 0.25)',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: '#003a96',
        opacity: '0.5',
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: '#E1EBFC',
        },
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        '&[class*="MuiOutlinedInput-root"]': {
          'padding': 7,
          '& $input': {
            padding: '5.5px 6px',
          },
        },
      },
    },
  },
});

export default theme;
