import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button as MuiButton, Grid, Link } from '@material-ui/core';

import Button from './Button';

const FormFooter = (props) => {
  return (
    <>
      <Grid
        direction='row'
        justify='space-between'
        alignItems='center'
        container
      >
        {props.onBack ? (
          <MuiButton
            size='medium'
            variant='containedSecondary'
            color='primary'
            onClick={props.onBack}
          >
            BACK
          </MuiButton>
        ) : (
          <Box />
        )}
        {props.onSkip ? (
          <Link onClick={props.onSkip} style={{ cursor: 'pointer' }}>
            Skip
          </Link>
        ) : (
          <></>
        )}
        <Button
          disabled={props.disabled}
          size='medium'
          variant='contained'
          color='primary'
          onClick={async () => {
            await Promise.resolve(props.onNext());
          }}
          title='NEXT'
        />
      </Grid>
    </>
  );
};

FormFooter.propTypes = {
  onNext: PropTypes.func,
  onSkip: PropTypes.func,
  onBack: PropTypes.func,
  disabled: PropTypes.bool,
};

export default FormFooter;
