import Api from './index';

const Degree = {
  list: async (config) => {
    const endpoint = '/education/degree';
    return await Api.get(endpoint, config);
  },
  listFields: async (config, { query = '', beg = 0, size = 50 } = {}) => {
    const endpoint = `/education/fields?${
      query && query.length > 0 ? `q=${query}&` : ''
    }orderBy=asc&page=${beg}&size=${size}`;
    return await Api.get(endpoint, config);
  },
};

export default Degree;
