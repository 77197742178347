import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles(() => ({
  pickerText: {
    '& .MuiInputBase-input': {
      color: '#BCBCBC',
    },
    'width': '100%',
  },
  pickerTextSelected: {
    '& .MuiInputBase-input': {
      color: '#000000',
    },
    'width': '100%',
  },
}));

const YearSelector = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <KeyboardDatePicker
      views={props.isExperience ? ['year', 'month'] : ['year']}
      inputVariant='outlined'
      className={props.value ? classes.pickerTextSelected : classes.pickerText}
      variant='inline'
      disabled={props.disabled}
      disableFuture={true}
      emptyLabel={props.placeholder}
      open={open}
      minDate={props.minDate}
      onClick={() => {
        if (props.disabled) return;
        setOpen(true);
      }}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={props.value}
      onChange={(value) => {
        props.onChange(value);
        setOpen(false);
      }}
      format={props.isExperience ? 'MM/yyyy' : 'yyyy'} // Added format for month/year display
    />
  );
};

YearSelector.propTypes = {
  onChange: PropTypes.func,
  open: PropTypes.bool,
  value: PropTypes.any, // Updated to `any` to handle both date objects and strings
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  minDate: PropTypes.any,
};

YearSelector.defaultProps = {
  minDate: new Date('1900-01-01'),
};

export default YearSelector;
