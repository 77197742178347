import React from 'react';
import { useTranslation } from 'react-i18next';

import Benefit1 from '../../../Assets/images/v1/icons/benefits-1.webp';
import Benefit2 from '../../../Assets/images/v1/icons/benefits-2.webp';
import { SectionTitle } from '../SectionTitle';
import { Text } from '../Text';

const BenefitCard = ({ className, img, title, description }) => (
  <div className={`${className}`}>
    <div>
      <img
        src={img}
        className='-top-20 relative left-4 md:left-10'
        alt={title}
      />
    </div>
    <Text className={'text-white text-regular pt-2 xl:pt-6'}>{title}</Text>
    <Text className={'text-white text-base py-6'}>{description}</Text>
  </div>
);

const Benefits = () => {
  const { t } = useTranslation();
  const benefits = t('benefits', { returnObjects: true }); // Access JSON data dynamically
  const images = [Benefit1, Benefit2];
  return (
    <div className='bg-PurpleBlack' id='benefits'>
      <div className='max-w-screen-2xl mx-auto'>
        <SectionTitle
          title={benefits.title}
          description={benefits.description}
          titleClass='text-2xl sm:text-3xl md:!text-[44px]'
          descriptionClass='mt-3'
        />
        <div className='flex flex-col md:flex-row justify-evenly md:pt-48 pt-0 items-center'>
          {benefits.cards.map((card, index) => (
            <div
              key={index}
              className={`w-full px-4 ${
                index === 0 ? 'py-24 md:py-0' : 'pt-10 md:pt-0'
              } md:w-3/5 lg:1/3 xl:w-[600px]`}
            >
              <BenefitCard
                className={`${
                  index === 0
                    ? 'bg-BluePurple'
                    : 'bg-DarkBluePurple -mt-0 md:-mt-24'
                } px-6 md:px-14 mb-10 pb-16`}
                img={images[index]}
                title={card.title}
                description={card.description}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Benefits;
