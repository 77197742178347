import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ProfileCompletionContext } from '../Contexts';

import ChipsDisplayForm from './ChipsDisplayForm';
import ProfileCompletionModal from './ProfileCompletionModal';

const useStyles = makeStyles(() => ({
  formContainer: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '24px',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  },
  chipsForm: {
    marginBottom: '6%',
  },
  titleStyle: {
    marginTop: '10px',
    fontSize: '30px',
  },
  subTitleStyle: {
    marginTop: '10px',
    fontSize: '18px',
    fontWeight: 'lighter',
  },
}));

const UpdateSkillsForm = (props) => {
  const classes = useStyles();
  const context = useContext(ProfileCompletionContext);
  const { profile } = context;
  const { softSkills, hardSkills } = profile;

  const handleDelete = (id, data, setter) => {
    setter((data) => data.filter((item) => item.id !== id));
  };

  const [softSkillsState, setSoftSkillsState] = useState(
    softSkills ? softSkills : [],
  );
  const [hardSkillsState, setHardSkillsState] = useState(
    hardSkills ? hardSkills : [],
  );

  useEffect(() => {
    setSoftSkillsState(profile.softSkills);
    setHardSkillsState(profile.hardSkills);
  }, [profile]);

  return (
    <Box className={classes.formContainer}>
      <ProfileCompletionModal
        submitted={() =>
          props.submitted({
            softSkills: softSkillsState,
            hardSkills: hardSkillsState,
          })
        }
        skipped={props.skipped}
        backed={props.backed}
        center={softSkillsState.length === 0 && hardSkillsState.length === 0}
        isChildren={softSkillsState.length || hardSkillsState.length}
        title='Add Skills'
        subTitle='Let us know the skills you have mastery on!'
        variant='skills'
        titleStyle={classes.titleStyle}
        subTitleStyle={classes.subTitleStyle}
      >
        <br />
        <Grid style={{ marginTop: '5%' }} container>
          <Grid
            direction='column'
            alignItems={
              softSkillsState.length === 0 && hardSkillsState.length === 0
                ? 'center'
                : 'flex-start'
            }
            justify='center'
            item
            xs={12}
          >
            {softSkillsState.length > 0 && (
              <Grid className={classes.chipsForm} xs={12} item>
                <ChipsDisplayForm
                  title='Soft Skills'
                  data={softSkillsState}
                  onDeleteItem={(id) =>
                    handleDelete(id, softSkillsState, setSoftSkillsState)
                  }
                />
              </Grid>
            )}
            {hardSkillsState.length > 0 && (
              <Grid className={classes.chipsForm} xs={12} item>
                <ChipsDisplayForm
                  title='Technical Competencies'
                  data={hardSkillsState}
                  onDeleteItem={(id) =>
                    handleDelete(id, hardSkillsState, setHardSkillsState)
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </ProfileCompletionModal>
    </Box>
  );
};

UpdateSkillsForm.propTypes = {
  softSkills: PropTypes.array,
  hardSkills: PropTypes.array,
  submitted: PropTypes.func.isRequired,
  skipped: PropTypes.func.isRequired,
  backed: PropTypes.func.isRequired,
};

UpdateSkillsForm.defaultProps = {
  softSkills: [],
  hardSkills: [],
};

export default UpdateSkillsForm;
