import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

import AssessmentScoreImage from 'src/Assets/images/v1/assessment-score.webp';
import careerCounselingImage from 'src/Assets/images/v1/career-counseling.webp';
// import RightIcon from 'src/Assets/images/v1/icons/right-icon-black.svg';
import SelectedScore from 'src/Assets/images/v1/icons/selected-score.svg';
import jobsImage from 'src/Assets/images/v1/jobs.webp';
import upskillImage from 'src/Assets/images/v1/upskill.webp';
import { AppContext } from 'src/Contexts';

import { Text } from '../Text';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const HowsWork = ({ title, heading, paragraph, src }) => {
  const { language } = useContext(AppContext);
  return (
    <div
      className={`max-w-screen-2xl mx-auto flex flex-wrap h-full md:h-96 items-center ${
        language === 'en' ? ' flex-row' : 'flex-row-reverse'
      }`}
    >
      <div
        className={`flex-1 px-4 flex justify-center flex-col ${
          language === 'en' ? 'text-left md:pl-20' : 'text-right md:pr-20'
        } `}
      >
        <Text className={'text-BluePurple text-regular'}>{title}</Text>
        <Text
          className={
            'text-secondary text-2xl sm:text-3xl md:!text-[36px] py-4 font-bold !font-[IBM-Ple-Sans] leading-tight'
          }
        >
          {heading}
        </Text>
        <Text className={'md:w-full'}>{paragraph}</Text>
        {/* <div className='flex border-t-2 w-full pb-4 cursor-pointer group items-center'>
          <Text className={'text-base group-hover:underline pt-2'}>
            {`Learn more about ${title?.toLowerCase()}`}
          </Text>
          <div className='pt-2'>
            <img src={RightIcon} />
          </div>
        </div> */}
      </div>
      <div className='flex justify-center xl:block !w-full xl:!w-max px-10 lg:px-0 mt-10 '>
        <img
          src={src}
          className='md-w-[400px] lg:w-[770px] shadow-[1px_1px_35px_-5px_rgba(0,0,0,0.1)]'
        />
      </div>
    </div>
  );
};

const HowItWorksSection = ({ isSelected, onClick, label }) => {
  console.log(isSelected, '---', label);
  return (
    <div className='relative overflow-hidden h-24 justify-center'>
      <div
        onClick={onClick}
        className={`px-5 py-3 z-10 rounded-lg cursor-pointer group hover:bg-SSBlack hover:text-white ${
          isSelected ? 'bg-SSBlack text-white' : 'text-black'
        }`}
      >
        <Text
          className={`group-hover:text-white ${
            isSelected ? 'text-white' : 'text-black'
          }`}
        >
          {label}
        </Text>
      </div>
      {isSelected && (
        <div className='absolute top-10 -z-10'>
          <img src={SelectedScore} className='w-40 h-20' alt='Selected Score' />
        </div>
      )}
    </div>
  );
};

const HowItWorks = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';
  const [selected, setSelected] = useState(0);
  const sliderRef = useRef(null);

  const handleToggle = (key) => {
    setSelected(key);
    const index = Object.keys(steps).indexOf(key); // Get the index of the selected key
    sliderRef.current.slickGoTo(index);
  };

  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    rtl: isRTL,
    slidesToScroll: 1,
  };

  // Get steps dynamically from JSON structure
  const steps = t('how_it_works.steps', { returnObjects: true });

  return (
    <div className='pt-5'>
      {/* Title */}
      <div className='md:py-10 py-5 text-center px-2'>
        <h1 className='!text-black !text-2xl sm:!text-3xl md:!text-4xl lg:!text[44px] px-2 md:text-subHeading font-bold !font-[IBM-Ple-Sans] leading-tight'>
          {t('how_it_works.title')}
        </h1>
      </div>

      {/* Tabs for Desktop */}
      <div className='flex-row justify-evenly hidden md:flex'>
        {Object.keys(steps).map((key, index) => (
          <HowItWorksSection
            key={key}
            label={steps[key].title}
            isSelected={selected === index}
            onClick={() => handleToggle(key)}
          />
        ))}
      </div>

      {/* Slider */}
      <div className='bg-lightGrey py-10 w-full px-2'>
        <div className='slider-container overflow-hidden'>
          <Slider
            afterChange={(index) => setSelected(index)}
            {...sliderSettings}
            ref={sliderRef}
            dotsClass='flex px-10 justify-around'
            appendDots={(dots) => (
              <ul className='flex'>
                {dots?.map((dot) => {
                  return (
                    <div
                      key={dot.key}
                      className={`${
                        dot.props.className === 'slick-active'
                          ? 'w-7 !bg-DarkBluePurple '
                          : 'w-4'
                      } h-3 md:w-0 md:h-0 rounded-full bg-slate-300 mt-5`}
                      onClick={dot.props.children.props.onClick}
                    />
                  );
                })}
              </ul>
            )}
          >
            <div>
              <HowsWork
                title={t('how_it_works.steps.assessment.title')}
                heading={t('how_it_works.steps.assessment.heading')}
                paragraph={t('how_it_works.steps.assessment.paragraph')}
                src={AssessmentScoreImage}
              />
            </div>

            <div>
              <HowsWork
                title={t('how_it_works.steps.upskills.title')}
                heading={t('how_it_works.steps.upskills.heading')}
                paragraph={t('how_it_works.steps.upskills.paragraph')}
                src={upskillImage}
              />
            </div>
            <div>
              <HowsWork
                title={t('how_it_works.steps.career_counseling.title')}
                heading={t('how_it_works.steps.career_counseling.heading')}
                paragraph={t('how_it_works.steps.career_counseling.paragraph')}
                src={careerCounselingImage}
              />
            </div>
            <div>
              <HowsWork
                title={t('how_it_works.steps.jobs_recommendations.title')}
                heading={t('how_it_works.steps.jobs_recommendations.heading')}
                paragraph={t(
                  'how_it_works.steps.jobs_recommendations.paragraph',
                )}
                src={jobsImage}
              />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
