import { useState } from 'react';

const Accordion = ({
  title,
  description,
  children,
  containerStyle,
  icon,
  buttonTitle,
  onOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`flex w-full flex-col bg-white border rounded-md px-2 md:px-5 py-3 ${containerStyle}`}
    >
      <button
        onClick={toggleAccordion}
        className='box-border flex cursor-pointer appearance-none items-center justify-between pb-2 focus:outline-none'
      >
        <div className='w-full'>
          <div className='flex justify-between px-2'>
            <h2 className='!text-xl md:!text-2xl !font-medium !text-center md:!text-left text-DarkBluePurple !font-[IBM-Ple-Sans]'>
              {title}
            </h2>
            {buttonTitle && (
              <button
                className='px-2 py-1 w-40 bg-BluePurple hover:bg-lightBg hover:text-secondary text-white rounded-md mt-1 transition-all duration-300 ease-in-out hover:transform hover:translate-x-2'
                onClick={(e) => {
                  e.stopPropagation();
                  if (onOpen) {
                    onOpen();
                  }
                }}
              >
                {buttonTitle}
              </button>
            )}
          </div>

          <div className='mt-2'>
            <p className='text-center text-xs md:text-sm lg:text-lg md:text-left leading-5 md:leading-6'>
              {description}
            </p>
          </div>
        </div>

        {icon ? (
          <div className={`${isOpen ? 'rotate-180' : ''}`}>{icon}</div>
        ) : null}
      </button>
      <div
        className={`overflow-hidden transition-all duration-700 ease-in-out ${
          isOpen ? 'max-h-screen' : 'max-h-0'
        }`}
      >
        <div className='pt-1'>{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
