import React from 'react';

import Footer from 'src/Components/LandingPage/Footer';
import { PointsSection } from 'src/Components/v1/PointsSection';

import { Header } from './LandingPage';

const PolicySection = ({ pageTitle, pageContent }) => {
  return (
    <>
      <div className='bg-DarkBluePurple' id={pageTitle}>
        <Header />
      </div>
      <div className='container mx-auto h-full px-10 md:px-20 xl:px-24 py-14'>
        <div className='flex justify-center'>
          <h1 className='block font-semibold text-DarkBluePurple text-4xl leading-tight md:text-5xl xl:text-6xl text-center font-[IBM-Ple-Sans]'>
            {pageTitle}
          </h1>
        </div>
        <div className='my-10'>
          <p className='font-[Karla-Regular] m-0 p-0 text-DarkBluePurple lg:mx-0 px-2 lg:px-0 w-full my-2 text-regular'>
            {pageContent.title}
          </p>
        </div>
        <div>
          <ul>
            {pageContent.points.map((point, index) => (
              <PointsSection data={point} index={index + 1} key={index} />
            ))}
          </ul>
        </div>

        <p className=' font-[Karla-Regular] m-0 text-DarkBluePurple w-full my-2 text-regular font-bold'>
          {pageContent.thanksMessage}
        </p>
      </div>
      <Footer />
    </>
  );
};

export default PolicySection;
