import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import BlogsApi from 'src/Api/Blogs';
import { Blog as BlogImage } from 'src/Assets/images/dashboard';
import Footer from 'src/Components/LandingPage/Footer';
import Loader from 'src/Components/Loader';
import { scrollSmoothTo } from 'src/Utils/utils';

import { Header } from '../../Components/LandingPage/index';

const Blog = () => {
  const { slug } = useParams();

  const [blog, setBlog] = useState(null);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    scrollSmoothTo('root');
  }, []);

  const getBlogs = async () => {
    setLoader(true);
    try {
      const response = await BlogsApi.singleBlog(slug);

      setBlog(response?.data?.data[0]?.attributes);
    } catch (error) {
      setBlog(undefined);
      console.error('Error fetching blogs:', error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getBlogs();
  }, []);

  return (
    <div>
      <Helmet>
        <title>{blog?.metaTitle || ''}</title>
        <meta name='description' content={blog?.metaDescription || ''} />
      </Helmet>
      <div className='bg-primaryBg px-2'>
        <div className='max-w-screen-2xl mx-auto'>
          <div className='mx-2 lg:px-8'>
            <Header />
          </div>
        </div>
      </div>
      <div className='min-h-[70vh]'>
        <div className='max-w-screen-2xl w-10/12 lg:w-7/12 mx-auto '>
          {blog ? (
            <div className='flex flex-col justify-center items m-auto mt-16 mb-4'>
              <h1 className='block font-semibold text-DarkBluePurple text-4xl lg:text-5xl leading-tight font-[IBM-Ple-Sans]'>
                {blog?.name}
              </h1>
              <div className='font-[Karla-Regular] text-gray-500 mb-4 w-full mt-2 text-regular'>
                {blog?.description}
              </div>
              <img
                className='rounded-t-lg w-full h-3/6'
                src={`${process.env.REACT_APP_STRAPI_API}${blog?.image?.data?.attributes?.url}`}
                alt={blog?.name}
              />
            </div>
          ) : null}

          <div className='mx-auto mb-36'>
            {loader ? (
              <div className='flex justify-center items-center h-[80vh]'>
                <Loader />
              </div>
            ) : (
              <div className=''>
                <div
                  dangerouslySetInnerHTML={{
                    __html: blog?.CKEditor ? blog.CKEditor : '',
                  }}
                  className='!font-[karla-regular]'
                />
              </div>
            )}
            {blog === undefined && (
              <div className='flex flex-col items-center h-[80vh] justify-center'>
                <img src={BlogImage} className='w-20 h-20 opacity-60' alt='' />
                <p className='text-center text-2xl font-[karla-regular]'>
                  No blog to show
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Blog;
