import Api from './index';
import Response from './Response';

const { next } = Response;

const Auth = {
  linkedInAuthorization: async (code, path) => {
    const endpoint = '/auth/linked-in-authorization';
    try {
      return await Api.post(endpoint, {
        code,
        path,
      });
    } catch (e) {
      next(e.response);
    }
  },

  microsoftSignOn: async (accessToken) => {
    const endpoint = '/auth/microsoft-sign-on';
    const response = await Api.post(endpoint, { accessToken });

    if (response?.data?.data) {
      localStorage.setItem('login', 'true');
      return response;
    }
  },

  socialSignOn: async (access_token) => {
    const endpoint = '/auth/social-sign-on';
    const response = await Api.post(endpoint, { access_token });
    if (response?.data?.data) {
      localStorage.setItem('login', 'true');
      return response;
    }
  },

  signUp: async (name, email, gender, password, dob, refrerCode) => {
    const endpoint = '/auth/register';
    try {
      localStorage.setItem('login', 'true');
      localStorage.removeItem('walkthroughSeen');
      return await Api.post(endpoint, {
        name,
        email,
        gender: gender.toLowerCase(),
        password,
        dob,
        refrerCode,
      });
    } catch (e) {
      next(e.response);
    }
  },

  signIn: async (email, password) => {
    const endpoint = '/auth/login';
    try {
      const response = await Api.post(endpoint, {
        email,
        password,
      });

      // if (response.data.data.role !== 'user') {
      //   const res = {
      //     response: {
      //       data: {
      //         error_code: 'invalid_user_type',
      //       },
      //     },
      //   };
      //   throw res;
      // }
      localStorage.setItem('login', 'true');
      return response;
    } catch (e) {
      next(e.response);
    }
  },

  forgotPassword: async (email) => {
    const endpoint = '/auth/forgot-password';
    try {
      return await Api.post(endpoint, {
        email,
      });
    } catch (e) {
      next(e.response);
    }
  },

  resetPassword: async (id, password) => {
    const endpoint = '/auth/reset-password';
    try {
      return await Api.put(endpoint, {
        id,
        password,
      });
    } catch (e) {
      next(e.response);
    }
  },

  validateRequest: async (token) => {
    const endpoint = '/auth/validate-request';

    try {
      return await Api.post(endpoint, {
        token,
      });
    } catch (e) {
      next(e.response);
    }
  },

  validateEmail: async (token) => {
    const endpoint = '/auth/validate-email';

    try {
      return await Api.post(endpoint, {
        token,
      });
    } catch (e) {
      next(e.response);
    }
  },

  sendEmailVerification: async (email) => {
    const endpoint = '/auth/send-verification';

    try {
      return await Api.post(endpoint, {
        email,
      });
    } catch (e) {
      next(e.response);
    }
  },

  changePassword: async (oldPassword, newPassword, token) => {
    const endpoint = '/auth/change-password';

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await Api.put(
        endpoint,
        {
          oldPassword,
          newPassword,
        },
        config,
      );
      return response;
    } catch (e) {
      next(e.response);
    }
  },

  completeProfile: async (profile, token) => {
    const endpoint = '/user';

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const payload = {
      general: JSON.stringify({
        profession_id: profile.general.profession.id,
        dob: profile.general.dob
          ? new Date(profile.general.dob).toISOString().split('T')[0]
          : '',
        country_id: profile.general.country.id,
        city_id: profile.general.city.id,
      }),
      image: profile.general.image ? profile.general.image.file : null,
      skill: JSON.stringify([
        ...profile.softSkills.map(({ id, rate }) => ({
          id,
          rate: rate.toString(),
        })),
        ...profile.hardSkills.map(({ id, rate }) => ({
          id,
          rate: rate.toString(),
        })),
      ]),
      experience: JSON.stringify(
        profile.experience.map(
          ({ designation, location, company, startYear, endYear }) => ({
            designation,
            company,
            start_year: startYear,
            end_year: endYear,
            location: location,
          }),
        ),
      ),
      education: JSON.stringify(
        profile.education.map(
          ({
            degree,
            fieldOfStudy,
            startYear,
            startUTC,
            endYear,
            endUTC,
            grade,
            location,
            institute,
          }) => ({
            degree_id: JSON.stringify(degree.id),
            study_field_id: JSON.stringify(fieldOfStudy.id),
            start_year: startYear,
            start_year_utc: startUTC,
            end_year: endYear,
            end_year_utc: endUTC,
            grade: grade,
            location: location,
            university_name: institute,
          }),
        ),
      ),
    };

    const formData = new FormData();
    Object.keys(payload).map((key) => formData.append(key, payload[key]));

    try {
      const resp = await Api.post(endpoint, formData, config);
      return resp;
    } catch (e) {
      next(e.response);
    }
  },
};

export default Auth;
