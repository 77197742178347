import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 400,
    fontSize: 14,
  },
  container: {
    marginTop: 4,
    marginBottom: 3,
  },
}));

const Label = ({ label }) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <label className={styles.label}>{label}</label>
    </div>
  );
};

export default Label;
