// import React, { useContext, useEffect, useState } from 'react';
// import { useCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  //   AppBar,
  //   Avatar,
  //   Box,
  //   ButtonBase,
  //   Divider,
  Grid,
  //   ListItemText,
  //   Toolbar,
  //   Typography,
} from '@material-ui/core';

import Navbar from './DashboardNew/Navbar';
// import { makeStyles } from '@material-ui/styles';

// import appBarLogo from '../Assets/images/appbar-logo.svg';
// import { Auth as AuthConstants } from '../Constants';
// import { HomeContext } from '../Contexts';

// import Settings from './../Components/Profile/Settings';
// import Notification from './Profile/Notification';
// import NotificationIcon from './NotificationIcon';
// import ProfileProgressIndicator from './ProfileProgressIndicator';
// import Tabs from './Tabs';

// const useStyles = makeStyles((theme) => ({
//   appBarContainer: {
//     borderBottom: '2px solid #e2e2e2',
//     paddingLeft: '5%',
//     paddingRight: '5%',
//   },
//   appBarPosition: {
//     zIndex: '5',
//     position: 'sticky',
//     top: '0',
//   },
//   divider: {
//     minHeight: '4vh',
//   },
//   notifIcon: {
//     padding: '0 0.5vw',
//   },
//   nameStyle: {
//     fontFamily: 'Avenir-Heavy',
//     fontSize: '16px',
//     color: '#505050',
//   },
//   emailStyle: {
//     fontFamily: 'Avenir-Book',
//     fontSize: '12px',
//     color: '#707070',
//   },
//   avatar: {
//     height: theme.spacing(6),
//     width: theme.spacing(6),
//   },
//   verifiedBar: {
//     backgroundColor: theme.palette.primary.main,
//     minHeight: '50px',
//   },
//   sendEmailButton: {
//     backgroundColor: '#ffffff',
//     marginLeft: '10px',
//   },
// }));

// const smallScreenProps = {
//   component: Box,
//   display: { xs: 'none', md: 'flex' },
// };

const AppBarContainer = (props) => {
  // const classes = useStyles();
  // const context = useContext(HomeContext);
  // const appContext = useContext(AppContext);

  // const route = '/home';
  // const tabIndex = {
  //   dashboard: 0,
  //   jobs: 1,
  //   upskill: 2,
  //   survey: 3,
  //   assessments: 4,
  //   profile: 5,
  //   notifications: 6,
  // };
  // const [, , removeCookie] = useCookies([AuthConstants.JWT_TOKEN]);
  // const [tab, setTab] = useState(tabIndex[props.tab]);
  // const [anchorProfile, setAnchorProfile] = useState();
  // const [anchorNotification, setAnchorNotification] = useState();
  // const profileMenu = [
  //   {
  //     title: 'Profile',
  //     route: `${route}/profile`,
  //   },
  //   {
  //     title: 'Change Password',
  //     route: `${route}/profile/change-password`,
  //   },
  //   {
  //     title: 'FAQ',
  //     route: `${route}/profile/faq`,
  //   },
  // ];
  // useEffect(() => {
  //   setTab(tabIndex[props.tab]);
  // }, [tabIndex, props.tab]);

  return (
    <Grid>
      {/* <Grid
        component={AppBar}
        position='static'
        item
        className={classes.appBarPosition}
      >
        {window.location.pathname === '/home' ||
        window.location.pathname === '/home/dashboard' ? null : (
          <Grid
            component={Toolbar}
            className={classes.appBarContainer}
            direction='row'
            justify='space-between'
            alignItems='center'
            container
          >
            <Grid item>
              <ButtonBase>
                <Link to='/home'>
                  <img src={appBarLogo} alt='SkillScore' />
                </Link>
              </ButtonBase>
            </Grid>
            <Grid item {...smallScreenProps}>
              <Tabs
                value={tab}
                onChange={(oldValue, newValue) => {
                  props.history.push(
                    `${route}/${Object.keys(tabIndex)[newValue].toLowerCase()}`,
                  );
                }}
                items={Object.keys(tabIndex)
                  .slice(0, 5)
                  .map((tab) => ({
                    name: tab.charAt(0).toUpperCase() + tab.slice(1),
                  }))}
              />
            </Grid>
            <Grid item>
              <Grid direction='row' alignItems='center' spacing={2} container>
                <Grid component={Box} item>
                  <Tabs
                    value={5 - tab}
                    noHorizontalPadding={true}
                    noHorizontalMargin={true}
                    onChange={() => {}}
                    items={[
                      {
                        icon: (
                          <div>
                            <ButtonBase
                              className={classes.notifIcon}
                              onClick={(event) =>
                                setAnchorNotification(event.currentTarget)
                              }
                            >
                              <NotificationIcon
                                newNotifications={
                                  context.notifications.unread > 0
                                }
                              />
                            </ButtonBase>
                            <Box>
                              <Notification
                                anchorEl={anchorNotification}
                                open={Boolean(anchorNotification)}
                                onSeeAll={() => {
                                  setAnchorNotification(null);
                                  props.history.push(`${route}/notifications`);
                                }}
                                onSelect={(notification) => {
                                  setAnchorNotification(null);

                                  notification.type === 'survey'
                                    ? props.history.push('/home/survey', {
                                        notification,
                                      })
                                    : props.history.push(
                                        `/home/notifications/${notification.id}`,
                                        {
                                          notification,
                                        },
                                      );
                                }}
                                data={context.notifications.data}
                                onClose={() => setAnchorNotification(null)}
                              />
                            </Box>
                          </div>
                        ),
                      },
                    ]}
                  />
                </Grid>
                <Grid item {...smallScreenProps}>
                  <Divider
                    className={classes.divider}
                    orientation='vertical'
                    color='action'
                  />
                </Grid>
                <Grid component={Box} item {...smallScreenProps}>
                  <ProfileProgressIndicator
                    percentage={parseInt(props.percentage)}
                  />
                </Grid>
                <Grid item {...smallScreenProps}>
                  <Divider
                    className={classes.divider}
                    orientation='vertical'
                    color='action'
                  />
                </Grid>
                <Grid component={Box} item>
                  <Tabs
                    value={4 - tab}
                    noHorizontalPadding={true}
                    noHorizontalMargin={true}
                    onChange={() => {}}
                    items={[
                      {
                        icon: (
                          <div>
                            <ButtonBase
                              onClick={(event) =>
                                setAnchorProfile(event.currentTarget)
                              }
                            >
                              <Avatar
                                alt='Profile Image'
                                src={props.profileImage}
                              />
                            </ButtonBase>
                            <Settings
                              anchorEl={anchorProfile}
                              open={Boolean(anchorProfile)}
                              header={
                                <Grid
                                  direction='row'
                                  spacing={1}
                                  alignItems='center'
                                  container
                                >
                                  <Grid item>
                                    <Avatar
                                      className={classes.avatar}
                                      alt='Profile Image'
                                      src={props.profileImage}
                                    />
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      className={classes.nameStyle}
                                      variant='h4'
                                    >
                                      {props.name}
                                    </Typography>
                                    <Typography
                                      className={classes.emailStyle}
                                      variant='h5'
                                    >
                                      {props.email}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              }
                              footer={
                                <ListItemText
                                  primary={'Sign Out'}
                                  onClick={() => {
                                    removeCookie(AuthConstants.JWT_TOKEN, {
                                      path: '/',
                                    });
                                    localStorage.clear('loginData');
                                    removeCookie(AuthConstants.PROFILE_STATUS, {
                                      path: '/',
                                    });
                                    props.history.push('/sign-in');
                                  }}
                                />
                              }
                              onSelect={(menuItem) => {
                                setAnchorProfile(null);
                                props.history.push(menuItem.route);
                              }}
                              data={profileMenu}
                              onClose={() => setAnchorProfile(null)}
                            />
                          </div>
                        ),
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid> */}
      <div className='flex flex-col'>
        <Navbar />
        <Grid>{props.children}</Grid>
      </div>
    </Grid>
  );
};
AppBarContainer.propTypes = {
  tab: PropTypes.string.isRequired,
  profileImage: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  percentage: PropTypes.number,
};
export default withRouter(AppBarContainer);
