import * as React from 'react';
import { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import UpdateSkillsForm from '../../Components/UpdateSkillsForm';
import { ProfileCompletionContext } from '../../Contexts';

const ProfileSkills = (props) => {
  const context = useContext(ProfileCompletionContext);
  const { profile, setProfile } = context;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { softSkills, hardSkills, ...rest } = profile;

  return (
    <>
      <UpdateSkillsForm
        submitted={({ softSkills, hardSkills }) => {
          const newData = {
            softSkills,
            hardSkills,
            ...rest,
          };
          setProfile(newData);
          props.submitted(newData);
        }}
        skipped={() => {
          props.skipped();
        }}
        backed={props.backed}
      />
    </>
  );
};

ProfileSkills.prototype = {
  submitted: PropTypes.func.isRequired,
  skipped: PropTypes.func.isRequired,
  backed: PropTypes.func.isRequired,
};

export default withRouter(ProfileSkills);
