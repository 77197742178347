// import CameraAltIcon from "@material-ui/icons/CameraAlt";
import React from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ProfileAvatar from '../../Assets/images/profileAvatar.svg';

const useStyles = makeStyles(() => ({
  dragImageIcon: (props) => ({
    borderRadius: '100%',
    border: props.image ? null : 'dashed 1px #979797 ',
    margin: 'auto',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
  }),
  imageSize: {
    height: '100px',
    width: '100px',
    borderRadius: '100%',
  },
  container: {
    margin: 'auto',
  },
}));

const UploadProfile = (props) => {
  const classes = useStyles(props);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        props.onImageUploaded({
          file: acceptedFiles[0],
          preview: URL.createObjectURL(acceptedFiles[0]),
        });
      }
    },
    className: 'dropzone',
    activeClassName: 'active-dropzone',
    multiple: false,
    accept: 'image/*',
  });
  return (
    <Box className={classes.container}>
      <Grid
        {...getRootProps()}
        direction='row'
        className={classes.dragImageContainer}
        alignItems={'center'}
        container
      >
        <input {...getInputProps()} />
        {props.image ? (
          <Box className={classes.dragImageIcon}>
            <img
              alt='Image'
              src={props.image?.preview ? props.image.preview : props.image}
              className={classes.imageSize}
            />
          </Box>
        ) : (
          <Box className={classes.dragImageIcon}>
            <img
              alt='Image'
              src={ProfileAvatar}
              className={classes.imageSize}
            />
          </Box>
        )}
      </Grid>
      <Grid {...getRootProps()}>{props.typography}</Grid>
    </Box>
  );
};

UploadProfile.propTypes = {
  typography: PropTypes.any,
  image: PropTypes.any,
  onImageUploaded: PropTypes.any,
};

export default UploadProfile;
