import React from 'react';
import { useTranslation } from 'react-i18next';

const SearchInput = ({ value, onChange, placeholder, label, inputStyle }) => {
  const { i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';
  return (
    <div className='flex flex-wrap  sm:flex-nowrap justify-start sm:justify-end items-center w-full pt-3'>
      {label ? (
        <span
          className={`text-sm sm:text-lg font-normal ${
            isRTL ? 'ml-2' : 'mr-2'
          } text-primaryBg`}
        >
          {label}
        </span>
      ) : null}

      <input
        type='text'
        id='input'
        name='input'
        className={`py-2 w-full sm:w-96 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-400 ${inputStyle}`}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default SearchInput;
