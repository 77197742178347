import Api from './index';

const Skills = {
  list: async (
    profession_id = '',
    config,
    { type = 'h', query = '', beg = 0, size = 10 },
  ) => {
    query = encodeURIComponent(query);
    const endpoint = `/skill?profession_id=${profession_id}&type=${type}&q=${query}&page=${beg}&size=${size}&orderBy=asc`;
    return await Api.get(endpoint, config);
  },
};

export default Skills;
