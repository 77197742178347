/* eslint-disable no-alert */
/* eslint-disable simple-import-sort/imports */
import React, { useState, useContext } from 'react';
import Accordion from 'src/Components/LandingPage/Accordian';
import { IoIosArrowDown } from 'react-icons/io';
import CareerApplyDialog from 'src/Components/CareerApplyDialog';
import { Misc } from 'src/Api';
import { AppContext } from 'src/Contexts';
import { COMMON } from 'src/Constants';

const JobAccordion = ({ job }) => {
  const { title, description, responsibilities, requirements } = job;
  const [open, setOpen] = useState(false);
  const context = useContext(AppContext);

  const handleApplyJobs = async (data) => {
    const formData = new FormData();

    formData.append('job_title', data.jobTitle);
    formData.append('first_name', data.firstName);
    formData.append('last_name', data.lastName);
    formData.append('email', data.email);
    formData.append('phone', data.phone);

    if (data.resume) {
      formData.append('resume', data.resume);
    }

    try {
      const response = await Misc.applyforJob(formData);

      if (response.error === false) {
        context.showError(COMMON[response.data.message]);
        setOpen(false);
      }
    } catch (error) {
      context.showError(COMMON[error.message]);
    }
  };

  return (
    <Accordion
      title={title}
      description={description}
      onOpen={() => setOpen(true)}
      buttonTitle='Apply'
      containerStyle='custom-container-style'
      icon={<IoIosArrowDown />}
      titleStyle='custom-title-style'
    >
      <div className='overflow-y-scroll h-[60vh] sm:h-auto'>
        <p className='text-DarkBluePurple pb-1 pt-2 font-semibold '>
          Responsibilities:
        </p>
        <ul className='list-disc ml-5'>
          {responsibilities.map((responsibility, index) => (
            <li
              className='text-xs md:text-sm leading-5 md:leading-6'
              key={index}
            >
              {responsibility}
            </li>
          ))}
        </ul>
        <p className='text-DarkBluePurple pb-1 pt-2 font-semibold'>
          Requirements:
        </p>
        <ul className='list-disc ml-5'>
          {requirements.map((responsibility, index) => (
            <li
              className='text-xs md:text-sm leading-5 md:leading-6'
              key={index}
            >
              {responsibility}
            </li>
          ))}
        </ul>
        {/* <p className='text-DarkBluePurple pb-1 pt-2 font-semibold'>Contact:</p>{' '}
        <p className='text-xs md:text-sm leading-5 md:leading-6'>
          {' '}
          Interested candidates are invited to submit their resume and a
          portfolio of relevant projects to{' '}
          <a
            className=' font-medium text-DarkBluePurple underline'
            href='mailto:hr@skilledscore.com'
          >
            hr@skilledscore.com
          </a>
        </p> */}
        {/* <div className='mt-2'>
          <button
            onClick={() => setOpen(true)}
            className='px-2 py-1 w-40 bg-BluePurple hover:bg-lightBg hover:text-secondary text-white  rounded-md mt-1 transition-all duration-300 ease-in-out  hover:transform hover:translate-x-2'
          >
            Apply
          </button>
        </div> */}
        <CareerApplyDialog
          title={title}
          open={open}
          onClose={() => setOpen(false)}
          onSubmitted={handleApplyJobs}
        />
      </div>
    </Accordion>
  );
};

export default JobAccordion;
