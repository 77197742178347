import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import LoaderIcon from './LoaderIcon'; // Ensure you have this component correctly imported

const CareerApplyInput = ({ title, onSubmitted }) => {
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState('');
  const [captchaError, setCaptchaError] = useState('');

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      resume: null,
      consent: false,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      phone: Yup.string().required('Phone is required'),
      resume: Yup.mixed()
        .required('Resume is required')
        .test(
          'fileSize',
          'File too large (max 5MB)',
          (value) => !value || value.size <= 5 * 1024 * 1024,
        )
        .test(
          'fileType',
          'Unsupported file format',
          (value) => !value || /\.(pdf|docx?)$/i.test(value.name),
        ),
      consent: Yup.boolean()
        .oneOf([true], 'You must grant consent to proceed.')
        .required('Consent is required'),
    }),
    onSubmit: async (values) => {
      if (!captcha) {
        setCaptchaError('Please complete the reCAPTCHA verification.');
        return;
      }

      setLoading(true);
      try {
        await onSubmitted({
          jobTitle: title,
          ...values,
          captchaToken: captcha, // Include captcha token for backend validation
        });
        formik.resetForm();
        setCaptcha(''); // Reset captcha state
        setCaptchaError(''); // Clear captcha error
      } catch (error) {
        console.error('Error submitting form:', error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className='flex flex-col space-y-4'>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
        <div>
          <input
            type='text'
            id='firstName'
            name='firstName'
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder='First Name*'
            className={`border rounded-md p-2 w-full ${
              formik.touched.firstName && formik.errors.firstName
                ? 'border-red-500'
                : 'border-gray-300'
            }`}
          />
          {formik.touched.firstName && formik.errors.firstName && (
            <p className='text-red-500 text-sm'>{formik.errors.firstName}</p>
          )}
        </div>

        <div>
          <input
            type='text'
            id='lastName'
            name='lastName'
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder='Last Name*'
            className={`border rounded-md p-2 w-full ${
              formik.touched.lastName && formik.errors.lastName
                ? 'border-red-500'
                : 'border-gray-300'
            }`}
          />
          {formik.touched.lastName && formik.errors.lastName && (
            <p className='text-red-500 text-sm'>{formik.errors.lastName}</p>
          )}
        </div>

        <div>
          <input
            type='email'
            id='email'
            name='email'
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder='Email*'
            className={`border rounded-md p-2 w-full ${
              formik.touched.email && formik.errors.email
                ? 'border-red-500'
                : 'border-gray-300'
            }`}
          />
          {formik.touched.email && formik.errors.email && (
            <p className='text-red-500 text-sm'>{formik.errors.email}</p>
          )}
        </div>

        <div>
          <input
            type='text'
            id='phone'
            name='phone'
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder='Phone*'
            className={`border rounded-md p-2 w-full ${
              formik.touched.phone && formik.errors.phone
                ? 'border-red-500'
                : 'border-gray-300'
            }`}
          />
          {formik.touched.phone && formik.errors.phone && (
            <p className='text-red-500 text-sm'>{formik.errors.phone}</p>
          )}
        </div>
      </div>

      <div>
        <label
          htmlFor='resume'
          className='block text-sm font-medium text-gray-700 mb-2'
        >
          Upload Resume (Max 5MB, PDF or Word)
        </label>
        <input
          type='file'
          id='resume'
          name='resume'
          accept='.pdf,.doc,.docx'
          onChange={(event) => {
            formik.setFieldValue('resume', event.target.files[0]);
          }}
          className='hidden'
        />
        <label
          htmlFor='resume'
          className='inline-block bg-blue-500 text-white px-4 py-2 rounded-md cursor-pointer hover:bg-blue-600'
        >
          Upload Resume*
        </label>
        {formik.values.resume && (
          <p className='text-sm text-gray-700 mt-2'>
            {formik.values.resume.name}
          </p>
        )}
        {formik.touched.resume && formik.errors.resume && (
          <p className='text-red-500 text-sm'>{formik.errors.resume}</p>
        )}
      </div>

      <div className='mb-3 xl:mb-0 w-full'>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA || ''}
          onChange={(value) => {
            setCaptcha(value);
            setCaptchaError('');
          }}
        />
        <div>
          {captchaError && (
            <p className='text-red-500 text-left'>{captchaError}</p>
          )}
        </div>
      </div>

      {/* Consent Checkbox */}
      <div className='flex items-center space-x-2'>
        <input
          type='checkbox'
          id='consent'
          name='consent'
          checked={formik.values.consent} // Ensuring the checkbox value is correctly handled
          onChange={formik.handleChange} // Formik's handleChange will update the consent value
          onBlur={formik.handleBlur}
          className={`h-4 w-4 border-gray-300 rounded ${
            formik.touched.consent && formik.errors.consent
              ? 'border-red-500'
              : 'border-gray-300'
          }`}
        />
        <label htmlFor='consent' className='text-sm'>
          I hereby grant my consent to calculate the Skilledscore and overall
          score, and to share my resume with other employers and other potential
          opportunities.
        </label>
      </div>
      {formik.touched.consent && formik.errors.consent && (
        <p className='text-red-500 text-sm'>{formik.errors.consent}</p>
      )}

      <div className='flex justify-end items-center mt-4'>
        <button
          disabled={loading}
          className='bg-primaryBg shadow-md shadow-gray-400 text-white px-8 mt-5 p-3 rounded-lg capitalize'
        >
          {loading ? <LoaderIcon blue={true} /> : 'Apply'}
        </button>
      </div>
    </form>
  );
};

CareerApplyInput.propTypes = {
  onSubmitted: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default CareerApplyInput;
