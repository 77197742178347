import Api from './index';
import Response from './Response';

const { next } = Response;

const Misc = {
  listCountries: async (token) => {
    const endpoint = '/misc/country';

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      return await Api.get(endpoint, config);
    } catch (e) {
      next(e.response);
    }
  },

  listCities: async (
    country_id = '',
    config,
    { query = '', last = null, size = 50 },
  ) => {
    const endpoint = `/misc/country/${country_id}/city?name=${query}&size=${size}${
      last ? `&last=${last}` : ''
    }`;

    try {
      return await Api.get(endpoint, config);
    } catch (e) {
      next(e.response);
    }
  },
  geoip: async () => {
    const clientIpResponse = await fetch('https://api.ipify.org?format=json');
    const clientIpData = await clientIpResponse.json();
    const clientIp = clientIpData.ip;

    try {
      return await Api.get(`misc/geoip?ip=${clientIp}`);
    } catch (e) {
      next(e.response);
    }
  },
  applyforJob: async (data) => {
    const endpoint = '/careers';

    try {
      const resp = await Api.post(endpoint, data);
      if (resp?.data?.error === false) {
        return resp.data;
      }
      throw new Error('Unexpected success response format');
    } catch (e) {
      // Handle 400 errors
      if (e.response && e.response.status === 400) {
        const error = e.response.data;
        throw new Error(error.error_code);
      }
      throw new Error('An unexpected error occurred. Please try again.');
    }
  },
};

export default Misc;
