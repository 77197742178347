import Api from './index';

const getConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const UserJobs = {
  jobs: async (token, data) => {
    const endpoint = '/job/get-user-jobs';
    return await Api.post(endpoint, { data }, getConfig(token));
  },
  getSearchJobs: async (token, data) => {
    const endpoint = '/job/search-jobs';
    return await Api.post(endpoint, { data }, getConfig(token));
  },
  save_job: async (token, data) => {
    const endpoint = '/job/saved-jobs';
    return await Api.post(endpoint, { data }, getConfig(token));
  },
  get_save_job: async (token, data) => {
    const endpoint = '/job/get-saved-jobs';
    return await Api.post(endpoint, { data }, getConfig(token));
  },
  getJobDescription: async (token, data) => {
    const endpoint = '/job/get-job-description';
    return await Api.post(endpoint, { data }, getConfig(token));
  },
};

export default UserJobs;
