import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';

import { ThemeProvider } from '@material-ui/styles';

import theme from './Theme/muiTheme';
import App from './App';
import { AppContextProvider } from './Contexts';
import * as serviceWorker from './serviceWorker';

import './index.css';

import './i18n';

// TEST

ReactDOM.render(
  <CookiesProvider>
    <AppContextProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </AppContextProvider>
  </CookiesProvider>,
  document.getElementById('root'),
);
serviceWorker.unregister();
