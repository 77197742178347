import React, { useState } from 'react';

const ProfileCompletionContext = React.createContext({
  profile: {},
  setProfile: () => {},
});

const ProfileCompletionProvider = (props) => {
  const initState = {
    profile: {
      name: '',
      general: {
        profession: {},
        dob: null,
        country: {},
        city: {},
        image: null,
      },
      softSkills: [],
      hardSkills: [],
      experience: [],
      education: [],
    },
  };

  const [state, setState] = useState(initState);

  const setProfile = (profile) => {
    setState({ ...state, profile });
  };

  const setName = (name) => {
    setState({ ...state, profile: { ...state.profile, name } });
  };

  const deleteExperience = (id) => {
    const { experience, ...rest } = state.profile;
    experience.splice(id.id, 1);
    setState({ ...state, profile: { ...rest, experience } });
  };

  const deleteEducation = (id) => {
    const { education, ...rest } = state.profile;
    education.splice(id.id, 1);
    setState({ ...state, profile: { ...rest, education } });
  };

  const addEducation = ({
    id,
    degree,
    institute,
    fieldOfStudy,
    startYear,
    startUTC,
    endUTC,
    endYear,
    grade,
    location,
  }) => {
    const { education, ...rest } = state.profile;

    if (id === -1) {
      const newData = {
        ...rest,
        education: [
          ...education,
          {
            degree,
            institute,
            fieldOfStudy,
            startYear,
            startUTC,
            endUTC,
            endYear,
            grade,
            location,
          },
        ],
      };
      setProfile(newData);
    } else {
      const newData = [...education];
      newData[id] = {
        degree,
        institute,
        fieldOfStudy,
        startYear,
        startUTC,
        endUTC,
        endYear,
        grade,
        location,
      };
      setProfile({ ...rest, education: newData });
    }
  };

  return (
    <ProfileCompletionContext.Provider
      value={{
        ...state,
        setProfile,
        setName,
        deleteExperience,
        deleteEducation,
        addEducation,
      }}
    >
      {props.children}
    </ProfileCompletionContext.Provider>
  );
};

export { ProfileCompletionContext, ProfileCompletionProvider };
