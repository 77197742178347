import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@material-ui/core';

const BlankListPlaceholder = (props) => {
  return (
    <Grid
      direction='column'
      alignItems='center'
      justify='center'
      alignContent='center'
      container
    >
      <Typography style={{ marginTop: '20%' }} variant='subtitle2'>
        {props.text}
      </Typography>
    </Grid>
  );
};

BlankListPlaceholder.propTypes = {
  text: PropTypes.string.isRequired,
};

export default BlankListPlaceholder;
