import React, { useEffect } from 'react';

import PolicySection from 'src/Components/PolicySection';
import { Content } from 'src/Constants/index';
import { scrollSmoothTo } from 'src/Utils/utils';

const PrivacyPolicy = () => {
  const {
    PRIVACY_POLICY: { heading, title, points, thanksMessage },
  } = Content;

  useEffect(() => {
    scrollSmoothTo(heading);
  }, []);

  return (
    <div className='user_select'>
      <PolicySection
        pageTitle={heading}
        pageContent={{ title, points, thanksMessage }}
      />
    </div>
  );
};

export default PrivacyPolicy;
