import Api from './index';

const getConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
    // crossDomain: true,
    // withCredentials: true,
  },
});

const Notifications = {
  list: async (token, { beg = 0, size = 10 }) => {
    const endpoint = `/user/notification?page=${beg}&size=${size}`;
    return await Api.get(endpoint, getConfig(token));
  },

  markRead: async (id, token) => {
    const endpoint = `/user/notification/${id}`;
    return await Api.put(endpoint, null, getConfig(token));
  },
};

export default Notifications;
