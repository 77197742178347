import Api from './index';

const getConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const Pathway = {
  list: async (token) => {
    const endpoint = '/pathway';
    return await Api.get(endpoint, getConfig(token));
  },
};

export default Pathway;
