import React from 'react';

const ChatSection = ({
  icon,
  description,
  paragraph,
  direction,
  containerStyle,
}) => {
  return (
    <div
      className={`${
        direction ? 'lg:flex-row-reverse' : 'flex-row'
      } flex ${containerStyle} justify-center mb-10 lg:mb-0`}
    >
      <div>
        <img src={icon} width={40} height={40} />
      </div>
      <div className={'flex flex-col items-end'}>
        <div className='w-72 h-40 bg-white p-8 rounded-tl-[40px] font-[Karla-Regular] rounded-tr-[40px] rounded-br-[40px]'>
          <p className='h-24'>{paragraph}</p>
        </div>
        <div className='bg-SSPurple rounded-full w-72 p-3 text-white mt-4 lg:mt-12 -ml-10'>
          <p className='text-center'>{description}</p>
        </div>
      </div>
    </div>
  );
};
export default ChatSection;
