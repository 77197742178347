import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next'; // For localization
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Header } from 'src/Components/LandingPage';
import LoaderButton from 'src/Components/LoaderButton';
import { AppContext } from 'src/Contexts';
import Routes from 'src/Utils/Routes';

import { Auth } from '../../../Api';
import Logo from '../../../Assets/images/v1/icons/logo.svg';
import Avatar from '../../../Assets/images/v1/icons/sign-in-avatar.svg';
import FooterHelpLink from '../FooterHelpLink';

const ForgetPassword = () => {
  const { t } = useTranslation(); // Initialize translation
  const [isSubmitted, setIsSubmitted] = useState(false);
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { language } = useContext(AppContext);
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t('login.validation.email_invalid'))
        .required(t('login.validation.email_required')),
    }),
    onSubmit: async (values) => {
      await onSubmit(values.email);
    },
  });

  const onSubmit = async (email) => {
    setLoader(true);
    setErrorMessage('');
    try {
      const response = await Auth.forgotPassword(email);
      if (response.status) {
        setLoader(false);
        setIsSubmitted(true);
      }
    } catch (e) {
      setErrorMessage(e.message);
      setLoader(false);
    }
  };

  return (
    <div
      className={`flex flex-col md:flex-row bg-lightGrey h-screen md:h-full justify-between ${
        language === 'en' ? 'md:flex-row' : 'flex-row-reverse'
      }`}
    >
      <div className='block md:hidden bg-primaryBg px-4'>
        <Header />
      </div>
      <div className='bg-primaryBg hidden md:block w-full md:w-2/5 px-4 md:px-14 h-screen min-h-[800px] text-white'>
        <Link to='/'>
          <img className='mt-10' src={Logo} alt={t('form.logo_alt')} />
        </Link>
        <div className='h-4/5 '>
          <img
            className='w-auto mt-10 mx-auto'
            src={Avatar}
            alt={t('form.avatar_alt')}
          />
          <h1 className='mx-auto text-xl font-[Karla-Bold] my-5 uppercase'>
            {t('form.forget_password')}
          </h1>
          <p className='mx-auto font-[Karla-regular] text-lightGrey'>
            {t('form.reset_password_instructions')}
          </p>
        </div>

        <div className='hidden md:block'>
          <FooterHelpLink />
        </div>
      </div>
      <div className='flex flex-col md:justify-center mx-auto my-10'>
        <div className='mx-auto flex flex-col justify-center md:mx-auto w-full md:w-96'>
          {isSubmitted ? (
            <>
              <div className='px-10 py-4 my-3 rounded-xl'>
                {t('form.reset_link_sent')}
              </div>
              <button
                onClick={() => history.push(Routes.Login)}
                className='flex justify-center bg-BluePurple text-white my-1.5 font-medium w-full rounded-lg text-sm py-3'
              >
                {t('form.back_to_login')}
              </button>
            </>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <div className='w-full text-center p-4'>
                <h1 className='text-3xl my-5 font-bold text-gray-900'>
                  {t('form.forget_password')}
                </h1>
                <p className='text-lg my-5'>{t('form.enter_email_to_reset')}</p>
                <input
                  type='text'
                  className='border-2 w-full h-12 px-3 mb-3 rounded-lg'
                  placeholder={t('signup.validation.email_placeholder')}
                  value={formik.values.email}
                  name='email'
                  id='email'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />

                {(formik.touched.email && formik.errors.email) ||
                (errorMessage && !formik.errors.email) ? (
                  <div className='text-start mb-2 text-red-500'>
                    {formik.errors.email || errorMessage}
                  </div>
                ) : null}

                {loader ? (
                  <LoaderButton />
                ) : (
                  <button
                    type='submit'
                    className='flex justify-center bg-BluePurple text-white font-medium w-full rounded-lg text-sm py-3'
                  >
                    {t('form.send_button')}
                  </button>
                )}
                <p className='flex justify-end text-BluePurple hover:cursor-pointer'>
                  <Link to={Routes.Login}>
                    {t('form.back_to_login_question')}
                  </Link>
                </p>
              </div>
            </form>
          )}
        </div>
      </div>
      <div className='block md:hidden pb-6 bg-lightGrey'>
        <FooterHelpLink linkStyle='text-black' iconColor='#000' />
      </div>
    </div>
  );
};

export default ForgetPassword;
