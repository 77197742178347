import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import GetPaymentHistory from 'src/Api/GetUserPayment';
import Payment from 'src/Api/UserPayment';
import { PAYMENT_STATUS } from 'src/Constants/Common';

import { User } from '../Api';
import { Auth as AuthConstants } from '../Constants';

const AppContext = React.createContext({});

const AppContextProvider = (props) => {
  const [user, setUser] = useState({
    percentage: 0,
    name: '',
    email: '',
    image: '',
    resume: false,
    email_verified: 1,
  });
  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const token = cookies[AuthConstants.JWT_TOKEN];
  const [message, setMessage] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [userPayment, setUserPayment] = useState(null);
  const [language, setLanguage] = useState('en');

  const showError = (message) => {
    setMessage(message);
    setToggle(!toggle);
  };

  const updateUser = (token) => {
    User.getProfileStatus(token).then((res) => {
      setUser({ ...res, percentage: Number(res.percentage) });
    });
  };

  const fetchUserPayment = async (loginToken) => {
    const isUserLogin = localStorage.getItem('loginData');

    if (isUserLogin) {
      try {
        const data = await GetPaymentHistory.UserPayment(
          loginToken ? loginToken : token,
        );

        if (Object.keys(data?.data?.data)?.length) {
          const isExpiryDate = data.data.data?.is_expiry_date;

          if (isExpiryDate) {
            const currentUnixTimestamp = Math.floor(Date.now() / 1000); // Get current time in Unix format (seconds)

            if (isExpiryDate < currentUnixTimestamp) {
              await Payment.updateUserStatus(loginToken ? loginToken : token);

              return setUserPayment({
                payment_id: '',
                payment_status: PAYMENT_STATUS.NOT_PAID,
              });
            }
          }

          return setUserPayment(data.data.data);
        }

        // If no data is found, set payment as NOT_PAID
        setUserPayment({
          payment_id: '',
          payment_status: PAYMENT_STATUS.NOT_PAID,
        });
      } catch (error) {
        console.error(error);
        setUserPayment({
          payment_id: '',
          payment_status: PAYMENT_STATUS.NOT_PAID,
        });
      }
    }
  };

  useEffect(() => {
    fetchUserPayment();
  }, []);

  return (
    <AppContext.Provider
      value={{
        message,
        showError,
        setToggle,
        toggle,
        updateUser,
        user,
        fetchUserPayment,
        userPayment,
        setLanguage,
        language,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
