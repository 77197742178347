import React from 'react';
import PropTypes from 'prop-types';

import CareerApplyInputForm from './CareerApplyInputForm';
import Dialog from './Dialog';

const CareerApplyDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.onClose} title={props.title}>
      <CareerApplyInputForm
        title={props.title}
        onSubmitted={(data) => props.onSubmitted(data)}
      />
    </Dialog>
  );
};

CareerApplyDialog.propTypes = {
  data: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func,
};

export default CareerApplyDialog;
