/* eslint-disable simple-import-sort/imports */

import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

import { Button } from '@material-ui/core';
import { PublicClientApplication } from '@azure/msal-browser';

import { Auth } from '../Api';
import Threads from '../Api/Threads';
import microsoft from '../Assets/images/microsoft-light.png';
import { Auth as AuthConstants } from '../Constants';
import { AppContext } from '../Contexts';

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: process.env.REACT_APP_BASE_URL,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

const MicrosoftSignOn = ({ label = '' }) => {
  const history = useHistory();

  const { fetchUserPayment } = useContext(AppContext);

  const [, setCookie] = useCookies([AuthConstants.JWT_TOKEN]);

  const login = async () => {
    try {
      await msalInstance.initialize();
      const loginResponse = await msalInstance.loginPopup({
        scopes: ['user.read'],
      });

      const res = await Auth.microsoftSignOn(loginResponse?.accessToken);

      const threadData = await Threads.getThread(res?.data?.data?.token);

      localStorage.setItem(
        'loginData',
        JSON.stringify({
          user: {
            ...res.data.data,
            thread_id: threadData?.data?.data?.id ?? null,
          },
        }),
      );
      setCookie(AuthConstants.JWT_TOKEN, res.data.data.token, {
        path: '/',
      });

      setCookie(AuthConstants.PROFILE_STATUS, res.data.data.profile_status, {
        path: '/',
      });

      fetchUserPayment(res?.data?.data?.token);

      history.push('/home');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Button
      onClick={login}
      variant='contained'
      style={{
        backgroundColor: '#fff',
        fontWeight: '600',
        width: '100%',
        lineHeight: '1',
      }}
    >
      <img
        src={microsoft}
        alt='Sign in with Microsoft'
        style={{ maxWidth: '35px' }}
      />
      &nbsp;&nbsp;
      {label}
    </Button>
  );
};

export default MicrosoftSignOn;
