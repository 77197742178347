import * as React from 'react';
import { withRouter } from 'react-router-dom';

import ForgetPasswordComponent from '../Components/v1/ForgotPassword';

const ForgetPassword = (props) => {
  return (
    <ForgetPasswordComponent onSubmit={() => props.history.push('/sign-in')} />
  );
};

export default withRouter(ForgetPassword);
