import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';

import Misc from '../Api/Misc';
import { Auth as AuthConstants } from '../Constants';

import SearchDropdown from './SearchDropdown';

import 'isomorphic-fetch';

const CountrySelect = (props) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open] = useState(false);

  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const token = cookies[AuthConstants.JWT_TOKEN];

  useEffect(() => {
    if (!loading) {
      return undefined;
    }

    Misc.listCountries(token)
      .then((res) => {
        if (res?.data?.data) setOptions(res.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <SearchDropdown
      value={props.value}
      onChange={(item) => {
        props.onChange(item);
      }}
      labelAttribute='name'
      matchAttribute='id'
      width='100%'
      data={options}
      loading={loading}
      inputPlaceholder={props.placeholder}
      requestMoreData={() => {}}
      onSearch={() => {}}
    />
  );
};

CountrySelect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  placeholder: PropTypes.string,
};

CountrySelect.defaultProps = {
  value: null,
  placeholder: 'Country',
};

export default CountrySelect;
