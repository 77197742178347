import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ListInputForm from '../../Components/ListInputForm';
import { ProfileCompletionContext } from '../../Contexts';

const useStyles = makeStyles(() => ({
  formContainer: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '24px',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  },
}));

const ProfileExperience = (props) => {
  const classes = useStyles();
  const context = useContext(ProfileCompletionContext);

  const [dataState, setDataState] = useState([]);

  useEffect(() => {
    const newData = context.profile.experience.map((item) => {
      return {
        ...item,
        title: `${item.designation}`,
        subtitle: `${item.company}, ${item.location ?? ''}`,
        date: `${item.startYear} - ${!item.endYear ? 'current' : item.endYear}`,
        locationText: item.location,
      };
    });
    setDataState(newData);
  }, [context]);

  return (
    <Box className={classes.formContainer}>
      <Grid xs={12} container>
        <ListInputForm
          submitted={props.submitted}
          skipped={props.skipped}
          deleteItem={context.deleteExperience}
          data={dataState}
          backed={props.backed}
          isChildren={dataState.length}
          title='Add Experience'
          subTitle='Let us know the experience you have mastery on!'
          variant='experience'
          blankPlaceholder='Please add your experience from here'
        />
      </Grid>
    </Box>
  );
};

ProfileExperience.prototype = {
  submitted: PropTypes.func.isRequired,
  skipped: PropTypes.func.isRequired,
  backed: PropTypes.func.isRequired,
};

export default withRouter(ProfileExperience);
