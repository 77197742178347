import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { User } from 'src/Api';
import HeroImage from 'src/Assets/images/v1/hero-image.webp';
import Avatar1 from 'src/Assets/images/v1/review-avatar1.webp';
import Avatar2 from 'src/Assets/images/v1/review-avatar2.webp';
import Avatar3 from 'src/Assets/images/v1/review-avatar3.webp';
import { AppContext } from 'src/Contexts';

import Aeroplane from '../../../Assets/images/v1/icons/aeroplane.svg';
import Badge from '../../../Assets/images/v1/icons/badge.svg';
import EmailIcon from '../../../Assets/images/v1/icons/email-icon.svg';
import Path from '../../../Assets/images/v1/icons/path.svg';
import VideoRecorder from '../../../Assets/images/v1/icons/video-recorder.svg';
import ChatSection from '../ChatSectionHero';
import StatsWithIcon from '../StatsWithIcon';
import { Header, Text } from '../';

function Hero() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const history = useHistory();
  const { t } = useTranslation();
  const { language } = useContext(AppContext);

  const checkUser = async () => {
    if (!email) {
      setEmailError(t('hero.email_required'));
      return;
    }
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isValidEmail = emailPattern.test(email);

    if (!isValidEmail) {
      setEmailError(t('hero.email_invalid'));
      return;
    }
    setLoading(true);
    const res = await User.getUserByEmail(email);
    if (!res) {
      history.push('/sign-up', { email });
    } else {
      history.push('/sign-in', { email });
    }
    setEmail('');
    setLoading(false);
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
    setEmailError('');
  };

  return (
    <div className='bg-primaryBg px-2 min-h-screen '>
      <div className='max-w-screen-2xl mx-auto'>
        <div className='mx-2 lg:px-8'>
          <Header />
        </div>
        <div className='flex justify-between flex-wrap xl:flex-nowrap py-10'>
          <div className='flex flex-col items m-auto px-2'>
            <Text className='text-4xl px-2 lg:px-0 md:text-heading font-bold text-white leading-[40px] md:leading-[60px] !font-[IBM-Ple-Sans]'>
              {t('hero.title')} <br />
              {t('hero.title2')}
            </Text>
            <Text className='lg:mx-0 px-2 lg:px-0 text-white w-full mt-2 text-regular'>
              {t('hero.subtitle')}
            </Text>
            <div className='flex flex-wrap mt-10 mb-2 md:ml-0 justify-center md:justify-start'>
              <div className='px-4 md:px-0 w-full md:w-auto mb-4'>
                <div className='flex ml-2'>
                  <div
                    className={`z-10 ${
                      language === 'en' ? '-mr-9' : '-ml-9'
                    }  mt-5`}
                  >
                    <img src={EmailIcon} alt='Email Icon' />
                  </div>
                  <input
                    type='email'
                    value={email}
                    onChange={handleChange}
                    className='text-base h-12 flex w-full md:w-80 rounded-md mt-2 outline-none px-12 font-[Karla-Regular]'
                    placeholder={t('hero.email_placeholder')}
                  />
                </div>
                {emailError && (
                  <div className='flex justify-start mt-2 h-10'>
                    <p className='text-red-500'>{emailError}</p>
                  </div>
                )}
              </div>
              <button
                disabled={loading}
                onClick={checkUser}
                className='gradient-button h-12 text-primary flex justify-center items-center mt-3 md:mt-2 md:ml-2 lg:ml-6 px-3 mx-2 rounded-md'
              >
                {loading ? (
                  <div
                    role='status'
                    className='w-24 flex justify-center items-center'
                  >
                    <svg
                      aria-hidden='true'
                      className='w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-white fill-secondary'
                      viewBox='0 0 100 101'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                        fill='currentColor'
                      />
                      <path
                        d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                        fill='currentFill'
                      />
                    </svg>
                    <span className='sr-only'>Loading...</span>
                  </div>
                ) : (
                  <Text className='m-0 p-0 text-base'>{t('hero.cta')}</Text>
                )}
              </button>
            </div>

            <div className='flex flex-wrap lg:w-4/5 mx-auto md:ml-0 mt-8 md:mt-2'>
              <div className='w-1/2'>
                <StatsWithIcon
                  icon={VideoRecorder}
                  stat='2.5'
                  description={t('hero.stats.assessments')}
                />
              </div>
              <div className='w-1/2'>
                <StatsWithIcon
                  icon={Aeroplane}
                  stat='108'
                  description={t('hero.stats.jobs_posted')}
                />
              </div>
              <div className='w-1/2'>
                <StatsWithIcon
                  icon={Badge}
                  stat='147'
                  description={t('hero.stats.reviews')}
                />
              </div>
              <div className='w-1/2'>
                <StatsWithIcon
                  icon={Path}
                  stat='200'
                  description={t('hero.stats.career_paths')}
                />
              </div>
            </div>
          </div>
          <div className='mt-10 md:h-[597px] w-[611px] mx-auto'>
            <img src={HeroImage} alt='Hero Image' />
          </div>
        </div>
        <div className='py-20 lg:justify-between flex flex-col lg:flex-row xl:px-32'>
          <div>
            <ChatSection
              icon={Avatar1}
              description={t('hero.chat_descriptions.sara')}
              paragraph={t('hero.reviews.review1')}
              containerStyle='lg:-mt-32'
            />
          </div>
          <div>
            <ChatSection
              icon={Avatar2}
              description={t('hero.chat_descriptions.amna')}
              paragraph={t('hero.reviews.review2')}
              containerStyle='lg:-mt-10'
            />
          </div>
          <div>
            <ChatSection
              icon={Avatar3}
              description={t('hero.chat_descriptions.bilal')}
              paragraph={t('hero.reviews.review3')}
              direction
              containerStyle='lg:-mt-20'
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
