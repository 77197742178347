/* eslint-disable simple-import-sort/imports */
import { RiLinkedinFill } from 'react-icons/ri';

import FacebookLogo from '../../Assets/images/v1/icons/Facebook.svg';
import InstagramIcon from '../../Assets/images/v1/icons/Instagram.svg';
import TwitterIcon from '../../Assets/images/v1/icons/x-twitter-white.svg';

const FooterEnd = () => {
  return (
    <div className='flex sm:items-center sm:justify-between sm:flex-row-reverse items-center flex-col'>
      {/* <span className=' text-2xl  text-gray-500 sm:text-center dark:text-gray-400'>
        <a href='#' className='hover:underline'>
          SkilledScore
        </a>
        © 2023
      </span> */}
      <div className='flex mb-4 sm:mb-0 space-x-5 sm:justify-center sm:mt-0'>
        <a
          href='https://www.facebook.com/SkilledScore'
          target='_blank'
          rel='noreferrer'
          className='text-gray-500 hover:text-gray-900 dark:hover:text-white'
        >
          <img className='w-7 h-7' src={FacebookLogo} />
        </a>
        <a
          href='https://twitter.com/skilledscore'
          target='_blank'
          rel='noreferrer'
          className='text-gray-500 hover:text-gray-900 dark:hover:text-white'
        >
          <img className='w-7 h-7' src={TwitterIcon} />
        </a>
        <a
          href='https://www.instagram.com/skilled.score/'
          target='_blank'
          rel='noreferrer'
          className='text-gray-500 hover:text-gray-900 dark:hover:text-white'
        >
          <img className='w-7 h-7' src={InstagramIcon} />
        </a>
        <a
          href='https://www.linkedin.com/company/skilledscore'
          target='_blank'
          rel='noreferrer'
          className=' bg-white w-7 h-7 flex justify-center items-center rounded-full'
        >
          <RiLinkedinFill />
        </a>
      </div>
      <p className='text-gray-500'>
        © {new Date().getFullYear()} SkilledScore{' '}
      </p>
    </div>
  );
};
export default FooterEnd;
