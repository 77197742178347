import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CareerSuggestion, JobSuggestion } from 'src/Assets/images/dashboard';
import { AppContext } from 'src/Contexts';

import FeaturesBottomRight from '../FeatureBottomright';
import FeaturesTopLeft from '../FeaturesTopLeft';

const FeaturesSection = () => {
  const { t } = useTranslation();
  const content = t('features_section', { returnObjects: true });
  const { language } = useContext(AppContext);

  return (
    <div className='px-10 lg:px-16 md:px-28 font-[Karla-Regular] lg:mx-20'>
      <div className='max-w-screen-2xl mx-auto'>
        <div className='flex items-center flex-wrap lg:flex-nowrap'>
          <div>
            <h2
              className={`text-2xl md:text-4xl font-medium text-center !font-[IBM-Ple-Sans] ${
                language === 'en' ? 'md:text-left' : 'md:text-right'
              }`}
            >
              {content.job_suggestions.title}
            </h2>
            <FeaturesTopLeft />
          </div>
          <div className='mt-8'>
            <img
              src={JobSuggestion}
              alt={content.job_suggestions.image_alt}
              className='w-full h-full md:min-h-[400px] md:min-w-[400px]'
            />
          </div>
        </div>
        <div className='flex items-center lg:flex-nowrap flex-wrap-reverse'>
          <div className='mb-10 md:mb-2'>
            <img
              src={CareerSuggestion}
              alt={content.analyze_career_path.image_alt}
              className='w-full h-full md:min-h-[400px] md:min-w-[400px]'
            />
          </div>
          <div className='my-8 md:my-20 ml-4'>
            <h2
              className={`text-2xl md:text-4xl  font-medium text-center !font-[IBM-Ple-Sans] ${
                language === 'en'
                  ? 'md:text-left lg:ml-20'
                  : 'md:text-right lg:mr-20'
              }`}
            >
              {content.analyze_career_path.title}
            </h2>
            <FeaturesBottomRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;
