import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Grid, Typography } from '@material-ui/core';

import { Auth } from '../Api';
import { AppContext } from '../Contexts';

const VerifyEmail = (props) => {
  const { showError } = useContext(AppContext);
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();

  useEffect(() => {
    Auth.validateEmail(query.get('token')).then(
      () => {
        showError('Email Verified');
        setTimeout(() => {
          props.history.push('/sign-in');
        }, 1500);
      },
      () => {
        showError('Already Verified or Invalid Request');
        setTimeout(() => {
          props.history.push('/');
        }, 2000);
      },
    );
  }, []);

  return (
    <Grid
      container
      justify='center'
      alignItems='center'
      style={{ height: '100vh' }}
    >
      <Grid item>
        <Typography variant='h3'>Please Wait...</Typography>
      </Grid>
    </Grid>
  );
};

export default VerifyEmail;
