/* eslint-disable max-lines */
import React, { useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { LiaUserFriendsSolid } from 'react-icons/lia';
import { MdLanguage, MdOutlineReviews } from 'react-icons/md';
import { PiBookOpenTextBold } from 'react-icons/pi';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { lowerFirst } from 'lodash';

import JobsLogo from 'src/Assets/images/v1/icons/jobsIconNavbar';
import Drawer from 'src/Components/Drawer';
import LanguageSwitch from 'src/Components/LanguageSwitch';
import { PAYMENT_STATUS } from 'src/Constants/Common';
import { AppContext, HomeContext } from 'src/Contexts';
import { FirebaseAnalyticsEvent } from 'src/Firebase/analytics';
import { walkthroughSteps } from 'src/Utils/utils';

import TourGuide from '../../../Assets/images/dashboard/tour-guide.svg';
import CLoseLogo from '../../../Assets/images/v1/icons/close-icon.svg';
import DashboardIcon from '../../../Assets/images/v1/icons/dashboardIcon';
import Hamburger from '../../../Assets/images/v1/icons/hamburger.svg';
import LogOutLogo from '../../../Assets/images/v1/icons/log-out-icon.svg';
import Logo from '../../../Assets/images/v1/icons/logo.svg';
import Avatar from '../../../Assets/images/v1/icons/male-avatar.svg';
import PaymentLogo from '../../../Assets/images/v1/icons/paymentIconNavbar';
import ResumeLogo from '../../../Assets/images/v1/icons/resumeIconNavbar';
import SurveyLogo from '../../../Assets/images/v1/icons/serveyIconNavbar';
// import SettingLogo from '../../../Assets/images/v1/icons/settingIconNavbar';
import UpskillLogo from '../../../Assets/images/v1/icons/upskillIconNavbar';
import { Auth as AuthConstants } from '../../../Constants';

const NavbarLinks = ({
  logo,
  link,
  name,
  color,
  children,
  id,
  titleClass,
  isRTL,
}) => {
  return (
    <Link
      to={link}
      onClick={() => {
        FirebaseAnalyticsEvent(lowerFirst(name));
      }}
    >
      <div
        className={`flex text-white hover:bg-LightBluePurple p-2 m-0 font-semibold my-2 rounded
        ${color ? 'cursor-pointer' : 'cursor-not-allowed text-black'} 
          `}
        id={id}
      >
        <div className={`${isRTL ? 'ml-3' : 'mr-3'}`}>{logo}</div>
        <div
          className={`${color ? 'text-white' : ' text-gray-500'} ${titleClass}`}
        >
          {name} {isRTL}
        </div>
        {children}
      </div>
    </Link>
  );
};
const NavbarItems = ({ HandleLogOut, context, isOpen, onClose }) => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';
  const isEmailVerified = context?.user?.email_verified;
  const homeContext = useContext(HomeContext);
  const { userPayment } = useContext(AppContext);
  const { total, setIsWalkthroughRunning, userProfile } = homeContext;
  const isEnterPriseUser = localStorage.getItem('isEnterPrise') ?? 'false';

  const [login, setLogin] = useState(localStorage.getItem('login'));
  const history = useHistory();

  const isEnable = isEmailVerified && !homeContext?.resumeLoader;
  const enableColor =
    isEmailVerified &&
    userPayment?.payment_status === 'paid' &&
    !homeContext?.resumeLoader;

  // const isWalkthroughSeen = localStorage.getItem('walkthroughSeen');
  const isPremium = userPayment?.payment_status === PAYMENT_STATUS.PAID;

  const startATour = () => {
    FirebaseAnalyticsEvent('take_tour');
    // close side bar in case of mobile screen
    if (isOpen === true) {
      onClose();
    }
    // eslint-disable-next-line no-undef
    walkthroughSteps(isPremium, setIsWalkthroughRunning, t, isRTL).drive();
    setIsWalkthroughRunning(true);
  };

  return (
    <div className='fixed h-full mx-8 flex flex-col justify-between overflow-scroll scrollbar-hide'>
      <div className={`mt-10 mx-0 ${isRTL ? 'ml-5' : 'mr-5'}`}>
        <div className={`${!isOpen ? 'flex flex-col items-center' : ''}`}>
          <img src={Logo} className='mx-auto' />
        </div>

        {userProfile?.is_enterprise_user && (
          <div className='flex items-center justify-center my-2 w-full'>
            <div className='text-left ml-3'>
              <div className='text-gray-300 text-[12px] font-light tracking-wide'>
                {t('navbar.corporate')}
              </div>
              <div className='mt-1 text-gray-300 text-[12px] tracking-wide font-light'>
                {userProfile.enterprise_user_info.organization_name}
              </div>
            </div>
            <div className='flex-grow h-px bg-white opacity-50 mx-1'></div>
          </div>
        )}
      </div>

      <div className='mt-5'>
        <NavbarLinks
          logo={<DashboardIcon isEnable={isEnable} />}
          link={`${isEnable ? '/home/' : '#'} `}
          name={t('navbar.dashboard')}
          color={isEnable}
          isRTL={isRTL}
        />
        {isEnterPriseUser === 'false' && (
          <NavbarLinks
            logo={<JobsLogo isEnable={enableColor} />}
            link={`${enableColor ? '/home/jobs' : '#'} `}
            name={t('navbar.jobs')}
            color={enableColor}
            id={isOpen ? 'job-section-mb' : 'job-section'}
            isRTL={isRTL}
          />
        )}
        <NavbarLinks
          logo={
            <PiBookOpenTextBold
              size={24}
              className={`${enableColor ? 'opacity-100' : 'opacity-50'} `}
            />
          }
          link={`${enableColor ? '/home/books' : '#'} `}
          name={t('navbar.books')}
          color={enableColor}
          id={isOpen ? 'book-section-mb' : 'book-section'}
          isRTL={isRTL}
        />
        <NavbarLinks
          logo={<UpskillLogo isEnable={enableColor} />}
          link={`${enableColor ? '/home/upskill' : '#'} `}
          name={t('navbar.upskill')}
          color={enableColor}
          id={isOpen ? 'upskill-section-mb' : 'upskill-section'}
          isRTL={isRTL}
        />
        <NavbarLinks
          logo={<SurveyLogo isEnable={enableColor} />}
          link={`${enableColor ? '/home/survey' : '#'} `}
          name={t('navbar.surveys')}
          color={enableColor}
          id={isOpen ? 'survey-section-mb' : 'survey-section'}
          isRTL={isRTL}
        />
        {isEnterPriseUser === 'false' && (
          <NavbarLinks
            logo={<PaymentLogo isEnable={isEnable} />}
            link={`${isEnable ? '/home/payment' : '#'} `}
            name={t('navbar.upgrade')}
            color={isEnable}
            id={isOpen ? 'upgrade-section-mb' : 'upgrade-section'}
            isRTL={isRTL}
          />
        )}
        <div>
          <NavbarLinks
            logo={<ResumeLogo isEnable={isEnable} />}
            link={`${isEnable ? '/home/profile' : '#'} `}
            name={t('navbar.profile')}
            color={isEnable}
            id={isOpen ? 'profile-section-mb' : 'profile-section'}
            titleClass='ml-1'
            isRTL={isRTL}
          >
            <div className='mt-2 bg-gray-100 w-20 rounded-lg h-3 ml-5'>
              <div
                className='h-full text-white text-center rounded-lg bg-orange-400'
                style={{
                  width: `${
                    // eslint-disable-next-line no-nested-ternary
                    context?.user?.percentage === ''
                      ? 0
                      : context?.user?.percentage >= 87
                      ? 100
                      : context?.user?.percentage
                  }%`,
                }}
              ></div>
            </div>
          </NavbarLinks>

          {/* <NavbarLinks
          logo={<SettingLogo isEnable={isEnable} />}
          link={`${isEnable ? '/home/profile' : '#'} `}
          name={'Account Settings'}
          color={isEnable}
        /> */}
        </div>
      </div>

      <div className='flex-1  flex flex-col justify-end mb-5 md:mb-2'>
        {/* <div className={`${!isOpen ? 'h-60' : 'h-20'}`}> */}
        <div>
          {login &&
          userPayment?.payment_status === PAYMENT_STATUS.NOT_PAID &&
          !isOpen &&
          isEmailVerified ? (
            <div
              className={`bg-LightBluePurple ${
                isRTL ? 'ml-5' : 'mr-5'
              }  rounded-lg text-white`}
            >
              <div className='py-4 px-4'>
                <div className='flex justify-between'>
                  <h3 className='my-2'>{t('navbar.freePlan')}</h3>
                  <img
                    onClick={() => {
                      if (isEnable) {
                        localStorage.removeItem('login');
                        setLogin(false);
                      }
                    }}
                    className={`${
                      isEnable ? 'cursor-pointer' : 'cursor-not-allowed'
                    }`}
                    src={CLoseLogo}
                  />
                </div>
                <p className='my-2 w-48'>{t('navbar.freePlanMessage')}</p>
                <hr className='my-3' />
                <div className='flex'>
                  <h3
                    onClick={() => {
                      if (isEnable) {
                        localStorage.removeItem('login');
                        setLogin(false);
                      }
                    }}
                    className={`${
                      isEnable ? 'cursor-pointer' : 'cursor-not-allowed'
                    }`}
                  >
                    {t('navbar.dismiss')}
                  </h3>
                  <h3 className='mx-3 '>
                    <Link
                      className={` ${
                        isEnable ? 'cursor-pointer' : 'cursor-not-allowed'
                      }`}
                      to={`${isEnable ? '/home/payment' : '#'}`}
                    >
                      {t('navbar.upgradePlan')}
                    </Link>
                  </h3>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className={'mx-2 flex items-center cursor-pointer my-2 lg:mb-0'}>
          <MdLanguage
            color='white'
            className={` ${isEnable ? 'opacity-100' : 'opacity-60'}`}
            size={20}
          />
          <LanguageSwitch
            onToggle={(lang) => console.log(`Language changed to: ${lang}`)}
          />
        </div>

        {Number(total) ? (
          <div
            className='mx-2 flex items-center cursor-pointer my-2 lg:mb-0'
            onClick={() => {
              startATour();
            }}
          >
            <img src={TourGuide} />
            {/* <MdTour
              color='white'
              className={` ${isEnable ? 'opacity-100' : 'opacity-60'}`}
              size={20}
            /> */}
            <p
              className={`text-white ${
                isRTL ? 'mr-2' : 'ml-2'
              } font-semibold text-sm`}
            >
              {t('navbar.takeTour')}
            </p>
          </div>
        ) : null}

        {isEmailVerified ? (
          <>
            <div
              className={'mx-2 flex items-center cursor-pointer my-2 lg:mb-0'}
            >
              <LiaUserFriendsSolid
                color='white'
                className={` ${isEnable ? 'opacity-100' : 'opacity-60'}`}
                size={20}
              />
              <p
                className={`text-white ${
                  isRTL ? 'mr-2' : 'ml-2'
                } text-sm font-semibold ${
                  isEnable ? 'opacity-100' : 'opacity-40'
                }`}
                onClick={() =>
                  isEnable ? history.push('/home/refer-a-friend') : '#'
                }
              >
                {t('navbar.referFriend')}
              </p>
            </div>
            <div
              className={'mx-2 flex items-center cursor-pointer my-2 lg:mb-0'}
            >
              <MdOutlineReviews
                color='white'
                className={` ${isEnable ? 'opacity-100' : 'opacity-60'}`}
                size={20}
              />
              <p
                className={`text-white ${
                  isRTL ? 'mr-2' : 'ml-2'
                } text-sm font-semibold ${
                  isEnable ? 'opacity-100' : 'opacity-40'
                }`}
                onClick={() => (isEnable ? history.push('/home/reviews') : '#')}
              >
                {t('navbar.submitReview')}
              </p>
            </div>
          </>
        ) : null}
        {!isOpen ? (
          <hr className={`my-5 ${isOpen ? '-ml-5 w-full' : 'w-64'}  `} />
        ) : null}
        <div className='flex lg:justify-between lg:w-72 mb-2 '>
          <div
            onClick={
              isEmailVerified ? () => history.push('/home/profile') : () => {}
            }
            className={`flex gap-0.5 ${
              isEmailVerified ? 'cursor-pointer' : 'cursor-not-allowed'
            }`}
          >
            <div
              className={`h-8 w-8 ${
                isRTL ? 'ml-2' : 'mr-2'
              } mt-1 bg-gray-300 rounded-full flex justify-center items-center`}
            >
              <img
                className='mx-2 h-8 w-8 rounded-full'
                src={context.user.image ? context.user.image : Avatar}
                alt='User Avatar'
              />
            </div>
            <h3 className='text-white text-xl mt-1 font-semibold'>
              {context?.user?.name}
            </h3>
          </div>

          <img
            className={`cursor-pointer mx-5 mt-2 ${isRTL ? 'rotate-180' : ''}`}
            src={LogOutLogo}
            onClick={HandleLogOut}
            alt='log-out'
          />
        </div>
      </div>
    </div>
  );
};

const Navbar = () => {
  const context = useContext(AppContext);
  const [, , removeCookie] = useCookies([AuthConstants.JWT_TOKEN]);
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);

  const HandleLogOut = () => {
    removeCookie(AuthConstants.JWT_TOKEN, {
      path: '/',
    });
    localStorage.removeItem('loginData');
    localStorage.removeItem('login');
    localStorage.removeItem('isBanner');
    localStorage.removeItem('showTicketModal');
    localStorage.removeItem('showInterestDialog');

    removeCookie(AuthConstants.PROFILE_STATUS, {
      path: '/',
    });
    history.push('/sign-in');
  };

  return (
    <div className={'bg-primaryBg pb-10 lg:pb-0 lg:w-80 lg:fixed h-full z-10'}>
      <div className='lg:block hidden'>
        <NavbarItems
          isOpen={isOpen}
          HandleLogOut={HandleLogOut}
          context={context}
        />
      </div>
      <div className='mt-10 mx-5 lg:hidden'>
        <img src={Logo} />
      </div>
      <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className='flex justify-between mx-5 mt-10'>
          <div className='mx-5'></div>
          <div>
            <button
              className=' text-white cursor-pointer px-4 py-1'
              onClick={() => setIsOpen(!isOpen)}
            >
              <img src={Hamburger} />
            </button>
          </div>
        </div>
        <NavbarItems
          isOpen={isOpen}
          HandleLogOut={HandleLogOut}
          context={context}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      </Drawer>
      <div className='lg:hidden flex justify-end -mt-8 ml-10'>
        <button
          className=' text-white rounded cursor-pointer px-4 py-1'
          onClick={() => setIsOpen(!isOpen)}
        >
          <img src={Hamburger} />
        </button>
      </div>
    </div>
  );
};
export default Navbar;
