import Api from './index';

const getConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const UserCourses = {
  get: async (token, data) => {
    const endpoint = '/skill/get-courses';
    return await Api.post(endpoint, { data }, getConfig(token));
  },
  save_course: async (token, data) => {
    const endpoint = '/skill/saved-courses';
    return await Api.post(endpoint, { data }, getConfig(token));
  },
  get_save_course: async (token, data) => {
    const endpoint = '/skill/get-saved-courses';
    return await Api.post(endpoint, { data }, getConfig(token));
  },
  get_search_courses: async (token, data) => {
    const endpoint = '/skill/get-search-courses';
    return await Api.post(endpoint, { data }, getConfig(token));
  },
};

export default UserCourses;
