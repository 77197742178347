import React from 'react';

export default function Drawer({ children, isOpen }) {
  return (
    <main
      className={` fixed overflow-hidden z-50 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out ${
        isOpen
          ? ' transition-opacity opacity-100 duration-500 translate-y-0  '
          : ' transition-all delay-500 opacity-0 translate-y-full  '
      }`}
    >
      <section
        className={` w-full absolute bg-primaryBg h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  ${
          isOpen ? ' translate-y-0 ' : ' translate-y-[-100%] '
        }`}
      >
        <article className='relative pb-10 flex flex-col space-y-6 overflow-y-scroll h-full'>
          {children}
        </article>
      </section>
    </main>
  );
}
