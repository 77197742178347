import * as React from 'react';
import PropTypes from 'prop-types';

import { Step, StepLabel, Stepper, Typography } from '@material-ui/core';

const CompletionStepper = (props) => {
  return (
    <Stepper
      style={{ backgroundColor: 'transparent' }}
      activeStep={props.active}
      orientation='horizontal'
    >
      {props.steps.map((step, index) => (
        <Step key={index}>
          <StepLabel>
            <Typography>{step}</Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

CompletionStepper.propTypes = {
  steps: PropTypes.array.isRequired,
  active: PropTypes.number,
};

CompletionStepper.defaultProps = {
  active: 0,
};

export default CompletionStepper;
