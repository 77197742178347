import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';

import DateFnsUtils from '@date-io/date-fns';
import { Box, FormGroup, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { Profession } from '../Api';
import { Auth as AuthConstants } from '../Constants';

import ProfessionDropdown from './OnBoarding/ProfessionDropdown';
import UploadProfile from './Profile/UploadProfile';
import CitySelect from './CitySelect';
import CountrySelect from './CountrySelect';
import DateSelector from './DateSelector';
import Label from './Label';
import ProfileCompletionForm from './ProfileCompletionForm';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: '25vw',
    minHeight: '100vh',
  },
  dateHint: {
    opacity: 0.2,
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    color: '#000000',
  },
  dragImageIcon: {
    borderRadius: '4px',
    border: 'dashed 1px #979797',
    paddingBottom: '15px',
    paddingTop: '15px',
    marginRight: '10px',
  },
  dragImageContainer: {},
  buttonsContainer: {
    marginTop: '20px',
  },
  formContainer: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '24px',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  },
  titleStyle: {
    margin: 'auto',
    marginTop: '10px',
    fontSize: '30px',
  },
  subTitleStyle: {
    margin: 'auto',
    marginTop: '10px',
    fontSize: '18px',
    fontWeight: 'lighter',
  },
  upload: {
    marginTop: '10px',
  },
}));

const BasicInfoForm = (props) => {
  const classes = useStyles();
  const [professions, setProfessions] = useState([]);

  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);

  const { profession, dob, country, city, image } = props.value;
  const [dobState, setDOB] = useState(dob);

  const [professionState, setProfession] = useState(profession);
  const [countryState, setCountry] = useState(country);
  const [cityState, setCity] = useState(city);
  const [imageState, setImage] = useState(image);

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${cookies[AuthConstants.JWT_TOKEN]}`,
    },
  };
  useEffect(() => {
    Profession.list(requestConfig).then((res) => {
      if (res?.data?.data) setProfessions(res.data.data);
    });
  }, []);

  useEffect(() => {
    setProfession(professionState);
  }, [professions]);

  const isDisabled = () => {
    return !(
      Object.keys(professionState).includes('id') &&
      Object.keys(countryState).includes('id') &&
      cityState &&
      Object.keys(cityState).includes('id')
    );
  };

  return (
    <Box className={classes.formContainer}>
      <ProfileCompletionForm
        disabled={isDisabled()}
        submitted={() => {
          props.submitted({
            profession: professionState,
            dob: dobState,
            country: countryState,
            city: cityState,
            image: imageState,
          });
        }}
        title='Basic Information'
        titleStyle={classes.titleStyle}
        subTitle='Let’s start by getting to know you better'
        subTitleStyle={classes.subTitleStyle}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FormGroup style={{ marginTop: '5%' }}>
            <UploadProfile
              onImageUploaded={(image) => setImage(image)}
              image={imageState}
              typography={
                <Grid item>
                  <Grid className={classes.upload} direction='column' container>
                    <Typography variant='subtitle1'>
                      Drag & Drop your picture here or
                      <br />{' '}
                      <Link onClick={() => {}} style={{ cursor: 'pointer' }}>
                        Browse
                      </Link>{' '}
                      your profile picture
                    </Typography>
                    {imageState ? (
                      <Link
                        style={{ color: 'red' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setImage(null);
                        }}
                      >
                        Delete
                      </Link>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              }
            />
            <br />
            <Label label='Profession' />
            <ProfessionDropdown
              onChange={(item) => setProfession(item === null ? {} : item)}
              value={professionState}
            />
            <br />
            <Label label='Date of Birth' />
            <DateSelector
              placeholder='Date of Birth'
              value={dobState}
              onChange={(value) => setDOB(value.toString())}
            />
            <br />
            <Label label='Country' />
            <CountrySelect
              onChange={(value) => {
                setCountry(value === null ? {} : value);
                setCity({});
              }}
              value={countryState}
            />
            <br />
            <Label label='City' />
            <CitySelect
              onChange={(value) => setCity(value)}
              countryId={countryState ? countryState.id : countryState}
              value={cityState}
            />
            <br />
          </FormGroup>
        </MuiPickersUtilsProvider>
      </ProfileCompletionForm>
    </Box>
  );
};

BasicInfoForm.propTypes = {
  value: PropTypes.object,
  submitted: PropTypes.func.isRequired,
  skipped: PropTypes.func,
};

export default BasicInfoForm;
