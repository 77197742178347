import Api from './index';

const getConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
    // crossDomain: true,
    // withCredentials: true,
  },
});

const Surveys = {
  list: async (token, { type = 'new' } = {}) => {
    const endpoint = `/user/survey?&type=${type}`;

    return await Api.get(endpoint, getConfig(token));
  },

  listSubmitted: async (token, { beg = 0, size = 10 } = {}) => {
    const endpoint = `/user/userSurvey?&page=${beg}&size=${size}&orderBy=desc`;

    return await Api.get(endpoint, getConfig(token));
  },

  deleteSurvey: async (id, token) => {
    const endpoint = `/user/userSurvey/${id}`;
    return await Api.delete(endpoint, getConfig(token));
  },

  createSurvey: async (token, surveyData) => {
    const endpoint = '/user/userSurvey/create';
    return await Api.post(endpoint, { surveyData }, getConfig(token));
  },

  updateStatus: async (id, token, surveyData) => {
    const endpoint = `/user/userSurvey/${id}`;
    return await Api.put(endpoint, { surveyData }, getConfig(token));
  },
};

export default Surveys;
