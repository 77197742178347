import React from 'react';

import FooterEnd from '../FooterEnd';
import FooterLeft from '../FooterLeft';
import FooterRight from '../FooterRight';
const Footer = () => {
  return (
    <footer className='bg-FooterBg dark:bg-gray-900'>
      <div className='mx-auto max-w-screen-2xl py-14 px-5 md:px-8 lg:px-16 '>
        <div className='flex flex-col md:flex-row md:justify-between'>
          <FooterLeft />
          <FooterRight />
        </div>
        <hr className='my-6 border-gray-200 sm:mx-auto opacity-20 dark:border-gray-700 lg:my-8' />
        <FooterEnd />
      </div>
    </footer>
  );
};

export default Footer;
