import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Threads from 'src/Api/Threads';
import { FirebaseAnalyticsEvent } from 'src/Firebase/analytics';
import { facebookEvent } from 'src/Utils/utils';

import { Auth } from '../../../Api';
import Avatar from '../../../Assets/images/v1/icons/sign-in-avatar.svg';
import { Auth as AuthConstants } from '../../../Constants';
import LoginSignupComponent from '../../LoginSignupComponents';

function SignUpNew() {
  const { t } = useTranslation(); // Use the translation hook
  const { state, search } = useLocation();
  const history = useHistory();
  const default_email = state?.email;
  const [error, setError] = useState({ show: false, msg: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [, setCookie] = useCookies([AuthConstants.JWT_TOKEN]);
  const [loader, setLoader] = useState(false);
  const [dob, setDob] = useState({ day: '', month: '', year: '' });

  const refrerCode = search
    ? parseInt(new URLSearchParams(search).get('referrerId'), 10)
    : '';

  const handleSubmit = async (name, email, password) => {
    setError({ show: false, msg: '' });
    setLoader(true);
    try {
      const res = await Auth.signUp(
        name,
        email,
        'male',
        password,
        `${dob.day} ${dob.month}  ${dob.year}`,
        refrerCode,
      );
      const threadData = await Threads.getThread(res?.data?.data?.token);

      localStorage.setItem(
        'loginData',
        JSON.stringify({
          user: {
            ...res.data.data,
            thread_id: threadData?.data?.data?.id ?? null,
            content: '',
          },
        }),
      );
      setCookie(AuthConstants.JWT_TOKEN, res.data.data.token, {
        path: '/',
      });
      setCookie(AuthConstants.PROFILE_STATUS, res.data.data.profile_status, {
        path: '/',
      });
      setLoader(false);
      FirebaseAnalyticsEvent('sign_up');
      facebookEvent('CompleteRegistration');

      history.push('/home');
    } catch (e) {
      setLoader(false);
      setError({ show: true, msg: e.message });
    }
  };

  const signUpFormik = useFormik({
    initialValues: {
      password: '',
      email: default_email ?? '',
      first_name: '',
      last_name: '',
      confirm_password: '',
      dob: '',
    },
    validationSchema: Yup.object({
      first_name: Yup.string()
        .min(2, t('signup.validation.first_name_min'))
        .max(60, t('signup.validation.first_name_max'))
        .required(t('signup.validation.first_name_required')),
      last_name: Yup.string()
        .min(2, t('signup.validation.last_name_min'))
        .max(60, t('signup.validation.last_name_max'))
        .required(t('signup.validation.last_name_required')),
      password: Yup.string()
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/,
          t('signup.validation.password_validation'),
        )
        .max(20, t('signup.validation.password_max_length'))
        .required(t('signup.validation.password_required')),
      confirm_password: Yup.string()
        .oneOf(
          [Yup.ref('password'), null],
          t('signup.validation.password_mismatch'),
        )
        .required(t('signup.validation.confirm_password_required')),
      email: Yup.string()
        .email(t('signup.validation.invalid_email'))
        .required(t('signup.validation.email_required')),
      dob: Yup.string().required(t('signup.validation.dob_required')),
    }),
    onSubmit: async (values) => {
      await handleSubmit(
        `${values.first_name} ${values.last_name}`,
        values.email,
        values.password,
      );
    },
  });

  useEffect(() => {
    if (dob.day && dob.month && dob.year) {
      signUpFormik.handleChange({ target: { name: 'dob', value: 'abc' } });
    } else {
      signUpFormik.handleChange({ target: { name: 'dob', value: '' } });
    }
  }, [dob]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div>
      <LoginSignupComponent
        MainHeading={t('signup.main_heading')}
        Paragraph={t('signup.paragraph')}
        Avatar={Avatar}
        Heading={t('signup.heading')}
        SubHeading={t('signup.sub_heading')}
        ButtonText={t('signup.button_text')}
        CreateOrAlready={t('signup.create_or_already')}
        EndText={t('signup.end_text')}
        formik={signUpFormik}
        error={error}
        showPassword={showPassword}
        showConfirmPassword={showConfirmPassword}
        togglePasswordVisibility={togglePasswordVisibility}
        toggleConfirmPasswordVisibility={toggleConfirmPasswordVisibility}
        loader={loader}
        setDob={setDob}
      />
    </div>
  );
}

export default SignUpNew;
