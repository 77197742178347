import Api from './index';

const Profession = {
  list: async (config, { query = '', beg = 0, size = 50 } = {}) => {
    const endpoint = `/profession?q=${query}&orderBy=asc&page=${beg}&size=${size}`;
    return await Api.get(endpoint, config);
  },
};

export default Profession;
