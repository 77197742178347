import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Chip as MuiChip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(() => ({
  chipStyle: {
    marginRight: '5px',
    marginBottom: '5px',
  },
  deleteIcon: {
    marginRight: (props) => (props.isRTL ? '0' : '5px'),
    marginLeft: (props) => (props.isRTL ? '5px' : '0'),
  },
}));

const Chip = (props) => {
  const { i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';
  const classes = useStyles({ isRTL });
  return (
    <MuiChip
      className={classes.chipStyle}
      label={props.label}
      onDelete={props.onDelete}
      variant='outlined'
      deleteIcon={<CancelIcon className={classes.deleteIcon} />}
    />
  );
};

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default Chip;
