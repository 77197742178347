import { formatDistanceToNow, isToday, parseISO } from 'date-fns';
import { driver } from 'driver.js';
import moment from 'moment';

import {
  Breakdown,
  Breakdown2,
  CareerSummary,
  CareerSummary2,
  Courses,
  Courses2,
  Jobs,
  SkillsFactors,
  SkillsFactors2,
  TopCountries,
} from 'src/Assets/images/dashboard/index';
import {
  ILO_CHART_WIDTH_MAPPER,
  PAYMENT_STATUS,
  PREMIUM_PLAN,
} from 'src/Constants/Common';
import { FirebaseAnalyticsEvent } from 'src/Firebase/analytics';

// Function to check if the screen is small
const isSmallScreen = () => window.innerWidth <= 768;

// Function to get the appropriate image based on screen size
const getBreakdownImage = () => (isSmallScreen() ? Breakdown2 : Breakdown);

// Function to update the image based on screen size
const updateBreakdownImage = () => {
  const imgElement = document.getElementById('appended-image');
  if (imgElement) {
    imgElement.src = getBreakdownImage();
  }
};

// Function to update the image based on screen size
const getCoursesImage = () => (isSmallScreen() ? Courses2 : Courses);

// Function to update the image based on screen size
const updateCoursesImage = () => {
  const imgElement = document.getElementById('appended-image6');
  if (imgElement) {
    imgElement.src = getCoursesImage();
  }
};

// Function to update the image based on screen size
const getCareerSummaryImage = () =>
  isSmallScreen() ? CareerSummary2 : CareerSummary;

// Function to update the image based on screen size
const updateCareerSummaryImage = () => {
  const imgElement = document.getElementById('appended-image1');
  if (imgElement) {
    imgElement.src = getCareerSummaryImage();
  }
};
// Function to update the image based on screen size
// const getCareerPathImage = () => (isSmallScreen() ? CareerPath2 : CareerPath);

// Function to update the image based on screen size
// const updateCareerPathImage = () => {
//   const imgElement = document.getElementById('appended-image2');
//   if (imgElement) {
//     imgElement.src = getCareerPathImage();
//   }
// };

const getSkillFactorsImage = () =>
  isSmallScreen() ? SkillsFactors2 : SkillsFactors;

const updateSkillsFactorImage = () => {
  const imgElement = document.getElementById('appended-image4');
  if (imgElement) {
    imgElement.src = getSkillFactorsImage();
  }
};
// Add event listener to update image on window resize
window.addEventListener('resize', updateSkillsFactorImage);
// Initial image update on page load
document.addEventListener('DOMContentLoaded', updateSkillsFactorImage);
// Add event listener to update image on window resize
window.addEventListener('resize', updateBreakdownImage);
// Initial image update on page load
document.addEventListener('DOMContentLoaded', updateBreakdownImage);

// Add event listener to update image on window resize
window.addEventListener('resize', updateCoursesImage);
// Initial image update on page load
document.addEventListener('DOMContentLoaded', updateCoursesImage);

// Add event listener to update image on window resize
window.addEventListener('resize', updateCareerSummaryImage);
// Initial image update on page load
document.addEventListener('DOMContentLoaded', updateCareerSummaryImage);
// Add event listener to update image on window resize
// window.addEventListener('resize', updateCareerPathImage);
// // Initial image update on page load
// document.addEventListener('DOMContentLoaded', updateCareerPathImage);

export const mapProjects = (data) => {
  return data?.map((item) => ({
    title: `${item.title}`,
    subtitle: `${item.company_name}`,
    date: `${item.start_year} - ${item.end_year}`,
    startYear: item.start_year,
    startUTC: item.start_year_utc,
    endYear: item.end_year,
    endUTC: item.end_year_utc,
    ...item,
    image: item.image_url,
    resume: item.cv,
  }));
};

export const mapEducation = (data) => {
  return data.map((item) => {
    const title = `${item.degree_name ?? ''}
    ${item.field_name ? `, ${item.field_name}` : ''}
    ${item.grade ? `, ${item.grade}` : ''}`;
    return {
      ...item,
      locationText: item.location,
      title: title,
      subtitle: `${item.university_name} ${
        item.university_name && item.location ? ',' : ''
      } ${item.location}`,
      startYear: item.start_year,
      startUTC: item.start_year_utc,
      endYear: item.end_year,
      endUTC: item.end_year_utc,
      degree: { id: item.degree_id, title: item.degree_name },
      fieldOfStudy: { id: item.study_field_id, title: item.field_name },
      institute: item.university_name,
    };
  });
};

export const mapExperience = (data) => {
  return data.map((item) => {
    const nonEmptySubtitleStrings = [item.company, item.location].filter(
      (str) => str !== null && str.trim() !== '',
    );

    return {
      title: item.designation,
      subtitle: nonEmptySubtitleStrings.join(', '),
      locationText: item.location,
      startYear: item.start_year,
      startUTC: item.start_year_utc,
      endYear: item.is_working ? null : item.end_year,
      endUTC: item.end_year_utc,
      ...item,
    };
  });
};

export const formatDate = (date) => {
  return moment(date * 1000).format('MMMM D, YYYY');
};

export const findPlanByPaymentId = (paymentId) => {
  return PREMIUM_PLAN.find((plan) => plan.priceId === paymentId);
};

export const filterJobsByQuery = (jobs, query) => {
  query = query?.toLowerCase();
  return jobs?.filter(
    (job) =>
      job?.jobTitle?.toLowerCase()?.includes(query) ||
      job?.companyName?.toLowerCase()?.includes(query) ||
      job?.location?.toLowerCase()?.includes(query),
  );
};
export const filterUpskillsByQuery = (upskills, query) => {
  query = query?.toLowerCase();
  return upskills?.filter((upskill) =>
    upskill?.title?.toLowerCase()?.includes(query),
  );
};

export const sortJobsByDate = (jobs) =>
  jobs?.sort((a, b) => (moment(a.jobPosting).isBefore(b.jobPosting) ? 1 : -1));

export const getEmptyCardText = (
  data,
  filteredData,
  searchQuery,
  favouriteMessage,
) => {
  if ((data && !filteredData.length && !searchQuery) || !data) {
    return favouriteMessage;
  }
  return 'No Result Found';
};

export const getILOScore = (ilo) => {
  if (!ilo) return 0;
  return ((10 - ilo) / 9) * 100;
};

export const getPercentage = (val, percentage) => Math.round(val * percentage);
export const capitalize = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};
export const truncateString = (inputString, prefixLength, suffixLength) => {
  if (inputString.length <= prefixLength + suffixLength) {
    return inputString;
  }

  const prefix = inputString.slice(0, prefixLength);
  const suffix = inputString.slice(-suffixLength);
  const ellipsis = '.....';

  return `${prefix}${ellipsis}${suffix}`;
};

export const formatJobDate = (time, t) => {
  const parsedDate = parseISO(time);
  const formattedDate = formatDistanceToNow(parsedDate);
  const isTodayDate = isToday(parsedDate);
  if (isTodayDate) {
    return t('jobs.postedToday');
  }
  return `${formattedDate} ${t('jobs.ago')}`;
};

export const getLocalUser = async () => {
  const userData = localStorage.getItem('loginData');
  return await JSON.parse(userData);
};

export const getIloChartStyle = (userIlo, currentIlo, isPremium) => {
  let backgroundColor = '#222B6D';
  let color = 'white';

  if (userIlo > currentIlo || !userIlo) {
    backgroundColor = '#eeeeee';
    color = isPremium ? 'black' : 'white';
  }
  let style = {
    color,
    fontSize: 7,
    marginTop: 2,
    backgroundColor,
    borderTopRightRadius: 50,
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    fontWeight: 'bold',
    width: ILO_CHART_WIDTH_MAPPER[currentIlo],
  };
  if (currentIlo === 1) {
    style = {
      ...style,
      height: 0,
      width: 16,
      borderLeft: '5px solid white',
      borderRight: '5px solid white',
      borderBottom: `10px solid ${backgroundColor}`,
    };
  }
  return style;
};

export const customSort = (dataArray, query) => {
  return dataArray?.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    const exactMatchA = nameA === query.toLowerCase();
    const exactMatchB = nameB === query.toLowerCase();

    if (exactMatchA && !exactMatchB) {
      return -1;
    } else if (!exactMatchA && exactMatchB) {
      return 1;
    } else if (exactMatchA && exactMatchB) {
      return 0;
    } else if (
      nameA.startsWith(query?.toLowerCase()) &&
      !nameB.startsWith(query?.toLowerCase())
    ) {
      return -1;
    } else if (
      !nameA.startsWith(query?.toLowerCase()) &&
      nameB.startsWith(query?.toLowerCase())
    ) {
      return 1;
    }
    return nameA.localeCompare(nameB);
  });
};

export const scrollSmoothTo = (elementId, page, history) => {
  if (page && history && location.pathname !== page) {
    history.push(page);
    setTimeout(() => {
      scrollSmoothTo(elementId);
    });
  } else {
    const element = document.getElementById(elementId);
    if (element) {
      element?.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }
};

export const createCaseInsensitiveRegex = (text) =>
  new RegExp(text?.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');

function appendImageToElement(elementId, imageId, imageUrl) {
  const ele = document.getElementById(elementId);

  if (ele && ele.innerHTML) {
    const img = document.createElement('img');
    img.src = imageUrl;
    img.style.position = 'absolute';
    img.style.width = '100%';
    img.style.height = '100%';
    img.style.top = '0';
    img.style.left = '0';
    img.id = imageId;
    ele.appendChild(img);
  }
}

const createStep = (
  element,
  title,
  description,
  isPremium,
  side,
  align,
  imageSrc,
  upgradeMessage,
) => {
  return {
    element,
    popover: {
      title,
      description: `${
        imageSrc
          ? `<img src=${imageSrc} style='height: 100%; width: 100%;' />`
          : ''
      }<span style='font-size: 15px; display: block; margin-top: 10px;'>${description} ${
        !isPremium ? upgradeMessage : ''
      }</span>`,
      side,
      align,
      showButtons: ['next', 'close', 'previous'],
    },
  };
};

export const facebookEvent = (eventName, params) => {
  if (process.env.REACT_APP_NODE_ENV === 'Production') {
    // eslint-disable-next-line no-undef
    fbq('track', eventName, params || '');
  }
};

export const walkthroughSteps = (
  isPremium,
  setIsWalkthroughRunning,
  t,
  isRTL,
) => {
  const steps = [
    {
      element: '#score-chart',
      popover: {
        title: 'Skilled Score',
        description: t('takeTour.description1'),
        side: 'top',
        align: 'start',
        showButtons: ['next', 'close'],
        onNextClick: () => {
          appendImageToElement(
            'breakdown-scores',
            'appended-image',
            getBreakdownImage(),
          );
          driverObj.moveNext();
        },
      },
    },
    {
      element: '#breakdown-scores',
      popover: {
        title: t('takeTour.title2'),
        description: `${t('takeTour.description2')}  ${
          !isPremium ? t('takeTour.upgradePlanScore') : t('common.dummyText')
        } `,
        side: 'top',
        align: 'start',
        showButtons: ['next', 'close', 'previous'],
        onNextClick: () => {
          appendImageToElement(
            'career-summary',
            'appended-image1',
            getCareerSummaryImage(),
          );
          driverObj.moveNext();
        },
      },
    },

    {
      element: '#career-summary',
      popover: {
        title: t('takeTour.title3'),
        description: `${t('takeTour.description3')} ${
          !isPremium ? t('takeTour.upgradePlanCareer') : t('common.dummyText')
        } `,
        side: 'top',
        align: 'start',
        showButtons: ['next', 'close', 'previous'],
        onNextClick: () => {
          appendImageToElement(
            'top-destination',
            'appended-image3',
            TopCountries,
          );
          driverObj.moveNext();
        },
      },
    },
    // {
    //   element: '#career-path',
    //   popover: {
    //     title: 'Career Path',
    //     description: `Uncover your career path and plan for the future. ${
    //       !isPremium
    //         ? 'Upgrade your plan to view career path.'
    //         : COMMON.DUMMY_TEXT
    //     } `,
    //     side: 'top',
    //     align: 'start',
    //     showButtons: ['next', 'close', 'previous'],
    //     onNextClick: () => {
    //       appendImageToElement(
    //         'top-destination',
    //         'appended-image3',
    //         TopCountries,
    //       );
    //       driverObj.moveNext();
    //       localStorage.setItem('walkthroughSeen', 'true');
    //     },
    //   },
    // },
    {
      element: '#top-destination',
      popover: {
        title: t('takeTour.title4'),
        description: `${t('takeTour.description4')} ${
          !isPremium
            ? t('takeTour.upgadePlanDestinations')
            : t('common.dummyText')
        } `,
        side: 'top',
        align: 'start',
        showButtons: ['next', 'close', 'previous'],
        onNextClick: () => {
          appendImageToElement(
            'skill-factors',
            'appended-image4',
            getSkillFactorsImage(),
          );
          driverObj.moveNext();
          localStorage.setItem('walkthroughSeen', 'true');
        },
      },
    },
    {
      element: '#skill-factors',
      popover: {
        title: t('takeTour.title5'),
        description: `${t('takeTour.description5')} ${
          !isPremium
            ? t('takeTour.upgradePlanSkillsFactors')
            : t('common.dummyText')
        } `,
        side: 'top',
        align: 'start',
        showButtons: ['next', 'close', 'previous'],
        onNextClick: () => {
          appendImageToElement('recent-jobs', 'appended-image5', Jobs);
          driverObj.moveNext();
          localStorage.setItem('walkthroughSeen', 'true');
        },
      },
    },

    {
      element: '#recent-jobs',
      popover: {
        title: t('takeTour.title6'),
        description: `${t('takeTour.description6')} ${
          !isPremium ? t('takeTour.upgredePlanJobs') : t('common.dummyText')
        } `,
        side: 'top',
        align: 'start',
        showButtons: ['next', 'close', 'previous'],
        onNextClick: () => {
          appendImageToElement(
            'recent-courses',
            'appended-image6',
            getCoursesImage(),
          );
          driverObj.moveNext();
          localStorage.setItem('walkthroughSeen', 'true');
        },
      },
    },

    createStep(
      '#recent-courses',
      t('takeTour.title7'),
      t('takeTour.description7'),
      isPremium,
      'top',
      'start',
      null,
      t('takeTour.upgradePlanCourses'),
    ),
    createStep(
      '#job-section',
      t('takeTour.title8'),
      t('takeTour.description8'),
      isPremium,
      'right',
      'start',
      null,
      t('takeTour.upgradeJobs'),
    ),
    createStep(
      '#book-section',
      t('takeTour.title9'),
      t('takeTour.description9'),
      isPremium,
      'right',
      'start',
      null,
      t('takeTour.upgradeBooks'),
    ),
    createStep(
      '#upskill-section',
      t('takeTour.title10'),
      t('takeTour.description10'),
      isPremium,
      'right',
      'start',
      null,
      t('takeTour.upgradeCourses'),
    ),
    createStep(
      '#survey-section',
      t('takeTour.title11'),
      t('takeTour.description11'),
      isPremium,
      'right',
      'start',
      null,
      t('takeTour.upgradeSurveys'),
    ),
    {
      element: '#upgrade-section',
      popover: {
        title: t('takeTour.title12'),
        description: `${
          isPremium ? t('takeTour.description12') : t('takeTour.upgradeProfile')
        }`,
        side: 'right',
        align: 'start',
        showButtons: ['next', 'close', 'previous'],
      },
    },

    {
      element: '#profile-section',
      popover: {
        title: t('takeTour.title13'),
        description: t('takeTour.description13'),
        side: 'right',
        align: 'start',
        showButtons: ['next', 'close', 'previous'],
        onNextClick: () => {
          localStorage.setItem('walkthroughSeen', 'true');
          FirebaseAnalyticsEvent('tour_completed');
          window.location.reload();
          setIsWalkthroughRunning(false);
          driverObj.moveNext();
        },
      },
    },
  ];

  // const driverObj = driver({
  //   showProgress: true,
  //   animate: true,
  //   popoverClass: 'popover',
  //   disableActiveInteraction: true,
  //   steps,
  //   allowClose: false,
  //   allowKeyboardControl: false,
  //   onCloseClick: () => {
  //     window.location.reload();
  //     localStorage.setItem('walkthroughSeen', 'true');
  //   },
  // });

  const driverObj = driver({
    showProgress: true,
    animate: true,
    popoverClass: 'popover',
    disableActiveInteraction: true,
    steps,
    allowClose: false,
    allowKeyboardControl: false,
    onCloseClick: () => {
      window.location.reload();
      localStorage.setItem('walkthroughSeen', 'true');
    },
    nextBtnText: t('takeTour.next'),
    prevBtnText: t('takeTour.previous'),
    onPopoverRender: (popover) => {
      const { footer } = popover;
      const { nextButton } = popover;
      const prevButton = popover.previousButton;
      const progressText = popover.progress;
      // Add spacing directly to the buttons
      if (isRTL) {
        nextButton.style.marginRight = '5px';
        prevButton.style.marginLeft = '5px';
      } else {
        prevButton.style.marginRight = '5px';
        nextButton.style.marginLeft = '5px';
      }

      // Ensure buttons exist and reorder for RTL
      if (footer && nextButton && prevButton) {
        footer.appendChild(prevButton); // Add 'Previous' button first (right side)
        footer.appendChild(nextButton); // Add 'Next' button after (left side)
      }

      // Update progress text to Arabic format
      const [current, total] = progressText.textContent.split('of');

      progressText.textContent = `${current} ${' '} ${t(
        'takeTour.of',
      )} ${' '} ${total}`;
    },
  });

  return driverObj;
};

export const authBearer = {
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
  },
};

export const getButtonText = (userPayment, t) => {
  if (userPayment?.payment_status === PAYMENT_STATUS.PAID)
    return t('payments.alreadyActive');
  return t('payments.upgrade');
};

export const determineUpgradeStatus = (userPayment, clickedPriceId) => {
  const currentIndex = PREMIUM_PLAN.findIndex(
    (p) => p.priceId === userPayment.payment_id,
  );
  const newIndex = PREMIUM_PLAN.findIndex((p) => p.priceId === clickedPriceId);
  return currentIndex > newIndex ? 'Downgrade' : 'Upgrade';
};
