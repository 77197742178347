import Api from './index';
import Response from './Response';

const { next } = Response;

const Feedback = {
  get: async (token, queryParams) => {
    const endpoint = '/feedback/get-feedback';

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const urlWithParams = `${endpoint}?${new URLSearchParams(queryParams)}`;

    try {
      const resp = await Api.get(urlWithParams, config);

      if (!resp?.data?.data) return resp;
      const response = resp.data.data;
      return response;
    } catch (e) {
      next(e.response);
    }
  },

  addFeedback: async (token, data) => {
    const endpoint = '/feedback/create';

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const resp = await Api.post(endpoint, data, config);
      if (!resp?.data?.data) return resp;
      const response = resp.data.data;
      return response;
    } catch (e) {
      next(e.response);
    }
  },
  becomePartner: async (data) => {
    const endpoint = '/feedback/become-partner';

    try {
      const resp = await Api.post(endpoint, data);
      if (!resp?.data?.data) return resp;
      const response = resp.data.data;
      return response;
    } catch (e) {
      next(e.response);
    }
  },
};

export default Feedback;
