import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Dialog from './Dialog';
import ExperienceForm from './ExperienceForm';

const ExperienceInputDialog = (props) => {
  const { t } = useTranslation();
  const { onClose, ...restProps } = props;

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      title={
        props.id === -1
          ? t('profile.profileExperience.addExperience')
          : t('profile.profileExperience.editExperience')
      }
    >
      <ExperienceForm
        {...restProps}
        onSubmitted={async (data) => await props.onSubmitted(data)}
      />
    </Dialog>
  );
};

ExperienceInputDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  id: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onSubmitted: PropTypes.any,
  designation: PropTypes.string,
  company: PropTypes.string,
  startYear: PropTypes.string,
  endYear: PropTypes.string,
  locationText: PropTypes.string,
};

ExperienceInputDialog.defaultProps = {
  id: -1,
};

export default ExperienceInputDialog;
