import Api from './index';

const getConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const GetPaymentHistory = {
  UserPayment: async (token) => {
    const endpoint = '/paymentHistory';
    return await Api.get(endpoint, getConfig(token));
  },
};

export default GetPaymentHistory;
