import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ListInputForm from '../../Components/ListInputForm';
import { ProfileCompletionContext } from '../../Contexts';

const useStyles = makeStyles(() => ({
  formContainer: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '24px',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  },
}));

const ProfileEducation = (props) => {
  const classes = useStyles();

  const context = useContext(ProfileCompletionContext);

  const [data, setData] = useState(context.profile.education);

  useEffect(() => {
    const newData = context.profile.education.map((item) => {
      return {
        ...item,
        title: `${item.degree.title}, ${item.fieldOfStudy.title}, ${item.grade}`,
        subtitle: `${item.institute}, ${item.location ?? ''}`,
        date: `${item.startYear} - ${!item.endYear ? 'current' : item.endYear}`,
        locationText: item.location,
      };
    });
    setData(newData);
  }, [context]);

  return (
    <Box className={classes.formContainer}>
      <Grid sm={12} container>
        <ListInputForm
          deleteItem={context.deleteEducation}
          submitted={props.submitted}
          skipped={props.skipped}
          data={data}
          backed={props.backed}
          title='Add Education'
          subTitle='Let us know the education you have mastery on!'
          variant='education'
          isChildren={data.length}
          blankPlaceholder='Please add your education from here'
        />
      </Grid>
    </Box>
  );
};

export default withRouter(ProfileEducation);
