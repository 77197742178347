import { useTranslation } from 'react-i18next';

import logo from '../../Assets/images/v1/icons/logo.svg';

const FooterLeft = () => {
  const { t } = useTranslation();

  return (
    <div className='mr-20 lg:mr-28 xl:mr-36'>
      <div className='mb-6 md:mb-0'>
        <a href='#' className='flex items-center pb-6'>
          <img src={logo} alt='Logo' className='w-full max-w-[250px]' />
        </a>
        <p className='text-white w-[20rem] text-base font-[Karla-Regular]'>
          {t('footer.address')}
        </p>
        <p className='text-white w-[20rem] text-lg font-[Karla-Regular]'>
          {t('footer.addressLine1')}
        </p>
        <p className='text-white w-[20rem] text-lg font-[Karla-Regular]'>
          {t('footer.addressLine2')}
        </p>
        <p className='text-white w-[20rem] text-base font-[Karla-Regular] mt-2'>
          {t('footer.contactUs')}
        </p>
        <p className='text-white w-[20rem] text-lg font-[Karla-Regular]'>
          {t('footer.email')}
        </p>
        {/* <p className='text-white mt-2'>{t('footer.phone')}</p> */}
      </div>
    </div>
  );
};

export default FooterLeft;
