import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { HomeContext } from 'src/Contexts';

import SearchDropdownNew from '../SearchDropDownNew';

const InterestsDropdown = (props) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);
  const { areaOfInterest } = useContext(HomeContext);

  return (
    <SearchDropdownNew
      value={props.value}
      onChange={(item) => {
        props.onChange(item);
      }}
      labelAttribute='name'
      matchAttribute='id'
      data={areaOfInterest}
      width={'auto'}
      loading={false}
      inputPlaceholder={t('profile.profileInterests.chooseInterest')}
      requestMoreData={() => {
        setPage(page + 1);
      }}
      onSearch={(input) => {
        if (input !== query) {
          setPage(0);
          setQuery(input);
        }
      }}
    />
  );
};

InterestsDropdown.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default InterestsDropdown;
