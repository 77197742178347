import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import { Auth, Chats } from '../../Api';
import backImage from '../../Assets/images/profile/background.svg';
import ProfileCompletionPane from '../../Components/ProfileCompletionPane';
import { Auth as AuthConstants } from '../../Constants';
import { ProfileCompletionContext } from '../../Contexts';
import { parseLocalStorage } from '../../Utils';

import Path from './Path';
import ProfileBasic from './ProfileBasic';
import ProfileEducation from './ProfileEducation';
import ProfileExperience from './ProfileExperience';
import ProfileSkills from './ProfileSkills';

const setBodyStyle = (document) => {
  document.body.style.backgroundColor = '#FFFFFF';
  document.body.style.backgroundImage = `url(${backImage})`;
  document.body.style.backgroundPosition = 'right 75vh';
  document.body.style.backgroundSize = 'auto';
  document.body.style.backgroundRepeat = 'no-repeat';
  document.body.style.backgroundAttachment = 'fixed';
};

const ProfileCompletion = (props) => {
  setBodyStyle(document);
  const context = useContext(ProfileCompletionContext);
  const [cookies, setCookie] = useCookies([AuthConstants.JWT_TOKEN]);

  const token = cookies[AuthConstants.JWT_TOKEN];

  const handleProfileUpdation = async () => {
    const payloadForThread = {
      education: [],
      experience: [],
      softSkills: [],
      hardSkills: [],
    };

    if (context.profile?.education) {
      payloadForThread.education = context.profile?.education.map((edu) => {
        return `I have done my ${edu.degree.title} with grade ${
          edu.grade
        } in the field of ${edu.fieldOfStudy.title} starting from ${
          edu.startYear
        } ${
          !edu.endYear
            ? 'and it is in progress'
            : ` and it completed in ${edu.endYear}`
        } from ${edu.institute} which is situated in ${edu.location}`;
      });
    }

    if (context.profile?.experience) {
      payloadForThread.experience = context.profile?.experience.map((exp) => {
        return `I have worked at ${exp.company} as a ${exp.designation} since ${
          exp.startYear
        } ${
          !exp.endYear
            ? 'and i am still working there'
            : `which ended in ${exp.endYear}`
        }`;
      });
    }

    if (context?.profile?.softSkills) {
      payloadForThread.softSkills = context?.profile?.softSkills?.map(
        (skill) => {
          return `i have ${skill.name} and i would rate that ${skill.rate} out of 5.`;
        },
      );
    }

    if (context?.profile?.hardSkills) {
      payloadForThread.hardSkills = context?.profile?.hardSkills?.map(
        (skill) => {
          return `i have ${skill.name} and i would rate that ${skill.rate} out of 5.`;
        },
      );
    }
    const data = localStorage.getItem('loginData');
    const parsedData = parseLocalStorage(data);

    const createChatByTextResponse = await Chats.createChatByText({
      ...payloadForThread,
      user_id: parsedData?.user?.id,
    });

    if (createChatByTextResponse?.data?.data?.id) {
      localStorage.setItem(
        'loginData',
        JSON.stringify({
          user: {
            ...parsedData.user,
            thread_id: createChatByTextResponse?.data?.data?.id,
          },
        }),
      );
    }
    const response = await Auth.completeProfile(context.profile, token);
    setCookie(AuthConstants.PROFILE_STATUS, response.data.data.profile_status, {
      path: '/',
    });
    props.history.push('/home/profile/resume');
  };

  const panes = {
    path: {
      component: (
        <Path
          submitted={() => {
            props.history.push('/sign-up/profile/basic');
          }}
        />
      ),
      index: 1,
    },
    basic: {
      component: (
        <ProfileBasic
          submitted={() => {
            props.history.push('/sign-up/profile/skills');
          }}
        />
      ),
      index: 2,
    },
    skills: {
      component: (
        <ProfileSkills
          submitted={() => {
            props.history.push('/sign-up/profile/experience');
          }}
          skipped={() => {
            props.history.push('/sign-up/profile/experience');
          }}
          backed={() => {
            props.history.push('/sign-up/profile/basic');
          }}
        />
      ),
      index: 3,
    },
    experience: {
      component: (
        <ProfileExperience
          submitted={() => {
            props.history.push('/sign-up/profile/education');
          }}
          skipped={() => {
            props.history.push('/sign-up/profile/education');
          }}
          backed={() => {
            props.history.push('/sign-up/profile/skills');
          }}
        />
      ),
      index: 4,
    },
    education: {
      component: (
        <ProfileEducation
          submitted={handleProfileUpdation}
          backed={() => {
            props.history.push('/sign-up/profile/experience');
          }}
        />
      ),
      index: 5,
    },
  };

  const [step, setStep] = useState(props.match.params.step);

  useEffect(() => {
    setStep(props.match.params.step);
  }, [props.match.params.step]);

  return (
    <ProfileCompletionPane step={panes[step].index} name={context.profile.name}>
      {panes[step].component}
    </ProfileCompletionPane>
  );
};

export default withRouter(ProfileCompletion);
