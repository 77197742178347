import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from 'src/Contexts';

import ELO from '../../../Assets/images/v1/Breakdown.webp';
import Arrow from '../../../Assets/images/v1/icons/ArrowUpRight.svg';
import Cap from '../../../Assets/images/v1/icons/cap.svg';
import Diamond from '../../../Assets/images/v1/icons/diamond.svg';
import { Text } from '../Text';

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className='mt-7'>
      <div className='flex justify-center md:justify-start'>
        <img src={icon} alt={`${title}-icon`} />
      </div>
      <div>
        <Text className='text-base py-2'>{title}</Text>
        <Text className='text-base w-60'>{description}</Text>
      </div>
    </div>
  );
};

const GrowthSection = () => {
  const { t } = useTranslation();
  const content = t('growth_section', { returnObjects: true });
  const { language } = useContext(AppContext);

  // Map icons to keys in the JSON
  const icons = { diamond: Diamond, cap: Cap };

  return (
    <div className='px-4 sm:px-10 md:px-20 md:py-20 py-10'>
      <div className='max-w-screen-2xl mx-auto flex flex-col md:flex-row'>
        {/* Left Image Section */}
        <div className='w-full md:w-1/2 flex align-middle justify-center'>
          <div className='md:px-0 md:w-96 w-full h-full md:min-h-[300px] md:min-w-[300px]'>
            <img src={ELO} className='max-w-full' alt='growth breakdown' />
          </div>
        </div>

        {/* Right Content Section */}
        <div
          className={`w-full md:w-1/2 flex px-4 lg:px-10 flex-col justify-center items-center ${
            language === 'en' ? '!text-left' : '!text-right'
          }`}
        >
          <Text
            className={`font-bold w-full mt-4 !text-2xl text-center md:text-left md:!text-4xl text-[44px] tracking-tight pb-10 !font-[IBM-Ple-Sans] leading-tight ${
              language === 'en' ? '!text-left' : '!text-right'
            }`}
          >
            {content.title}
          </Text>
          <Text className='text-base w-full'>{content.description}</Text>
          <div className='flex w-full mt-4 flex-wrap text-center justify-center md:justify-start md:text-left xl:gap-x-20 gap-y-5 gap-x-10'>
            {content.features.map((feature, index) => (
              <FeatureCard
                key={index}
                icon={icons[feature.icon]}
                title={feature.title}
                description={feature.description}
              />
            ))}
          </div>
          <div className='mx-auto md:ml-0 mt-10 cursor-pointer'>
            <button
              onClick={() => {
                window.location.href = content.cta.link;
              }}
              className='text-black border font-medium border-black flex justify-center items-center px-3 py-2 rounded-md'
            >
              {content.cta.label}{' '}
              <img className='ml-3' src={Arrow} alt='arrow' />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrowthSection;
