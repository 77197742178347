import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Header } from 'src/Components/LandingPage';
import Footer from 'src/Components/LandingPage/Footer';
import { AppContext } from 'src/Contexts';

const HowItWorks = () => {
  const { t } = useTranslation();
  const { language } = useContext(AppContext);

  const steps = t('how_it_works2.steps', { returnObjects: true });
  const videos = t('how_it_works2.videos', { returnObjects: true });

  return (
    <div>
      <Helmet>
        <title>{t('how_it_works2.meta.title')}</title>
        <meta
          name='description'
          content={t('how_it_works2.meta.description')}
        />
      </Helmet>
      <div className='bg-primaryBg px-2'>
        <div className='max-w-screen-2xl mx-auto'>
          <div className='mx-2 lg:px-8'>
            <Header />
          </div>
        </div>
      </div>
      <div className={'container w-full mx-auto my-16 '}>
        <div className='mx-auto mt-11 grid grid-cols-1 lg:grid-cols-12'>
          <div></div>
          <div className='px-5 col-span-10'>
            <h1 className='!text-2xl md:!text-3xl font-medium text-center text-DarkBluePurple !font-[IBM-Ple-Sans]'>
              {t('how_it_works2.heading')}
            </h1>
            <div className='mt-4'>
              <p className='text-center'>{t('how_it_works2.description')}</p>
            </div>
          </div>
          <div></div>
        </div>

        {Object.keys(steps).map((key) => (
          <div
            key={key}
            className={'grid grid-cols-1 lg:grid-cols-12 mt-12 sm:mt-7 px-5 '}
          >
            <div></div>
            <div className={'col-span-10 border rounded-xl p-5 '}>
              <h2
                className={`!text-xl md:!text-2xl !font-medium text-center text-black !font-[IBM-Ple-Sans] ${
                  language === 'en'
                    ? 'text-left md:text-left'
                    : '!text-right md:text-right'
                }`}
              >
                {steps[key].heading}
              </h2>
              <div className='mt-4'>
                <p
                  className={`text-center ${
                    language === 'en'
                      ? 'text-left md:text-left'
                      : '!text-right md:text-right'
                  }`}
                >
                  {steps[key].description}
                </p>
              </div>
            </div>
            <div></div>
          </div>
        ))}

        <div className='grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-10 mt-10 px-5 md:px-2'>
          {Object.keys(videos).map((key) => (
            <div key={key} className='bg-reg-100'>
              <iframe
                allowFullScreen
                title={`how-vid-${videos[key].title}`}
                src={`https://www.youtube.com/embed/${videos[key].url}`} // Replace with dynamic URLs if available
                className='mx-auto w-[100%] mt-5 md:w-full h-60 md:h-80 rounded-lg'
              ></iframe>
              <h3
                className={`font-[karla-regular] mt-3 text-center font-bold text-xl md:text-2xl text-DarkBluePurple ${
                  language === 'en' ? 'md:text-left' : 'md:text-right'
                }`}
              >
                {videos[key].title}
              </h3>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HowItWorks;
