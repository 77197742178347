import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ProfileCompletionContext } from '../Contexts';

import EducationInputDialog from './EducationInputDialog';
import ExperienceInputDialog from './ExperienceInputDialog';
import FormFooter from './FormFooter';
import FormHeader from './FormHeader';
import SkillsInputDialog from './SkillsInputDialog';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: '30vw',
  },
  buttonsContainer: {
    margin: '2vh 0',
  },
}));

const ProfileCompletionForm = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const context = useContext(ProfileCompletionContext);
  const { profile, setProfile } = context;
  const { softSkills, hardSkills, experience, education } = profile;

  const dialog = {
    skills: (
      <SkillsInputDialog
        softSkills={softSkills}
        hardSkills={hardSkills}
        onSubmitted={({ softSkills, hardSkills }) => {
          setProfile({
            ...profile,
            softSkills,
            hardSkills,
          });
          setOpen(false);
        }}
        profession_id={context.profile.general.profession.id}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    ),
    experience: (
      <ExperienceInputDialog
        locationText={experience.location}
        endYear={experience.endYear}
        id={experience.id}
        startYear={experience.startYear}
        company={experience.company}
        designation={experience.designation}
        onSubmitted={({
          id,
          designation,
          company,
          startYear,
          endYear,
          location,
        }) => {
          if (id === -1) {
            setProfile({
              ...profile,
              experience: [
                ...experience,
                {
                  designation,
                  company,
                  startYear,
                  endYear,
                  location,
                },
              ],
            });
          } else {
            const newData = [...experience];
            newData[id] = {
              designation,
              company,
              startYear,
              endYear,
              location,
            };
            setProfile({ ...profile, experience: newData });
          }
          setOpen(false);
        }}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    ),
    education: (
      <EducationInputDialog
        startYear={education.startYear}
        endYear={education.endYear}
        startUTC={education.start_year_utc}
        endUTC={education.end_year_utc}
        id={education.id}
        locationText={education.location}
        institute={education.institute}
        fieldOfStudy={education.fieldOfStudy}
        degree={education.degree}
        grade={education.grade}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onSubmitted={(data) => {
          if (data.id === -1) {
            setProfile({ ...profile, education: [...education, { ...data }] });
          } else {
            const newData = [...education];
            newData[education.id] = data;
            setProfile({ ...profile, education: newData });
          }
          setOpen(false);
        }}
      />
    ),
  };

  return (
    <Grid
      className={classes.container}
      justify='center'
      alignItems='center'
      container
    >
      <Grid style={{ flex: 1 }} xs={12} item>
        <Grid justify='center' alignItems='center' container>
          <Grid xs={12} item>
            <FormHeader
              className={props.titleStyle}
              title={props.title}
              onActionPerformed={props.variant ? () => setOpen(true) : null}
            />
            {props.subTitleStyle && (
              <FormHeader
                className={props.subTitleStyle}
                title={props.subTitle}
                onActionPerformed={props.variant ? () => setOpen(true) : null}
              />
            )}

            {dialog[props.variant]}
          </Grid>
          <Grid container>
            <Grid xs={12} item>
              {props.children}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} className={classes.buttonsContainer} item>
        <FormFooter
          disabled={props.disabled}
          onNext={props.submitted}
          onSkip={props.skipped}
          onBack={props.backed}
        />
      </Grid>
    </Grid>
  );
};

ProfileCompletionForm.propTypes = {
  submitted: PropTypes.func.isRequired,
  skipped: PropTypes.func,
  backed: PropTypes.func,
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['skills', 'experience', 'education']),
  center: PropTypes.bool,
  disabled: PropTypes.bool,
};

ProfileCompletionForm.defaultProps = {
  center: false,
};

export default ProfileCompletionForm;
