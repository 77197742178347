import axios from 'axios';

import Auth from './Auth';
import Chats from './Chat';
import Courses from './Courses';
import Degree from './Degree';
import Interests from './Interests';
import Jobs from './Jobs';
import Misc from './Misc';
import Notifications from './Notifications';
import Profession from './Profession';
import Refer from './Refer';
import Skills from './Skills';
import Surveys from './Surveys';
import User from './User';

const Api = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}/v1`,
});

export {
  Auth,
  Chats,
  Courses,
  Degree,
  Interests,
  Jobs,
  Misc,
  Notifications,
  Profession,
  Refer,
  Skills,
  Surveys,
  User,
};

export default Api;
