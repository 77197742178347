import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Misc from 'src/Api/Misc';
import Payment from 'src/Api/UserPayment';
import Footer from 'src/Components/LandingPage/Footer';
import { scrollSmoothTo } from 'src/Utils/utils';

import { Header, Text } from '../../Components/LandingPage/index';
import PaymentCard from '../../Components/PaymentCard';
import { PREMIUM_PLAN } from '../../Constants/Common';

const Pricing = () => {
  const { t } = useTranslation();

  const PLAN_INFO = t('payments.planInfo', {
    returnObjects: true,
  });
  const [dollarToPKR, setDollarToPKR] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [currencyLoading, setCurrencyLoading] = useState(false);

  const history = useHistory();

  const fetchCurrencyData = async () => {
    try {
      setCurrencyLoading(true);
      const getDollarRate = await Payment.getDollarRate();
      setDollarToPKR(getDollarRate.data.data);
      setTimeout(() => {
        setCurrencyLoading(false);
      }, 1000);
    } catch (error) {
      console.error(t('pricing.errors.currency_fetch_error', { error }));
    }
  };

  useEffect(() => {
    const fetchUserLocation = async () => {
      try {
        const response = await Misc.geoip();
        const data = response?.data?.data;
        setUserLocation(
          data.country === 'PK' ? 'PK' : t('pricing.default_location'),
        );
      } catch (error) {
        console.error(t('pricing.errors.location_fetch_error', { error }));
        setUserLocation(t('pricing.default_location'));
      }
    };
    fetchUserLocation();
  }, [t]);

  useEffect(() => {
    scrollSmoothTo('root');
    fetchCurrencyData();
  }, []);

  return (
    <div className='user_select'>
      <div className='bg-primaryBg px-2'>
        <div className='max-w-screen-2xl mx-auto'>
          <div className='mx-2 lg:px-8'>
            <Header />
          </div>
        </div>
      </div>

      <div className='max-w-screen-2xl mx-auto'>
        <div
          id='heading'
          className='flex flex-col justify-center items m-auto px-2 mt-16 mb-8'
        >
          <h1 className='block font-semibold text-DarkBluePurple text-4xl leading-tight md:text-5xl xl:text-6xl text-center font-[IBM-Ple-Sans]'>
            {t('pricing.choose_plan')}
          </h1>
        </div>
      </div>

      <div className='max-w-screen-xl mx-auto mb-36'>
        <div className='flex flex-wrap justify-center items-center'>
          <Text className='font-[Karla-Regular] m-0 p-0 text-DarkBluePurple lg:mx-0 px-2 lg:px-0 text-center mb-8 w-full mt-2 text-regular'>
            {t('pricing.plan_text')}
          </Text>
          {PREMIUM_PLAN.filter((plan) => plan.title === 'One Time Payment').map(
            (plan) => (
              <PaymentCard
                key={plan.title}
                planInfo={PLAN_INFO}
                title={plan.title}
                duration={plan.duration}
                amount={plan.amount}
                currencyLoading={currencyLoading}
                buttonText={t('pricing.get_started')}
                dollarToPKR={dollarToPKR}
                userLocation={userLocation}
                amountPKR={plan.amountPKR}
                onClick={() => history.push('/sign-in')}
                cardStyle='!border-DarkBluePurple border-2'
              />
            ),
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Pricing;
