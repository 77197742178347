import React, { useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Header } from 'src/Components/LandingPage';
import LoaderButton from 'src/Components/LoaderButton';
import { COMMON } from 'src/Constants';

import EmailIcon from '../../../Assets/images/v1/icons/email-icon.svg';
import Logo from '../../../Assets/images/v1/icons/logo.svg';
import Avatar from '../../../Assets/images/v1/icons/sign-in-avatar.svg';
import FooterHelpLink from '../FooterHelpLink';

const ResetPassword = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/,
          COMMON.PASSWORD_VALIDATION,
        )
        .max(20, COMMON.PASSWORD_MAX_LENGTH)
        .required('Password is required'),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), 'not equal'], 'Password must match.')
        .required('Confirmation password is required'),
    }),
    onSubmit: ({ newPassword }) => {
      props.onSubmit({ newPassword });
    },
  });

  return (
    <div className='flex flex-col md:flex-row bg-lightGrey h-screen md:h-full justify-between'>
      <div className='block md:hidden bg-primaryBg px-4'>
        <Header />
      </div>
      <div className='bg-primaryBg hidden md:block w-full md:w-2/5 px-4 md:px-14 h-screen min-h-[800px] text-white'>
        <Link to='/'>
          <img className='mt-10' src={Logo} alt='logo' />
        </Link>
        <div className='h-4/5 mt-10'>
          <img className='w-auto mt-10 mx-auto' src={Avatar} alt='avatar' />
          <h1 className=' mx-auto text-xl font-[Karla-Bold] my-5 uppercase'>
            Reset Password
          </h1>
          <p className=' mx-auto font-[Karla-regular] text-lightGrey'>
            Lost your way? No worries. Reset your password now and rediscover
            the path to your account
          </p>
        </div>
        <div className='flex md:-mt-7 -mt-10  justify-center flex-wrap md:justify-between text-SSGrey'>
          <div className='flex'>
            <img src={EmailIcon} alt='icon' />
            <a className='underline mx-2' href='mailto:help@skilledscore.com'>
              help@skilledscore.com
            </a>
          </div>
          <p>© {new Date().getFullYear()} SkilledScore </p>
        </div>
      </div>
      <div className='mx-auto flex flex-col justify-center md:mx-auto w-full md:w-96'>
        <form onSubmit={formik.handleSubmit}>
          <div className='w-full text-center p-4'>
            <h1 className='text-3xl my-5 font-bold text-gray-900'>
              Reset Password
            </h1>
            <div className='relative mt-2 '>
              <input
                autoComplete='new-password'
                type={showPassword ? 'text' : 'password'}
                className='border-2 w-full h-12 px-3 mb-5 rounded-lg'
                placeholder='New Password'
                value={formik.values.newPassword}
                name='newPassword'
                id='newPassword'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              <div className='absolute top-9 right-2 -translate-y-5 '>
                <button
                  type='button'
                  onClick={(e) => {
                    e.preventDefault();
                    togglePasswordVisibility();
                  }}
                  className='border-none bg-transparent cursor-pointer p-0'
                >
                  {showPassword ? (
                    <AiOutlineEye size={20} />
                  ) : (
                    <AiOutlineEyeInvisible size={20} />
                  )}
                </button>
              </div>
              {formik.touched.newPassword && formik.errors.newPassword && (
                <div className='text-red-500 text-start text-xs sm:text-base my-1 -mt-4  mb-3 sm:mb-0'>
                  {formik.errors.newPassword}
                </div>
              )}
            </div>
            <div className='relative mt-2'>
              <input
                autoComplete='new-password'
                type={showConfirmPassword ? 'text' : 'password'}
                className='border-2 w-full h-12 px-3 mb-5 rounded-lg'
                placeholder='Confirm Password'
                value={formik.values.confirmNewPassword}
                name='confirmNewPassword'
                id='confirmNewPassword'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              <div className='absolute top-9 right-2 -translate-y-5 '>
                <button
                  type='button'
                  onClick={(e) => {
                    e.preventDefault();
                    toggleConfirmPasswordVisibility();
                  }}
                  className='border-none bg-transparent cursor-pointer p-0'
                >
                  {showConfirmPassword ? (
                    <AiOutlineEye size={20} />
                  ) : (
                    <AiOutlineEyeInvisible size={20} />
                  )}
                </button>
              </div>
              <div className='text-start -mt-4 text-xs sm:text-base text-red-500'>
                {formik.errors.confirmNewPassword}
              </div>
            </div>
            {props.isDisabled ? (
              <LoaderButton />
            ) : (
              <button
                disabled={props.isDisabled}
                type='submit'
                className='flex justify-center  bg-BluePurple text-white my-1.5 font-medium w-full  rounded-lg text-sm py-3  '
              >
                {props.isDisabled ? 'Wait' : 'Reset'}
              </button>
            )}
          </div>
        </form>
      </div>

      <div className='block md:hidden pb-6 bg-lightGrey'>
        <FooterHelpLink linkStyle=' text-black' iconColor='#000' />
      </div>
    </div>
  );
};
export default ResetPassword;
