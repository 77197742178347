/* eslint-disable simple-import-sort/imports */
import { Header } from 'src/Components/LandingPage';
import Footer from 'src/Components/LandingPage/Footer';
import JobAccordion from './JobAccordion';
import { useTranslation } from 'react-i18next';

import { JOBS } from 'src/Constants';

const Careers = () => {
  const { t } = useTranslation();
  const jobs = JOBS;
  return (
    <div>
      <div className='bg-primaryBg px-2 '>
        <div className='max-w-screen-2xl mx-auto'>
          <div className='mx-2 lg:px-8'>
            <Header />
          </div>
        </div>
      </div>
      <div className='container w-full mx-auto my-16 px-6 md:px-12 lg:px-20 min-h-[60vh]'>
        <h1 className='!text-3xl md:!text-5xl font-medium text-center text-DarkBluePurple py-5 !font-[IBM-Ple-Sans]'>
          {t('common.weAreHiring')}
        </h1>

        {jobs.map((job, index) => (
          <div className='my-2' key={index}>
            <JobAccordion job={job} />
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
};

export default Careers;
