import Api from './index';

const getConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const Refer = {
  getReferDetails: async (token) => {
    const endpoint = '/refer/referral-details';
    return await Api.get(endpoint, getConfig(token));
  },
  couponStatus: async (token, promoCode) => {
    const endpoint = `/refer/coupon-status/${promoCode}`;
    return await Api.get(endpoint, getConfig(token));
  },
  updateDiscount: async (token, discount, id) => {
    const endpoint = '/refer/discount';
    return await Api.post(
      endpoint,
      { discount, promoId: id },
      getConfig(token),
    );
  },
  removeDiscount: async (token) => {
    const endpoint = '/refer/discount';
    return await Api.delete(endpoint, getConfig(token));
  },
  getDiscount: async (token) => {
    const endpoint = '/refer/discount';
    return await Api.get(endpoint, getConfig(token));
  },
};

export default Refer;
