import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import CreateTwoToneIcon from '@material-ui/icons/CreateTwoTone';
// import DeleteIcon from '@material-ui/icons/Delete';
const useStyles = makeStyles(() => ({
  infoContainer: {
    flex: 1,
  },
  dateStyle: {
    opacity: 0.5,
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    color: '#505050',
    marginTop: '4px',
  },
  titleStyle: {
    fontFamily: 'Avenir-Heavy',
    fontSize: '1em',
    fontWeight: '900',
  },
  subtitleStyle: {
    fontSize: '14px',
    fontFamily: 'Avenir-Roman',
    color: '#505050',
    marginTop: '4px',
  },
  buttonContainer: () => ({
    display: 'flex',
    padding: '0.3vw',
    marginLeft: '0.2vw',
    marginRight: '5px',
    borderRadius: '50%',
    border: '1px solid #D5D2D6',
    zIndex: 3,
    color: '#A9A9A9',
    justifyContent: 'center',
    alignItems: 'center',
  }),
}));

const ExperienceItem = (props) => {
  const classes = useStyles(props);

  return (
    <Grid direction='row' className={classes.container} container>
      <Grid className={classes.infoContainer} direction='column' item>
        <Grid xs={12} container>
          <Grid xs={12} item>
            <Typography className={classes.titleStyle} variant='h5'>
              {props.title}
            </Typography>
          </Grid>
          {props.subtitle && (
            <Grid xs={12} item>
              <Typography className={classes.subtitleStyle} variant='subtitle1'>
                {props.subtitle}
              </Typography>
            </Grid>
          )}
          {props?.startYear ? (
            <div className=' text-gray-500'>
              {props.startYear}- {!props.endYear ? 'On Going' : props.endYear}
            </div>
          ) : null}
        </Grid>
      </Grid>
      {/* <Grid item>
        <Grid direction='row' container>
          <ButtonBase
            className={classes.buttonContainer}
            onClick={() => props.onEdit(props.id)}
          >
            <CreateTwoToneIcon color='inherit' fontSize='small' />
          </ButtonBase>
          <ButtonBase
            className={classes.buttonContainer}
            onClick={() => props.onDelete(props.id)}
          >
            <DeleteIcon color='inherit' fontSize='small' />
          </ButtonBase>
        </Grid>
      </Grid> */}
    </Grid>
  );
};

ExperienceItem.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  isSticky: PropTypes.bool,
};

ExperienceItem.defaultProps = {
  onEdit: () => {},
  onDelete: () => {},
  isSticky: false,
};

export default ExperienceItem;
