import React from 'react';
import { Helmet } from 'react-helmet';

// import Flare from '@material-ui/icons/Flare';
// import StickyMessage from 'src/Components/StickyMessage';
import Benefits from '../../Components/LandingPage/Benefits';
import FeaturesSection from '../../Components/LandingPage/FeaturesSection';
import Footer from '../../Components/LandingPage/Footer';
import GrowthSection from '../../Components/LandingPage/GrowthSection';
import Hero from '../../Components/LandingPage/Hero';
import HowItWorks from '../../Components/LandingPage/HowItWorks';
import HowToUseSection from '../../Components/LandingPage/HowToUse';
import TrialSection from '../../Components/LandingPage/Trial';

const LandingPage = () => {
  return (
    <div className='user_select'>
      <Helmet>
        <title>Professional AI Career Counseling Online - SkilledScore</title>
        <meta
          name='description'
          content='Explore Your Future with AI-Based Career Counseling Services. Our Software Uses The Most Advanced AI Technology To Give You a Unique Career Skills Assessment.'
        />
      </Helmet>
      {/* <StickyMessage icon={<Flare />}>
        <p>
          <strong>Good News:</strong> Now you can join our{' '}
          <a
            target='_blank'
            rel='noreferrer'
            href='https://whatsapp.com/channel/0029VaGjdvuDzgT3fwTjAx1B'
          >
            WhatsApp channel
          </a>{' '}
          for frequent updates and releases.
        </p>
      </StickyMessage> */}
      <Hero />
      <HowItWorks />
      <Benefits />
      <HowToUseSection />
      <GrowthSection />
      <FeaturesSection />
      <TrialSection />
      <Footer />
    </div>
  );
};

export default LandingPage;
