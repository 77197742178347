import React from 'react';
import { useTranslation } from 'react-i18next';

import howToImage1 from '../../../Assets/images/v1/how-to-img1.png';
import howToImage2 from '../../../Assets/images/v1/how-to-img2.png';
import howToImage3 from '../../../Assets/images/v1/how-to-img3.png';
import bottomRight from '../../../Assets/images/v1/icons/down-right.png';
import topRight from '../../../Assets/images/v1/icons/top-right.png';
import { SectionTitle, Text } from '../../LandingPage';

const HowToUseComponent = ({ src, title, description, arrow, isDown }) => {
  return (
    <div className='w-11/12 sm:w-6/12 md:w-1/4 px-2 flex flex-col relative mt-5'>
      <div className='hidden md:block'>
        {arrow && (
          <div className={`absolute -right-24 ${isDown ? 'top-24' : 'top-10'}`}>
            <img src={arrow} className='w-32' alt='arrow' />
          </div>
        )}
      </div>
      <img src={src} className='w-40 h-40 mx-auto' alt='step' />
      <Text className='text-regular pb-4 text-center font-bold'>{title}</Text>
      <Text className='text-base text-center'>{description}</Text>
    </div>
  );
};

const HowToUseSection = () => {
  const { t } = useTranslation();
  const content = t('how_to_use', { returnObjects: true });

  // Map images and arrows to keys in the JSON
  const images = [howToImage1, howToImage2, howToImage3];
  const arrows = { topRight, bottomRight };

  return (
    <div className='pt-6 pb-16 bg-lightGrey px-4' id='how-to-use'>
      <div className='max-w-screen-2xl mx-auto'>
        <SectionTitle
          titleClass='!text-black'
          descriptionClass='!text-black'
          title={content.title}
          description={content.description}
        />
        <div className='flex flex-col justify-center items-center md:flex-row md:justify-around'>
          {content.steps.map((step, index) => (
            <HowToUseComponent
              key={index}
              src={images[index]}
              title={step.title}
              description={step.description}
              arrow={arrows[step.arrow]}
              isDown={step.isDown}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowToUseSection;
