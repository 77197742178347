import { useTranslation } from 'react-i18next';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

export const InputComponent = ({
  type,
  text,
  placeholder,
  formik,
  value,
  autoComplete,
  error,
  helperText = '',
}) => {
  return (
    <>
      <input
        type={type}
        name={text}
        className='border-2 w-full h-12 px-3 rounded-lg'
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={value}
        placeholder={placeholder}
        autoComplete={autoComplete}
      />
      {helperText ? (
        <div className='text-red-500 text-start text-xs sm:text-base my-1 h-5 mb-3 sm:mb-0'>
          {helperText}
        </div>
      ) : null}
      <div className='text-red-500 text-start text-xs sm:text-base my-1 h-5 mb-3 sm:mb-0'>
        {error}
      </div>
    </>
  );
};
export const ButtonComponent = ({ logo, label }) => {
  return (
    <button
      type='button'
      className='flex justify-center border-2 bg-white my-1.5 font-medium w-full rounded-lg text-sm px-3 py-2.5  '
    >
      <img className='-mb-5 mx-2 ' src={logo} />
      {label}
    </button>
  );
};
export const ToggleButtonComponent = ({
  showPassword,
  togglePasswordVisibility,
}) => {
  const { i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';
  return (
    <div
      className={`absolute top-9 ${
        isRTL ? 'left-2' : 'right-2'
      }  -translate-y-5`}
    >
      <div
        onClick={togglePasswordVisibility}
        className='border-none bg-transparent cursor-pointer p-0'
      >
        {showPassword ? (
          <AiOutlineEye size={20} />
        ) : (
          <AiOutlineEyeInvisible size={20} />
        )}
      </div>
    </div>
  );
};
