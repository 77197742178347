import Api from './index';

const getConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
    // crossDomain: true,
    // withCredentials: true,
  },
});

const Courses = {
  list: async (token, { beg = 0, size = 20, type = 'recommended' } = {}) => {
    const ENDPOINTS = {
      recommended: `/course/user?page=${beg}&size=${size}&orderBy=desc`,
      enrolled: '/course/enrolled',
      saved: '/course/saved',
      completed: '/course/completed',
    };
    return await Api.get(ENDPOINTS[type], getConfig(token));
  },

  deleteCourse: async (id, token) => {
    const endpoint = `/user/course/${id}`;
    return await Api.delete(endpoint, getConfig(token));
  },

  updateStatus: async (id, token, status, { value = true } = {}) => {
    const ENDPOINTS = {
      enrolled: `/course/${id}/enroll?enrolled=${value}`,
      saved: `/course/${id}/save`,
      completed: `/course/${id}/complete`,
      visited: `/course/${id}/visit`,
    };

    return await Api.put(ENDPOINTS[status], {}, getConfig(token));
  },
};

export default Courses;
