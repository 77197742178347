import {
  GufhtuguHorizontal,
  KangarooHorizontal,
  ZindabhagHorizontal,
} from 'src/Assets/images/banner';
import { SanaRasheed, SirZeeshan } from 'src/Assets/images/dashboard';
import Routes from 'src/Utils/Routes';

const PAYMENT_CARD_HEADINGS = [
  'Plan Type',
  'Payment',
  'Amount',
  'Discount',
  'Payment Date',
  'Action',
];

const COMMON = {
  REQUIRED_ERROR_MSG: 'Required fields cannot be empty',
  PASSWORD_VALIDATION:
    'Must contain 8 characters, at-least one uppercase, one lowercase and one number.',
  PASSWORD_MAX_LENGTH: 'Password must not exceed 20 characters.',
  PAYMENT_TEXT:
    'Elevate your SkilledScore experience with our enhanced plan for expanded opportunities.',
  PAYMENT_HISTORY: 'View your complete payment history here.',
  SKILL_SCORE_TEXT_1: `Skilled Score is a comprehensive metric that provides valuable
  insights into your career prospects. This score helps you to
  understand your strengths and weaknesses, providing you with the
  necessary information to make informed decisions regarding your career
  path, training, and skill development.`,
  SKILL_SCORE_TEXT_2: `The Skilled Score is a powerful tool that can assist you in planning
  your career and self-assessment. It empowers you to thrive in the
  current job market and prepare you for future opportunities.`,
  DUMMY_TEXT: 'This is an example that you are viewing',
  PREMIUM_TEXT: 'Please upgrade to premium to view your score.',
  NO_REFUND: 'Disclaimer: Non-Refundable Fee.',
  CHOOSE_PRICING_PLAN: 'Choose your pricing plan',
  OUR_TEAM: 'Our Team',
  BLOGS: 'Blogs',
  PRICING_PLAN_TEXT:
    'Explore our flexible payment plans to find the option that best fits your needs. Achieve your goals with ease as you choose a plan that aligns with your budget and timeline. Discover the possibilities and start your journey with SkilledScore today!',
  job_submitted_success: 'Job Application Submitted Successfully.',
  job_title_required: 'Job title is required.',
  phone_number_required: 'Phone is required.',
  email_required: 'Email is required. ',
  last_name_required: 'Last Name is required.',
  first_name_required: 'First Name is required.',
  resume_file_required: 'Resume is Required.',
};

const POLICIES = [
  { name: 'Privacy Policy', path: Routes.PrivacyPolicy },
  { name: 'Terms and Conditions', path: Routes.TermsAndCondition },
  { name: 'Cookie Policy', path: Routes.CookiesPolicy },
];

const HOME = [
  { name: 'Benefits', id: 'benefits', type: 'scroll' },
  { name: 'How to Use?', id: 'how-to-use', type: 'scroll' },
  { name: 'Pricing', id: 'pricing-plan', type: 'link' },
];

const PAYMENT_STATUS = {
  PAID: 'paid',
  NOT_PAID: 'not paid',
};
const ILO_CHART_WIDTH_MAPPER = {
  1: 10.5,
  2: 16.8,
  3: 24.15,
  4: 31.5,
  5: 40.95,
  6: 50.4,
  7: 57.75,
  8: 66.15,
  9: 73.5,
};
const ILO_GROUPS = [
  'Armed Forces Occupations',
  'Managers',
  'Professionals',
  'Technicians and Associate Professionals',
  'Clerical Support Workers',
  'Services And Sales Workers',
  'Skilled Agricultural, Forestry and Fishery Workers',
  'Craft and Related Trades Workers',
  'Plant and Machine Operators and Assemblers',
  'Elementary Occupations',
];

const PREMIUM_PLAN = [
  {
    title: 'Monthly Plan',
    amount: '20',
    priceId: process.env.REACT_APP_STRIPE_MONTHLY_PRICE_KEY,
    duration: '1 Month',
    validity: 30,
  },
  {
    title: 'Quarterly Plan',
    amount: '50',
    priceId: process.env.REACT_APP_STRIPE_QUARTERLY_PRICE_KEY,
    duration: '3 Months',
    validity: 90,
  },
  {
    title: 'One Time Payment',
    amount: '20',
    amountPKR: '3000',
    priceId: process.env.REACT_APP_STRIPE_ONE_TIME_PRICE_KEY,
    duration: 'Package for lifetime',
  },
  {
    title: 'Full Time Access Payment',
    amount: '20',
    amountPKR: '3000',
    priceId: process.env.REACT_APP_STRIPE_FULL_TIME_ACCESS_PRICE_KEY,
    duration: 'Package for lifetime',
  },
  {
    title: 'Yearly Plan',
    amount: '150',
    priceId: process.env.REACT_APP_STRIPE_YEARLY_PRICE_KEY,
    duration: '1 Year',
    validity: 365,
  },
];

const PLAN_INFO = [
  { description: 'View profile information.' },
  { description: 'Edit profile information.' },
  {
    description: 'Assessment score calculation.',
  },
  {
    description: 'Recommended jobs.',
  },
  {
    description: 'Recommended courses.',
  },
  {
    description: 'Recommended surveys.',
  },
  {
    description: 'Upload resume upto 5 MB.',
  },
];

const PAYMENT_METHODS = {
  STRIPE: 'stripe',
  EASY_PAISA: 'easyPaisa',
};
const BANNER_LINK = [
  'https://gufhtugu.com/',
  'https://zindabhag.com/',
  'https://www.kangaroo.ventures',
];

const USER_PROFILE = {
  NO_SUMMARY: 'No summary has been added yet',
  NO_SKILLS: 'No skills have been added yet',
  NO_EXPERIENCE: 'No experiences have been added yet',
  NO_EDUCATION: 'No educations have been added yet',
  NO_INTEREST: 'No interests have been added yet',
  NO_PROJECT: 'No projects have been added yet',
  NO_CERTIFICATE: 'No certificates have been added yet',
};
const EASY_PAISA_STATUS = {
  SUCCESS: 'SUCCESS',
  ACCOUNT_DOES_NOT_EXIST: 'ACCOUNT DOES NOT EXIST',
  SYSTEM_ERROR:
    'You either have Unregistered account or incorrect PIN or have not approved transaction from EasyPaisa',
};

const EASY_PAISA_APPROVAL_POINTS = [
  'Ensure that in-app notifications for the EasyPaisa app are enabled.',
  'Login to your EasyPaisa app. Tap on the top-left menu My Account.',
  'To approve the payment under My Approvals. Ensure your EasyPaisa account is Active.',
];

const MESSAGES = [
  'Processing Your Resume...',
  "We're Analyzing Your Skills...",
  'Data Extraction in Progress...',
  'Preparing Your Profile...',
];
const FILE_SIZES = {
  PREMIUM_USER: 5,
  FREE_USER: 2,
};

const BANNER_IMAGES = [
  GufhtuguHorizontal,
  ZindabhagHorizontal,
  KangarooHorizontal,
];

const SKILLED_SCORE_VIDEOS = [
  {
    url: '/1D5mSyL4RjE?si=R-yrB1lqE8COC7UU',
    title: 'SkilledScore Demo (English)',
  },
  {
    url: '/042g0Xxu038?si=myCX-LBwsFiYa7Eo',
    title: 'SkilledScore Demo (Urdu)',
  },
];

const SCORE_DETAILs = [
  [
    {
      heading: '',
      details: [
        '1. Enhance skills and qualifications.',
        '2. Prepare for future job markets.',
        '3. Improve career opportunities.',
      ],
    },
  ],

  [
    {
      heading: 'Course Selection for Adaptive Skill Development',
      details: [
        'a. Lifelong Learning: Embrace continuous learning by staying updated with industry trends and emerging technologies through courses, workshops, and online resources.',
        'b. Knowledge Expansion: Invest time reading books, articles, and research to broaden your knowledge and keep your skills relevant.',
      ],
    },
    {
      heading: 'Adaptability and Resilience',
      details: [
        'a. Embracing Change: Develop a mindset that welcomes change, new ideas, and technologies, allowing you to adapt swiftly to evolving situations.',
        'b. Resilience Building: View setbacks as opportunities for growth and learning, cultivating resilience in facing challenges.',
      ],
    },
    {
      heading: 'Professional Growth and Networking',
      details: [
        'a. Mentorship and Feedback: Seek guidance and feedback from experienced professionals and mentors in your field to refine your skills and knowledge.',
        'b. Networking and Collaboration: Build a solid professional network by attending industry events and collaborating with colleagues from diverse backgrounds to enhance your teamwork skills.',
      ],
    },
  ],
  [
    {
      heading: 'Research and Targeting',
      details: [
        'a. Identify regions or cities aligned with your career goals and industry preferences.',
        'b. Narrow down your job search to specific geographical regions for focused efforts.',
      ],
    },
    {
      heading: 'Network and Connect',
      details: [
        'a. Build a solid professional network in your chosen geographic area.',
        'b. Connect with local professionals on LinkedIn for job insights and to expand your network.',
        'c. Leverage your network for insights on job openings, company cultures, and the local job market.',
      ],
    },
    {
      heading: 'Skill Enhancement',
      details: [
        'a. Tailor your skill set to meet local job market demands.',
        'b. Identify skills or certifications in high demand in your target area.',
        'c. Invest in professional development and online courses to enhance qualifications.',
      ],
    },
  ],
  [
    {
      heading: 'Continuous Learning and Growth Commitment',
      details: [
        'a. Online Certifications: Pursue online certifications and micro-credentials to gain specialized knowledge and skills.',
        'b. Mentoring and Coaching: Seek mentorship or coaching to accelerate personal and professional growth.',
        'c. Learning Communities: Join online or local learning communities and exchange peer-to-peer knowledge.',
      ],
    },
    {
      heading: 'Adaptability and Change Management',
      details: [
        'a. Embrace Change Diversity: Develop an understanding and appreciation for diverse perspectives and cultures, preparing you to adapt to different environments effectively.',
        'b. Digital Literacy: Enhance your digital literacy by familiarizing yourself with various digital tools and platforms, enabling seamless adaptation to technological shifts.',
      ],
    },
    {
      heading: 'Innovation and Creative Thinking',
      details: [
        'a. Brainstorming Techniques: Learn and apply various brainstorming techniques to generate innovative ideas.',
        'b. Intrapreneurship: Explore opportunities for intrapreneurship within your organization, fostering innovation from within.',
        'c. Creative Hobbies: Pursue creative hobbies outside work to keep your creative juices flowing and maintain a fresh perspective.',
      ],
    },
  ],
  [
    {
      heading: 'Economic Wealth and Social Support',
      details: [
        'a. Higher GDP per capita provides access to better living standards, healthcare, and education.',
        'b. A strong social safety net ensures support from family and others in times of need.',
      ],
    },
    {
      heading: 'Health and Freedom of Choice',
      details: [
        'a. Life expectancy reflects population health and access to quality healthcare.',
        'b. Freedom to make key life decisions, like career and family planning, influences well-being.',
      ],
    },
    {
      heading: 'Generosity and Trust',
      details: [
        'a. Willingness to help others, through charitable acts, contributes to social cohesion.',
        'b. Low levels of corruption and trust in government build a sense of security and stability.',
      ],
    },
    {
      heading: 'Mental and Physical Well-being',
      details: [
        'a. Emotional balance, mental health, and overall life satisfaction are key indicators of happiness.',
        'b. Physical health and fitness are also crucial factors in quality of life.',
      ],
    },
  ],
];
const HOW_IT_WORK = [
  {
    HEADING: 'How Does It Work?',
    PARA: "SkilledScore is more than just a piece of software; it's your road map to realizing your greatest potential in the workplace. By utilizing the International Labour Organization's (ILO) extensive information base and data analytics, SkilledScore enables people to make well-informed career selections based on their specific skill sets, objectives, and geographical location.",
  },
  {
    HEADING: 'Precision Scoring From 0 to 100',
    PARA: "With a score between 0 and 100, SkilledScore's scoring system gives users a brief and straightforward rating for their abilities. Using this score as a reference, people may accurately assess their areas of strength and growth. SkilledScore provides insightful information to help you navigate your career path, regardless of your background—you might be a recent graduate looking for entry-level positions or an experienced professional trying to switch to a different industry.",
  },
  {
    HEADING: 'Job Recommendation with SkilledScore',
    PARA: "However, SkilledScore is more than just a skills assessment test; it's also a certified career counselor for advancing your education and navigating the job market. SkilledScore uses a large database and strong algorithms to provide recommendations that are specific to your skill level. SkilledScore gives you everything that you need to succeed in today's competitive environment, whether it's by proposing relevant job vacancies in your area or courses and certifications to expand your skill set.",
  },
];

const TEAM_MEMBERS = [
  {
    name: 'Zeeshan Usmani',
    role: 'CTO/Co-founder',
    description:
      "With 10+ years in data science and marketing analytics, I lead Walee's team in advanced analytics, strategic partnerships, media buying, consumer profiling, and influencer management, driving innovative solutions in social and headless commerce.",
    image: SirZeeshan,
    linkedin: 'https://www.linkedin.com/in/zusmani/',
  },
  {
    name: 'Sana Rasheed',
    role: 'CTO/Co-founder',
    description:
      "I provide value through data science (R, Python), predictive analytics (ML, NLTK, SciKit), visualization (Pentaho, Tableau, QlikView, D3), and deep learning. I'm also versed in blockchain and NFTs (Ethereum, Smart Contracts, Bitcoin) for finance, travel, and supply chain.",
    image: SanaRasheed,
    linkedin: 'https://www.linkedin.com/in/sanarasheed/',
  },
];

const JOBS = [
  {
    title: 'Chief Technology Officer (CTO)',
    description:
      'We are seeking an experienced and visionary Chief Technology Officer (CTO) to oversee all technical aspects of Skilledscore. The ideal candidate will have 15+ years of experience in software development, cloud technologies, and leadership roles. The CTO will drive the company’s technological strategy, ensure the development of scalable systems, and manage the technology team to deliver cutting-edge solutions aligned with business objectives.',
    responsibilities: [
      'Strategic Leadership: Define and implement the technology vision, strategy, and roadmap to support Skilledscore’s goals.',
      'Team Management: Build, lead, and mentor a high-performing technology team.',
      'Architecture Oversight: Design and maintain scalable, secure, and efficient systems and architecture.',
      'Innovation: Stay ahead of technology trends and integrate innovative solutions to enhance the platform.',
      'Collaboration: Partner with other departments (Product, Marketing, Sales) to align technology with business priorities.',
      'Budget Management: Manage the technology budget, ensuring efficient resource allocation and cost optimization.',
      'Compliance & Security: Ensure adherence to security best practices, data protection regulations, and compliance standards.',
      'Problem Solving: Address technical challenges and drive solutions to ensure project success and operational efficiency.',
    ],
    requirements: [
      '15+ years of experience in software development, architecture, and technology leadership.',
      'Proven experience in scaling SaaS platforms and implementing enterprise-level solutions.',
      'Expertise in cloud platforms (AWS, Azure, or Google Cloud) and modern development frameworks.',
      'Strong knowledge of DevOps, CI/CD pipelines, and microservices architecture.',
      'Experience with AI/ML technologies, data pipelines, and analytics tools is a significant advantage.',
      'Exceptional problem-solving, strategic thinking, and leadership skills.',
      'Excellent communication and stakeholder management abilities.',
    ],
    contact: 'hr@skilledscore.com',
  },
  {
    title: 'Backend Developer (Node.Js)',
    description:
      'We are looking for a skilled Backend Developer to join our dynamic team. As a Backend Developer, you will be responsible for designing and implementing robust and scalable server-side applications...',
    responsibilities: [
      'Develop and maintain server-side logic, ensuring high performance and responsiveness.',
      'Design and implement RESTful APIs to support various front-end functionalities.',
      'Integrate and manage AWS S3 for file storage and retrieval.',
      'Implement and manage database solutions using MySQL and RDS.',
      'Use Sequelize ORM to interact with the database in a secure and efficient manner.',
      'Integrate payment processing using Stripe.',
      'Write clean, maintainable, and efficient code in JavaScript.',
      'Optimize applications for maximum speed and scalability.',
      'Troubleshoot and debug issues to improve performance and usability.',
      'Participate in code reviews and contribute to a high standard of code quality.',
      'Stay up-to-date with the latest industry trends and technologies in backend development.',
    ],
    requirements: [
      'Proven experience as a Backend Developer with a strong portfolio of previous projects.',
      'Proficiency in JavaScript, including ES6+ features.',
      'Solid understanding of AWS S3 for storage solutions.',
      'Experience with Sequelize ORM for database management.',
      'Familiarity with MySQL and RDS for database deployment and management.',
      'Knowledge of integrating payment gateways, particularly Stripe.',
      'Strong problem-solving skills and attention to detail.',
      'Excellent communication and teamwork skills.',
      'Understanding of RESTful API design principles.',
      'Experience with version control systems (e.g., Git).',
      'Ability to write clean, readable, and maintainable code.',
    ],
    contact: 'hr@skilledscore.com',
  },
  {
    title: 'React.js Developer',
    description:
      'We are looking for a skilled React.js Developer to join our dynamic team. As a React.js Developer, you will be responsible for building advanced user interfaces for our web applications...',
    responsibilities: [
      'Develop advanced user interfaces using React.js and related technologies.',
      'Implement responsive design using Tailwind CSS and Material-UI (MUI).',
      'Manage state and side-effects using modern state management libraries and patterns.',
      'Integrate and consume RESTful APIs using Fetch API and Axios.',
      'Write clean, maintainable, and efficient code in TypeScript.',
      'Collaborate with designers to translate UI/UX designs into high-quality code.',
      'Optimize applications for maximum speed and scalability.',
      'Troubleshoot and debug issues to improve performance and usability.',
      'Participate in code reviews and contribute to a high standard of code quality.',
      'Stay up-to-date with the latest industry trends and technologies in front-end development.',
    ],
    requirements: [
      'Proven experience as a React.js Developer with a strong portfolio of previous projects.',
      'Proficiency in React.js, including hooks, context API, and functional components.',
      'Solid understanding of TypeScript and its use in React applications.',
      'Experience with Fetch API and Axios for data fetching.',
      'Familiarity with Material-UI (MUI) and Tailwind CSS for UI development.',
      'Strong knowledge of HTML, CSS, and JavaScript (ES6+).',
      'Understanding of responsive design principles and mobile-first development.',
      'Experience with version control systems (e.g., Git).',
      'Ability to write clean, readable, and maintainable code.',
      'Strong problem-solving skills and attention to detail.',
      'Excellent communication and teamwork skills.',
    ],
  },
  {
    title: 'Front-End Developer',
    description:
      'We are looking for a talented and passionate Front-End Developer with 2+ years of experience to join our team. The ideal candidate will work on creating exceptional user experiences through visually appealing and highly functional web applications. You will collaborate closely with designers, back-end developers, and product managers to bring ideas to life.',
    responsibilities: [
      'Front-End Development: Build, test, and maintain responsive web interfaces using modern frameworks and technologies.',
      'Collaboration: Work with UI/UX designers and back-end developers to ensure seamless integration of user-facing elements with server-side logic.',
      'Optimization: Optimize applications for maximum speed, scalability, and usability.',
      'Code Quality: Write clean, efficient, and maintainable code while adhering to industry best practices.',
      'Debugging: Identify and fix performance bottlenecks and bugs to ensure high-quality deliverables.',
      'Innovation: Stay updated with the latest front-end technologies and contribute to the team’s continuous improvement.',
    ],
    requirements: [
      '2+ years of professional experience in front-end development.',
      'Proficiency in HTML, CSS, and JavaScript.',
      'Experience with front-end frameworks/libraries such as React, Angular, or Vue.js.',
      'Solid understanding of responsive design and cross-browser compatibility.',
      'Familiarity with version control systems like Git.',
      'Knowledge of RESTful APIs and integrating front-end with back-end services.',
      'Strong debugging and problem-solving skills.',
    ],
  },
  {
    title: 'IT Intern',
    description:
      'We are seeking a motivated IT Intern to assist in maintaining and enhancing our IT infrastructure. The ideal candidate will have a passion for technology and a willingness to learn in a dynamic environment.',
    responsibilities: [
      'Assist in troubleshooting and resolving IT-related issues for internal systems.',
      'Support hardware and software setup, configuration, and updates.',
      'Monitor system performance and provide recommendations for improvement.',
      'Collaborate with the IT team to document processes and create knowledge bases.',
      'Ensure data security and system compliance with company policies.',
      'Participate in IT projects, including network optimization and software testing.',
    ],
    requirements: [
      'Currently pursuing or recently completed a degree in IT, Computer Science, or a related field.',
      'Basic knowledge of operating systems (Windows, macOS, Linux) and networking concepts.',
      'Familiarity with cloud services like AWS or Azure is a plus.',
      'Strong problem-solving skills and attention to detail.',
      'Excellent communication and teamwork abilities.',
    ],
  },
  {
    title: 'Data Analysis Intern',
    description:
      'We are looking for an analytical and detail-oriented Data Analysis Intern to help us extract insights from data and support data-driven decision-making.',
    responsibilities: [
      'Collect, clean, and preprocess data from various sources.',
      'Analyze datasets to identify trends, patterns, and insights.',
      'Create visualizations and dashboards using tools like Tableau, Power BI, or similar platforms.',
      'Assist in generating reports to support business operations and strategy.',
      'Collaborate with teams to understand their data needs and deliver actionable insights.',
    ],
    requirements: [
      'Currently pursuing or recently completed a degree in Data Science, Statistics, Computer Science, or a related field.',
      'Proficiency in Excel and at least one programming language (Python, R, or SQL).',
      'Experience with data visualization tools like Tableau, Power BI, or Matplotlib.',
      'Strong analytical and problem-solving skills.',
      'Attention to detail and ability to work independently.',
      'Knowledge of machine learning or statistical methods is a plus.',
    ],
  },
  {
    title: 'HR Manager',
    title_translated: 'HR Manager',
    description:
      'We are seeking an experienced and proactive HR Manager with 2+ years of experience to lead and oversee our human resources functions. The ideal candidate will drive HR initiatives, foster a positive workplace culture, and align HR strategies with business goals.',
    responsibilities: [
      'Talent Acquisition: Manage end-to-end recruitment processes, including job postings, interviews, and onboarding,',
      'Employee Relations: Foster a positive and inclusive workplace culture by addressing employee concerns and implementing engagement strategies.',
      'Performance Management: Develop and oversee performance appraisal systems and employee development programs.',
      'Policy Development: Create, update, and implement HR policies and procedures in compliance with labor laws.',
      'Compensation & Benefits: Administer payroll, benefits, and reward systems to attract and retain top talent.',
      'Compliance: Ensure compliance with local labor laws and regulations,',
      'HR Analytics: Utilize data-driven insights to enhance HR processes and decision-making,',
      'Training & Development: Identify training needs and organize programs to enhance employee skills and productivity.',
      'HR Strategy: Align HR strategies with organizational goals and actively contribute to long-term planning.',
    ],
    requirements: [
      '2+ years of experience in HR management or a similar role.',
      'Strong knowledge of labor laws and HR best practices.',
      'Proficiency in HR software and tools (e.g., HRIS, ATS).',
      'Excellent interpersonal, communication, and leadership skills.',
      'Ability to handle sensitive and confidential information with discretion.',
      'Strong problem-solving and decision-making abilities.',
    ],
  },
  {
    title: 'Chief Financial Officer (CFO)',
    title_translated: 'Chief Financial Officer (CFO)',
    description:
      'We are seeking a highly motivated and results-driven CFO with 2+ years of experience to oversee all financial aspects of the company and drive the company’s financial planning and strategy. The ideal candidate will play a critical role in shaping the financial health and long-term success of Skilledscore.',
    responsibilities: [
      "Strategic Financial Leadership: Develop and implement financial strategies that align with SkilledScore's business objectives.",
      'Financial Planning and Analysis: Oversee budgeting, forecasting, and financial reporting to support decision-making.',
      'Cash Flow Management: Monitor cash flow, manage financial risks, and ensure adequate funding for operations and growth.',
      'Compliance and Governance: Ensure compliance with financial regulations and establish internal controls.',
      'Investor Relations: Collaborate with investors and stakeholders, providing transparent and accurate financial information.',
      'Team Management: Lead and mentor the finance team, fostering a culture of excellence and accountability.',
      'Cost Optimization: Identify cost-saving opportunities and improve overall financial efficiency.',
      'Growth Enablement: Partner with leadership to evaluate investment opportunities and execute mergers, acquisitions, or partnerships as needed.',
    ],
    requirements: [
      'Bachelor’s degree in Finance, Accounting, Economics, or a related field (MBA or CPA preferred).',
      '2+ years of experience in financial management, preferably in a startup or fast-paced environment.',
      'Proven track record of developing and executing financial strategies.',
      'Strong knowledge of financial principles, regulations, and best practices.',
      'Proficiency in financial software and tools (e.g., QuickBooks, SAP, Excel).',
      'Exceptional analytical, problem-solving, and decision-making skills.',
      'Excellent leadership, communication, and interpersonal abilities.',
    ],
  },
  {
    title: 'Chief Operating Officer (COO)',
    title_translated: 'Chief Operating Officer (COO)',
    description:
      'We are seeking an experienced and strategic Chief Operating Officer (COO) with 2+ years of experience to oversee the company’s operations and execute its business strategies. As a key member of the executive team, the COO will ensure operational efficiency and contribute to the growth and success of Skilledscore.',
    responsibilities: [
      'Operational Leadership: Oversee daily operations, including HR, finance, technology, and customer support, ensuring alignment with company goals.',
      'Strategic Planning: Collaborate with the CEO and leadership team to develop and implement business strategies, plans, and procedures.',
      'Performance Management: Set comprehensive goals for performance and growth and monitor departmental KPIs.',
      'Process Improvement: Identify and implement process improvements to enhance operational efficiency and scalability.',
      'Team Leadership: Foster a culture of accountability, collaboration, and innovation across teams.',
      'Resource Management: Optimize the allocation of resources, including people, technology, and budgets, to achieve business objectives.',
      'Risk Management: Identify operational risks and develop mitigation strategies.',
      'Customer Focus: Ensure exceptional customer experiences and satisfaction through efficient operations and service delivery.',
    ],
    requirements: [
      'Bachelor’s degree in Business Administration, Management, or a related field (MBA preferred).',
      '2+ years of experience in a senior operational or management role, preferably in a startup or fast-paced environment.',
      'Proven track record of driving operational success and scaling business operations.',
      'Strong leadership, organizational, and problem-solving skills.',
      'Excellent interpersonal and communication abilities.',
      'Proficiency in project management tools and software.',
    ],
  },
  {
    title: 'General Consideration',
    description:
      "We are always on the lookout for talented and driven individuals to join our team. If you don't see a specific job opening that fits your skills and experience, you are welcome to apply here under General Consideration. Our team will review your application and match you with opportunities that align with your expertise and career aspirations.",
    responsibilities: [
      'Talent Pool: Submit your application to be considered for a variety of current and future opportunities.',
      'Skill Matching: Help us understand your expertise and interests to identify suitable roles.',
      'Networking: Stay connected with our organization and explore potential career paths.',
      'Flexibility: Indicate your preferred areas of interest or roles to help us match your profile effectively.',
      'Contribution: Share how you can bring value to our team and support our organizational goals.',
    ],
    requirements: [
      'A passion for contributing to a dynamic and innovative work environment.',
      'Relevant skills and experiences tailored to the roles you are interested in.',
      'Strong communication and a proactive approach to collaboration.',
      'Willingness to learn and adapt to diverse challenges.',
      'Additional qualifications may vary depending on the specific role.',
    ],
  },
];

export {
  BANNER_IMAGES,
  BANNER_LINK,
  COMMON,
  EASY_PAISA_APPROVAL_POINTS,
  EASY_PAISA_STATUS,
  FILE_SIZES,
  HOME,
  HOW_IT_WORK,
  ILO_CHART_WIDTH_MAPPER,
  ILO_GROUPS,
  JOBS,
  MESSAGES,
  PAYMENT_CARD_HEADINGS,
  PAYMENT_METHODS,
  PAYMENT_STATUS,
  PLAN_INFO,
  POLICIES,
  PREMIUM_PLAN,
  SCORE_DETAILs,
  SKILLED_SCORE_VIDEOS,
  TEAM_MEMBERS,
  USER_PROFILE,
};
