import React from 'react';

import LoaderIcon from './LoaderIcon';

const LoaderButton = ({ text }) => {
  return (
    <button
      type='button'
      className='bg-white flex justify-center items-center font-medium w-full h-10 rounded-lg text-sm px-2'
    >
      {text ? <p className='text-blue-700 font-medium mr-3'>{text}</p> : null}
      <LoaderIcon blue={true} />
    </button>
  );
};

export default LoaderButton;
