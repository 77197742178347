import React from 'react';

import EmailIcon from 'src/Assets/images/v1/icons/emailIcon';

const FooterHelpLink = ({ linkStyle, iconColor = '#9199B2' }) => {
  return (
    <div className='flex md:-mt-5 justify-center flex-wrap md:justify-between text-SSGrey'>
      <div className='flex'>
        <EmailIcon color={iconColor} />
        <a
          className={`underline mx-2 ${linkStyle}`}
          href='mailto:help@skilledscore.com'
        >
          help@skilledscore.com
        </a>
      </div>
      <p className={`mr-5 ${linkStyle}`}>
        © {new Date().getFullYear()} SkilledScore{' '}
      </p>
    </div>
  );
};

export default FooterHelpLink;
