import Api from './index';

const getConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const Chats = {
  getChats: async (data) => {
    const endpoint = '/chat/getMessages';
    return await Api.post(endpoint, data, getConfig());
  },

  createChatByText: async (data) => {
    const endpoint = '/chat/createChatByText';
    return await Api.post(endpoint, data, getConfig());
  },

  createChat: (data) => {
    const endpoint = '/chat/createChat';
    return Api.post(endpoint, data);
  },

  submitChat: async (data) => {
    const endpoint = '/chat/sendMessage';
    return await Api.post(endpoint, data, getConfig());
  },

  getDataFromResume: async (data) => {
    const endpoint = '/chat/get-resume-data';
    return await Api.post(endpoint, data, getConfig());
  },

  getResumeValidation: async (token) => {
    const endpoint = '/chat/resume-validate';
    return await Api.get(endpoint, getConfig(token));
  },
};

export default Chats;
