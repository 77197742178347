/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Month = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const isLeapYear = (year) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

const getMaxDaysForMonth = (month, year) => {
  const maxDaysByMonth = {
    January: 31,
    February: isLeapYear(year) ? 29 : 28,
    March: 31,
    April: 30,
    May: 31,
    June: 30,
    July: 31,
    August: 31,
    September: 30,
    October: 31,
    November: 30,
    December: 31,
  };
  return maxDaysByMonth[month];
};

const DateSelector = (props) => {
  const [dayValue, setDayValue] = useState('');
  const [monthValue, setMonthValue] = useState('');
  const [yearValue, setYearValue] = useState('');

  // const [dayError, setDayError] = useState(false);
  // const [yearError, setYearError] = useState(false);
  const [errorTextDay, setErrorTextDay] = useState('');
  const [errorTextYear, setErrorTextYear] = useState('');
  const years = generateYearList(1960, new Date().getFullYear() - 12);

  function generateYearList(start, end) {
    const years = [];
    for (let year = start; year <= end; year++) {
      years.push(year.toString());
    }
    return years;
  }

  useEffect(() => {
    if (props.value) {
      const dateObject = new Date(props.value);
      const month = dateObject.toLocaleString('default', { month: 'long' });
      const date = dateObject.getDate();
      const year = dateObject.getFullYear();
      setDayValue(date);
      setMonthValue(month);
      setYearValue(year);
    }
  }, [props.value]);

  const handleDayChange = (event) => {
    const inputValue = event.target.value;
    props.setDob({ day: inputValue, month: monthValue, year: yearValue });

    if (!/^\d*$/.test(inputValue)) {
      // setErrorTextDay('Please enter only numeric characters');
      // setDayError(true);
      return;
    }
    const formattedValue = inputValue.replace(/\D+/g, '');

    if (formattedValue === '') {
      setDayValue('');
    } else {
      const numericValue = parseInt(formattedValue, 10);
      setDayValue(String(Math.min(Math.max(numericValue, 0), 31)));
    }
  };

  const handleDayBlur = () => {
    setDayValue(dayValue.toString().padStart(2, '0'));
    const maxDaysForMonth = getMaxDaysForMonth(monthValue, parseInt(yearValue));
    const numericValue = parseInt(dayValue, 10);

    if (numericValue > maxDaysForMonth) {
      setErrorTextDay(
        `Date should be less than or equal to ${maxDaysForMonth}`,
      );
      // setDayError(true);
    } else {
      setErrorTextDay('');
      // setDayError(false);
    }
  };

  const handleYearChange = (event) => {
    const inputValue = event.target.value;
    props.setDob({ day: dayValue, month: monthValue, year: inputValue });

    if (!/^\d*$/.test(inputValue)) {
      // setErrorTextYear('Please enter only numeric characters');
      // setYearError(true);
      return;
    }

    const formattedValue = inputValue.replace(/\D+/g, '');

    if (formattedValue === '') {
      setYearValue('');
    } else {
      const numericValue = parseInt(formattedValue, 10);
      props.setDob({ day: dayValue, month: monthValue, year: numericValue });

      setYearValue(
        String(Math.min(Math.max(numericValue, 1), new Date().getFullYear())),
      );
    }
  };

  const handleYearBlur = () => {
    handleDayBlur();
    const numericValue = parseInt(yearValue);

    if (numericValue < 1900 || numericValue > new Date().getFullYear()) {
      setErrorTextYear(
        `Year should be between 1900 and ${new Date().getFullYear()}`,
      );
      // setYearError(true);
    } else {
      setErrorTextYear('');
      // setYearError(false);
    }
  };

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    props.setDob({ day: dayValue, month: selectedMonth, year: yearValue });
    setMonthValue(selectedMonth);
    const maxDaysForMonth = getMaxDaysForMonth(
      selectedMonth,
      parseInt(yearValue),
    );
    handleDayBlur();
    if (parseInt(dayValue) > maxDaysForMonth) {
      setDayValue(String(maxDaysForMonth).padStart(2, '0'));
    }
  };

  useEffect(() => {
    if (
      dayValue &&
      monthValue &&
      yearValue &&
      yearValue.toString().length >= 4
    ) {
      // props.onChange(new Date(`${dayValue}-${monthValue}-${yearValue}`));
    }
  }, [dayValue, monthValue, yearValue]);

  return (
    <div className=''>
      <div className='flex justify-between '>
        <div>
          <input
            type='text'
            placeholder='DD *'
            className='rounded-md w-full'
            value={dayValue}
            maxLength={2}
            onBlur={handleDayBlur}
            onChange={handleDayChange}
          />
        </div>
        <select
          className={`rounded-md w-full mx-2 ${
            dayValue ? 'text-black' : 'text-[#777]'
          }`}
          value={monthValue}
          onChange={handleMonthChange}
        >
          <option value='' disabled>
            MM *
          </option>
          {Month.map((name) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </select>

        <div>
          <select
            className={`rounded-md ${dayValue ? 'text-black' : 'text-[#777]'}`}
            value={yearValue}
            onBlur={handleYearBlur}
            onChange={handleYearChange}
          >
            <option value='' disabled>
              YYYY *
            </option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='h-5 my-1 text-red-500 text-start text-xs sm:text-base '>
        {props.dobError ? props.dobError : null}
        {errorTextYear ? (
          <div>
            {errorTextYear}
            {errorTextDay && errorTextYear ? ' and ' : ''}
            {errorTextDay}
          </div>
        ) : null}
      </div>
    </div>
  );
};

DateSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

export default DateSelector;

// const DateSelector = () => {
//   const [selectedYear, setSelectedYear] = useState('');
//   const [selectedMonth, setSelectedMonth] = useState('');
//   const [selectedDay, setSelectedDay] = useState('');
//   const [daysInMonth, setDaysInMonth] = useState(31);

//   const handleYearChange = (e) => {
//     setSelectedYear(e.target.value);
//     if (
//       (e.target.value % 4 === 0 && e.target.value % 100 !== 0) ||
//       e.target.value % 400 === 0
//     ) {
//       setDaysInMonth(29);
//     } else {
//       setDaysInMonth(28);
//     }
//   };

//   const handleMonthChange = (e) => {
//     setSelectedMonth(e.target.value);
//   };

//   const handleDayChange = (e) => {
//     setSelectedDay(e.target.value);
//   };

//   const yearOptions = Array.from(
//     { length: 100 },
//     (_, index) => new Date().getFullYear() - index,
//   );

//   const monthOptions = [
//     { value: '1', label: 'January' },
//     { value: '2', label: 'February' },
//     { value: '3', label: 'March' },
//     { value: '4', label: 'April' },
//     { value: '5', label: 'May' },
//     { value: '6', label: 'June' },
//     { value: '7', label: 'July' },
//     { value: '8', label: 'August' },
//     { value: '9', label: 'September' },
//     { value: '10', label: 'October' },
//     { value: '11', label: 'November' },
//     { value: '12', label: 'December' },
//   ];

//   const dayOptions = Array.from(
//     { length: daysInMonth },
//     (_, index) => index + 1,
//   );

//   return (
//     <div className='flex space-x-4'>
//       <div className='my-2'>
//         <select
//           id='day'
//           name='day'
//           onChange={handleDayChange}
//           value={selectedDay}
//           className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
//         >
//           <option value=''>DD *</option>
//           {dayOptions.map((day) => (
//             <option key={day} value={day}>
//               {day}
//             </option>
//           ))}
//         </select>
//       </div>
//       <div className='my-2'>
//         <select
//           id='month'
//           name='month'
//           onChange={handleMonthChange}
//           value={selectedMonth}
//           className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
//         >
//           <option value=''>MM *</option>
//           {monthOptions.map((month) => (
//             <option key={month.value} value={month.value}>
//               {month.label}
//             </option>
//           ))}
//         </select>
//       </div>
//       <div className='my-2'>
//         <select
//           id='year'
//           name='year'
//           onChange={handleYearChange}
//           value={selectedYear}
//           className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
//         >
//           <option value=''>YYYY *</option>
//           {yearOptions.map((year) => (
//             <option key={year} value={year}>
//               {year}
//             </option>
//           ))}
//         </select>
//       </div>
//     </div>
//   );
// };
