import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ExperienceInputDialog from '../Components/ExperienceInputDialog';
import { ProfileCompletionContext } from '../Contexts';

import BlankListPlaceholder from './BlankListPlaceholder';
import EducationInputDialog from './EducationInputDialog';
import ExperienceItem from './ExperienceItem';
import ProfileCompletionModal from './ProfileCompletionModal';

const useStyles = makeStyles(() => ({
  dividerStyle: {
    marginTop: '2%',
    marginBottom: '2%',
  },
  listStyle: {
    height: '50vh',
    marginTop: '4vh',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
  },
  titleStyle: {
    marginTop: '10px',
    fontSize: '30px',
  },
  subTitleStyle: {
    marginTop: '10px',
    fontSize: '18px',
    fontWeight: 'lighter',
  },
}));

const ListInputForm = (props) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [data, setData] = useState(props.data);
  const [selectedItem, setSelectedItem] = useState({});

  const context = useContext(ProfileCompletionContext);
  const { profile, setProfile } = context;
  const { experience, education } = profile;

  const handleEdit = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleDelete = (id) => {
    props.deleteItem(id);
  };

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <>
      <ProfileCompletionModal
        submitted={props.submitted}
        skipped={props.skipped}
        backed={props.backed}
        isChildren={props.isChildren}
        center={data.length === 0}
        title={props.title}
        variant={props.variant}
        titleStyle={classes.titleStyle}
        subTitle={props.subTitle}
        subTitleStyle={classes.subTitleStyle}
      >
        {data.length > 0 ? (
          <Grid xs={12} className={classes.listStyle} container>
            <Grid xs={12} item>
              <Grid xs={12} container>
                {data.map((item, index) => (
                  <Grid xs={12} item key={index}>
                    <ExperienceItem
                      id={index}
                      title={item.title}
                      subtitle={item.subtitle}
                      date={item.date}
                      onEdit={() => handleEdit({ ...item, id: index })}
                      onDelete={() =>
                        handleDelete({ ...item, id: index }, data, setData)
                      }
                    />
                    <Divider
                      variant='fullWidth'
                      className={classes.dividerStyle}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Box style={{ marginTop: '25%' }}>
            <BlankListPlaceholder text={props.blankPlaceholder} />
          </Box>
        )}
      </ProfileCompletionModal>

      {
        // eslint-disable-next-line no-nested-ternary
        data && data.length > 0 ? (
          props.variant === 'experience' ? (
            <ExperienceInputDialog
              {...selectedItem}
              open={open}
              onClose={() => {
                setSelectedItem({});
                setOpen(false);
              }}
              onSubmitted={({
                id,
                designation,
                company,
                startYear,
                endYear,
                location,
              }) => {
                if (id === -1) {
                  setProfile({
                    ...profile,
                    experience: [
                      ...experience,
                      {
                        designation,
                        company,
                        startYear,
                        endYear,
                        location,
                      },
                    ],
                  });
                } else {
                  const newData = [...experience];
                  newData[id] = {
                    designation,
                    company,
                    startYear,
                    endYear,
                    location,
                  };
                  setProfile({ ...profile, experience: newData });
                }
                setOpen(false);
              }}
            />
          ) : (
            <EducationInputDialog
              {...selectedItem}
              open={open}
              onClose={() => {
                setSelectedItem({});
                setOpen(false);
              }}
              onSubmitted={(data) => {
                if (data.id === -1) {
                  setProfile({
                    ...profile,
                    education: [...education, { ...data }],
                  });
                } else {
                  const newData = [...education];
                  newData[education.id] = data;
                  setProfile({ ...profile, education: newData });
                }
                setOpen(false);
              }}
            />
          )
        ) : (
          <></>
        )
      }
    </>
  );
};

ListInputForm.propTypes = {
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['experience', 'education']),
  submitted: PropTypes.func.isRequired,
  skipped: PropTypes.func.isRequired,
  backed: PropTypes.func.isRequired,
  data: PropTypes.array,
  deleteItem: PropTypes.func,
  blankPlaceholder: PropTypes.string.isRequired,
};

ListInputForm.defaultProps = {
  variant: 'experience',
};

export default ListInputForm;
