import Api from './index';
import Response from './Response';

const { next } = Response;
const getConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const GetUser = {
  summary: async (token, data) => {
    try {
      const endpoint = '/chat/getMessages';
      const response = await Api.post(endpoint, { data }, getConfig(token));
      if (response?.data?.data) {
        return response.data.data;
      }
    } catch (e) {
      next(e);
    }
  },
};

export default GetUser;
