import * as React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Box,
  CircularProgress,
  Grid,
  MobileStepper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import logo from '../Assets/images/logo.svg';
import backImage from '../Assets/images/stepperBg.svg';
import CompletionStepper from '../Components/CompletionStepper';

const useStyles = makeStyles(() => ({
  parent: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${backImage})`,
  },
  contentContainer: {
    marginTop: '30px',
  },
  mobileStepper: () => ({
    backgroundColor: 'transparent',
    width: '200%',
    position: 'absolute',
    bottom: '0px',
    padding: '0px',
  }),
  titleContainer: {
    marginTop: '15%',
  },
  stepStyle: {
    marginBottom: '2%',
    display: 'inline-block',
  },
  stepsTotal: {
    display: 'inline-block',
    opacity: '0.2',
    fontSize: '20px',
    fontWeight: '500',
  },
  subtitleStyle: {
    inset: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  nav: {
    position: 'fixed',
    paddingTop: '45px',
    paddingBottom: '40px',
    display: 'flex',
    width: '100vw',
    backgroundColor: '#ffffff',
    top: '0px',
    left: '0px',
  },
  logo: {
    paddingLeft: '6%',
  },
  step: {
    width: '45%',
    marginTop: '-3%',
    marginBottom: '-5%',
    padding: '1%',
  },
}));

const ProfileCompletionPane = (props) => {
  const classes = useStyles();
  const steps = [
    'Path',
    'Basic Information',
    'Add Skills',
    'Add Experience',
    'Add Education',
  ];
  const timeToCompleteSteps = [
    '2 minutes',
    '3 minutes',
    '3 minutes',
    '3 minutes',
  ];
  const history = useHistory();
  const percent = ((props.step - 1) / steps.length) * 100;

  return (
    <Box className={classes.parent}>
      <Grid
        className={classes.nav}
        style={{ boxShadow: '5px 5px 5px #ececec' }}
      >
        <Grid
          item
          className={classes.logo}
          onClick={() => history.push('/')}
          xs={3}
        >
          <img src={logo} alt='logo' />
        </Grid>
        <Grid
          item
          className={classes.step}
          xs={6}
          onClick={() => history.push('/')}
        >
          <CompletionStepper steps={steps} active={props.step - 1} />
        </Grid>
        <Grid item xs={3} />
        <MobileStepper
          steps={steps.length + 1}
          activeStep={props.step - 1}
          variant='progress'
          position='static'
          className={classes.mobileStepper}
        />
      </Grid>
      <Box>
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            style={{
              width: 'fit-content',
              marginTop: '150px',
              marginBottom: '50px',
            }}
          >
            {window.location.pathname !== '/sign-up/profile/path' ? (
              <Typography color='primary' style={{ marginBottom: '15px' }}>
                This step will hardly take {timeToCompleteSteps[props.step - 1]}{' '}
                to complete
              </Typography>
            ) : null}
            {props.children}
          </Box>
        </Box>
        <Box style={{ position: 'fixed', top: '150px', right: '40px' }}>
          {percent ? (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
              <CircularProgress
                variant='determinate'
                value={percent}
                size={80}
              />
              <Box className={classes.subtitleStyle}>
                <Typography
                  variant='caption'
                  component='div'
                  color='text.secondary'
                >{`${percent}%`}</Typography>
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

ProfileCompletionPane.propTypes = {
  step: PropTypes.number,
  name: PropTypes.string.isRequired,
};

ProfileCompletionPane.defaultProps = {
  step: 1,
};

export default ProfileCompletionPane;
