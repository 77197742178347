import { authBearer } from 'src/Utils/utils';

import Api from './index';

const Blogs = {
  allBlogs: async () => {
    const response = await Api.get(
      `${process.env.REACT_APP_STRAPI_API}/api/articles?populate=*&pagination[limit]=1000`,
      authBearer,
    );
    return response;
  },
  singleBlog: async (slug) => {
    const response = await Api.get(
      `${process.env.REACT_APP_STRAPI_API}/api/articles?filters[slug][$eq]=${slug}&populate=*`,

      authBearer,
    );
    return response;
  },
};

export default Blogs;
