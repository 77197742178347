import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from 'src/Contexts';

const LanguageSwitch = ({ onToggle }) => {
  const { i18n } = useTranslation();
  const { language, setLanguage } = useContext(AppContext);

  const toggleLanguage = () => {
    const newLanguage = language === 'en' ? 'ar' : 'en';
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    document.dir = newLanguage === 'ar' ? 'rtl' : 'ltr';
    localStorage.setItem('lang', newLanguage);

    if (onToggle) {
      onToggle(newLanguage);
    }
  };

  return (
    <div
      className={`flex items-center ${
        language === 'ar' ? 'flex-row-reverse' : 'flex-row'
      } space-x-2 mx-4`}
    >
      <button
        onClick={toggleLanguage}
        className={`relative inline-flex items-center h-5 w-12 rounded-full transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 ${
          language === 'en' ? 'bg-gray-300' : 'bg-blue-500'
        }`}
      >
        {/* Indicator */}
        <span
          className={`absolute inline-block h-5 w-6 rounded-full bg-white z-50 shadow transform transition-transform duration-300 ${
            language === 'en' ? 'translate-x-0' : 'translate-x-0'
          }`}
        ></span>
        {/* Labels */}
        <span
          className={`absolute left-2 text-xs text-gray-600 ${
            language === 'en' ? 'opacity-100' : 'opacity-50'
          } transition-opacity duration-300`}
        >
          EN
        </span>
        <span
          className={`absolute right-2 text-xs text-gray-600 ${
            language === 'ar' ? 'opacity-100' : 'opacity-50'
          } transition-opacity duration-300`}
        >
          AR
        </span>
      </button>
    </div>
  );
};

export default LanguageSwitch;
