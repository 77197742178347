import * as React from 'react';
import { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import BasicInfoForm from '../../Components/BasicInfoForm';
import { ProfileCompletionContext } from '../../Contexts';

const ProfileBasic = (props) => {
  const context = useContext(ProfileCompletionContext);
  const { profile, setProfile } = context;
  const {
    general: { profession, dob, country, city, image },
    ...rest
  } = profile;

  return (
    <>
      <BasicInfoForm
        value={{ profession, dob, country, city, image }}
        submitted={({ profession, dob, country, city, image }) => {
          const newData = {
            general: { profession, dob, country, city, image },
            ...rest,
          };
          setProfile(newData);
          props.submitted(newData);
        }}
      />
    </>
  );
};

ProfileBasic.prototype = {
  submitted: PropTypes.func.isRequired,
};

export default withRouter(ProfileBasic);
