import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Threads from 'src/Api/Threads';
import { AppContext } from 'src/Contexts';
import { FirebaseAnalyticsEvent } from 'src/Firebase/analytics';

import { Auth } from '../../../Api';
import Avatar from '../../../Assets/images/v1/icons/sign-in-avatar.svg';
import { Auth as AuthConstants } from '../../../Constants';
import LoginSignupComponent from '../../LoginSignupComponents';

const LoginNew = () => {
  const history = useHistory();
  const { state } = useLocation();
  const { fetchUserPayment } = React.useContext(AppContext);
  const { t } = useTranslation();

  const defaultEmail = state?.email;
  const [, setCookie] = useCookies([AuthConstants.JWT_TOKEN]);

  const [error, setError] = useState({ show: false, msg: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);

  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

  const onSubmit = async (email, password) => {
    setError({ show: false, msg: '' });
    setLoader(true);
    try {
      const res = await Auth.signIn(email, password);
      const threadData = await Threads.getThread(res?.data?.data?.token);

      localStorage.setItem(
        'loginData',
        JSON.stringify({
          user: {
            ...res.data.data,
            thread_id: threadData?.data?.data?.id ?? null,
            content: threadData?.data?.data?.content ?? '',
          },
        }),
      );

      setCookie(AuthConstants.JWT_TOKEN, res.data.data.token, { path: '/' });
      fetchUserPayment(res?.data?.data?.token);
      FirebaseAnalyticsEvent('login');
      history.push('/home');
    } catch (err) {
      setError({ show: true, msg: err.message });
    } finally {
      setLoader(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: defaultEmail || '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t('login.validation.invalid_email'))
        .required(t('login.validation.email_required')),
      password: Yup.string().required(t('login.validation.password_required')),
    }),
    onSubmit: async (values) => {
      await onSubmit(values.email, values.password);
    },
  });

  return (
    <div>
      <LoginSignupComponent
        MainHeading={t('login.main_heading')}
        Paragraph={t('login.paragraph')}
        Avatar={Avatar}
        Heading={t('login.heading')}
        SubHeading={t('login.sub_heading')}
        ButtonText={t('login.button_text')}
        CreateOrAlready={t('login.create_or_already')}
        EndText={t('login.end_text')}
        login={true}
        formik={formik}
        error={error}
        loader={loader}
        showPassword={showPassword}
        togglePasswordVisibility={togglePasswordVisibility}
      />
    </div>
  );
};

export default LoginNew;
