import * as React from 'react';
import { useContext, useState } from 'react';

import ResetPasswordComponent from 'src/Components/v1/ResetPassword';

import { Auth } from '../Api';
import { AppContext } from '../Contexts';

const ResetPassword = (props) => {
  const [error, setError] = useState({ show: false, msg: '' });
  const [loader, setLoader] = useState(false);

  const urlString = window.location.href; // You can also use a different URL string

  // Create a URL object
  const url = new URL(urlString);
  const query = new URLSearchParams(url.search);
  const [id, setId] = useState();
  const { showError } = useContext(AppContext);

  // DO NOT REMOVE THIS COMMENT: TOKEN VERIFICATION WORK IS DONE HERE
  React.useEffect(() => {
    Auth.validateRequest(query.get('token')).then(
      (res) => {
        setId(res.data.data.id);
      },
      (err) => {
        showError('Token expired, submit forget password request again!');
        props.history.push('/forget-password');
        console.error('ValidateRequest:', err);
      },
    );
  }, []);

  const onSubmit = async ({ newPassword }) => {
    setLoader(true);
    setError({ show: false, msg: '' });
    try {
      await Auth.resetPassword(id, newPassword);
      props.history.push('/sign-in');
      showError('Password updated successfully!');
    } catch (e) {
      setError({ show: true, msg: e.message });
    } finally {
      setLoader(false);
    }
  };

  return (
    <ResetPasswordComponent
      error={error}
      isDisabled={loader}
      onSubmit={async ({ newPassword }) => await onSubmit({ newPassword })}
    />
  );
};

export default ResetPassword;
