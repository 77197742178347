import Api from './index';

const getConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const Payment = {
  paymentHistory: async (token, userData) => {
    const endpoint = '/paymentHistory';
    return await Api.post(endpoint, { userData }, getConfig(token));
  },
  easyPaisaPayment: async (token, userData) => {
    const endpoint = '/payment/easy-paisa';
    return await Api.post(endpoint, { userData }, getConfig(token));
  },
  checkEasyPaisaPlanExpiration: async (token, orderId) => {
    const endpoint = '/payment/easy-paisa-check-expiration';
    return await Api.post(endpoint, { orderId }, getConfig(token));
  },
  updateUserStatus: async (token) => {
    const endpoint = '/payment/update-user-status';
    return await Api.get(endpoint, getConfig(token));
  },
  getEasyPaisaPaymentHistory: async (token, orderId) => {
    const endpoint = '/payment/easy-paisa-payment-history';
    return await Api.post(endpoint, { orderId }, getConfig(token));
  },
  getDollarRate: async (token) => {
    const endpoint = '/currency/dollar-rate';
    return await Api.get(endpoint, getConfig(token));
  },
};

export default Payment;
