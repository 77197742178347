import React, { useState } from 'react';

import LoaderIcon from './LoaderIcon';

const Button = ({ title, onClick, className, ...props }) => {
  const [isLoading, setLoading] = useState(false);
  return (
    <button
      disabled={isLoading || props.disabled}
      className={`bg-primaryBg shadow-md shadow-gray-400 text-white px-8 mt-5 p-3 rounded-lg capitalize ${className}`}
      onClick={() => {
        setLoading(true);
        Promise.resolve(onClick()).finally(() => {
          setLoading(false);
        });
      }}
      {...props}
    >
      {isLoading ? <LoaderIcon blue={true} /> : title}
    </button>
  );
};

export default Button;
