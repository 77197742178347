import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BlogsApi from 'src/Api/Blogs';
import { Blog } from 'src/Assets/images/dashboard';
import Footer from 'src/Components/LandingPage/Footer';
import Loader from 'src/Components/Loader';
import SearchInput from 'src/Components/v1/SearchInput';

import { Header } from '../../Components/LandingPage/index';

import { BlogCard } from './components/BlogsCard';

const Blogs = () => {
  const { t } = useTranslation();
  const [blogs, setBlogs] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  const filteredBlogs = blogs
    ? blogs?.filter((blog) =>
        blog?.attributes?.name
          ?.toLowerCase()
          ?.includes(searchQuery.toLowerCase()),
      )
    : [];

  const fetchBlogs = async () => {
    try {
      const response = await BlogsApi.allBlogs();
      setBlogs(response?.data?.data);
    } catch (error) {
      console.error('Error fetching blogs:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <div>
      <div className='bg-primaryBg px-2'>
        <div className='max-w-screen-2xl mx-auto'>
          <div className='mx-2 lg:px-8'>
            <Header />
          </div>
        </div>
      </div>
      <div className='max-w-screen-2xl mx-auto'>
        <div
          id='heading'
          className='flex flex-col justify-center items m-auto px-2 mt-16 mb-8'
        >
          <h1 className='block font-semibold text-DarkBluePurple text-4xl leading-tight md:text-5xl xl:text-6xl text-center font-[IBM-Ple-Sans]'>
            {t('common.blogs')}
          </h1>
        </div>
      </div>

      <div className='mb-10 min-h-[400px] container px-4 md:px-0 mx-auto'>
        <div className='mb-5'>
          <SearchInput
            value={searchQuery}
            onChange={setSearchQuery}
            placeholder={t('blogs.searchBlogsByName')}
            label={t('blogs.searchBlogs')}
          />
        </div>
        {loading && (
          <div className='flex justify-center items-center h-[400px]'>
            <Loader />
          </div>
        )}

        {!loading && filteredBlogs?.length ? (
          <div
            className={'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6'}
          >
            {filteredBlogs?.map((blog) => (
              <BlogCard key={blog.id} blog={blog} />
            ))}
          </div>
        ) : (
          !loading && (
            <div className=' flex flex-col items-center h-96 justify-center'>
              <img src={Blog} className='opacity-40 h-20 w-20' alt='' />
              <p className='text-center text-2xl font-[karla-regular]'>
                {t('blogs.noBlogs')}
              </p>
            </div>
          )
        )}
      </div>

      <Footer />
    </div>
  );
};

export default Blogs;
