import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Chip from './Chip';

const useStyles = makeStyles(() => ({
  titleStyle: {
    fontSize: '14px',
    fontWeight: '300',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    marginBottom: '1.5%',
    marginLeft: '1%',
    color: '#4f4f4f',
  },
}));

const ChipsDisplayForm = (props) => {
  const classes = useStyles();

  return (
    <Grid direction='column' alignItems='flex-start' container>
      <Typography variant='subtitle2' className={classes.titleStyle}>
        <b>{props.title}</b>
      </Typography>
      <Grid item>
        {props.data.map((item, index) => (
          <Chip
            key={index}
            label={`${item.name} (${item.rate})`}
            onDelete={() => props.onDeleteItem(item.id)}
            className={classes.chipStyle}
          />
        ))}
      </Grid>
    </Grid>
  );
};

ChipsDisplayForm.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
};

export default ChipsDisplayForm;
