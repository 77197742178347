import React from 'react';

import { Text } from './Text';

const SectionTitle = ({ title, description, titleClass, descriptionClass }) => {
  return (
    <div className='md:py-10 py-5 text-center px-2'>
      <Text
        className={`text-white text-2xl  md:text-subHeading font-bold !font-[IBM-Ple-Sans] leading-tight ${titleClass}`}
      >
        {title}
      </Text>
      <Text className={`text-white text-regular mt-2 ${descriptionClass}`}>
        {description}
      </Text>
    </div>
  );
};

export { SectionTitle };
